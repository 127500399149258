import { Card, Col, Row } from 'antd';
import '@features/Webpage/homepage.css';
import { WebsiteFooter } from './Footer';
import {
  KFS_Store_Syd1,
  KFS_Store_Syd2,
  KFS_Store_Syd3,
  KFS_Store_Syd4,
  KFS_Store_Syd6,
  KFS_Store_Syd7,
  KFS_Store_Syd8,
  KFS_Store_Syd9,
  KFS_Store_Syd10,
  KFS_Store_Syd11,
  KFS_Store_Syd12,
  KFS_Store_Syd13,
  KFS_Store_Syd14,
  KFS_Store_Syd15,
  KFS_Store_Syd16,
  KFS_Store_Syd17,
  KFS_Store_Syd18,
} from 'common/images';
import { useHistory } from 'react-router';
import { PATHS } from '@routing/routes';
export const StorePartnerSyd = () => {
  const history = useHistory();
  const handleMelbourne = () => {
    history.push(PATHS.KFSStorePartnerMel.path);
  };

  const handleWarnambool = () => {
    history.push(PATHS.KFSStorePartnerWarnambool.path);
  };

  const handleBendigo = () => {
    history.push(PATHS.KFSStorePartnerBendigo.path);
  };

  return (
    <>
      <Row gutter={0} align="middle" className="headerMainRow">
        <Col xs={24} sm={24} md={24} lg={24} xl={24} className="webPageHeaderTextAboutUs">
          <p className="headertextFAQs">KFS store partners</p>
        </Col>
      </Row>
      <Row justify="space-between">
        <Col sm={24} className="">
          <div className="">
            <a className="normal-font dInlineBlock" onClick={() => handleMelbourne()}>
              <h3 className="normalfontweight melsydlbl"> Melbourne </h3>
            </a>
            <span className="normal-font dInlineBlock verticalBar"> | </span>
            <a className="normal-font dInlineBlock" onClick={() => handleBendigo()}>
              <h3 className="normalfontweight melsydlbl"> Bendigo </h3>
            </a>
            <span className="normal-font dInlineBlock verticalBar"> | </span>
            <a className="normal-font dInlineBlock" onClick={() => handleWarnambool()}>
              <h3 className="normalfontweight melsydlbl"> Warrnambool </h3>
            </a>
            <span className="normal-font dInlineBlock verticalBar"> | </span>
            <a className="normal-font dInlineBlock underline">
              <h3 className="normalfontweight melsydlbl"> Sydney</h3>
            </a>
          </div>
        </Col>
      </Row>
      <a className="textColor">Grab your KFS balikbayan boxes from our store partners near you.</a>

      <br />
      <br />
      <Row gutter={24}>
        <Col xs={24} sm={12} md={12} lg={8} xl={8}>
          <Card className="cardKFSPartner">
            <img alt="example" src={KFS_Store_Syd1} className="kfs-partner-store" />
            <div className="pl-10 divTxtAsianStore">
              <h3 className="textColor mtb-0 mtp-0">Manila Sari-Sari Store</h3>
              <p className="textColor mtb">2/427-441 Victoria Ave, Chatswood NSW 2067</p>
              <p className="textColor mtp-0">+61 2 9411 1855</p>
            </div>
          </Card>
        </Col>
        <Col xs={24} sm={12} md={12} lg={8} xl={8}>
          <Card className="cardKFSPartner">
            <img alt="example" src={KFS_Store_Syd2} className="kfs-partner-store" />
            <div className="pl-10 divTxtAsianStore">
              <h3 className="textColor mtb-0 mtp-0">Suki Kart & Linalyn&apos;s</h3>
              <p className="textColor mtb">10 Rooty Hill Rd S, Rooty Hill NSW 2766</p>
              <p className="textColor mtp-0">+61 2 8632 0667</p>
            </div>
          </Card>
        </Col>
        <Col xs={24} sm={12} md={12} lg={8} xl={8}>
          <Card className="cardKFSPartner">
            <img alt="example" src={KFS_Store_Syd3} className="kfs-partner-store" />
            <div className="pl-10 divTxtAsianStore">
              <h3 className="textColor mtb-0 mtp-0">Suki Kart</h3>
              <p className="textColor mtb">Shop 3, Ground Floor/25-31 Florence St, Hornsby NSW 2077</p>
              <p className="textColor mtp-0">+61 2 8924 1165</p>
            </div>
          </Card>
        </Col>
        <Col xs={24} sm={12} md={12} lg={8} xl={8}>
          <Card className="cardKFSPartner">
            <img alt="example" src={KFS_Store_Syd4} className="kfs-partner-store" />
            <div className="pl-10 divTxtAsianStore">
              <h3 className="textColor mtb-0 mtp-0">Filo Pride Asian Grocery, Eatery and Catering</h3>
              <p className="textColor mtb">211 Marrickville Rd, Marrickville NSW 2204</p>
              <p className="textColor mtp-0"> +61 451 234 689</p>
            </div>
          </Card>
        </Col>
        {/* <Col xs={24} sm={12} md={12} lg={8} xl={8}>
          <Card className="cardKFSPartner">
            <img alt="example" src={KFS_Store_Syd5} className="kfs-partner-store" />
            <div className="pl-10 divTxtAsianStore">
              <h3 className="textColor mtb-0 mtp-0">Pinoy To Atbp Asian Goods and Services</h3>
              <p className="textColor mtb">109 Oxford St, Bondi Junction NSW 2022</p>
              <p className="textColor mtp-0">+61 2 9389 7484</p>
            </div>
          </Card>
        </Col> */}
        <Col xs={24} sm={12} md={12} lg={8} xl={8}>
          <Card className="cardKFSPartner">
            <img alt="example" src={KFS_Store_Syd6} className="kfs-partner-store" />
            <div className="pl-10 divTxtAsianStore">
              <h3 className="textColor mtb-0 mtp-0">Manila Mart </h3>
              <p className="textColor mtb">23 Ryedale Rd, West Ryde NSW 2114</p>
              <p className="textColor mtp-0">+61 2 9809 1997</p>
            </div>
          </Card>
        </Col>
        <Col xs={24} sm={12} md={12} lg={8} xl={8}>
          <Card className="cardKFSPartner">
            <img alt="example" src={KFS_Store_Syd7} className="kfs-partner-store" />
            <div className="pl-10 divTxtAsianStore">
              <h3 className="textColor mtb-0 mtp-0">Pasalubong Oriental Foods </h3>
              <p className="textColor mtb">1073 Botany Rd, Mascot NSW 2020</p>
              <p className="textColor mtp-0">+61 2 8338 8438</p>
            </div>
          </Card>
        </Col>
        <Col xs={24} sm={12} md={12} lg={8} xl={8}>
          <Card className="cardKFSPartner">
            <img alt="example" src={KFS_Store_Syd8} className="kfs-partner-store" />
            <div className="pl-10 divTxtAsianStore">
              <h3 className="textColor mtb-0 mtp-0">Tatak Pinoy Land Down Under </h3>
              <p className="textColor mtb">Shop 2/34 Main St, Blacktown NSW 2148</p>
              <p className="textColor mtp-0">+61 479 077 914</p>
            </div>
          </Card>
        </Col>
        <Col xs={24} sm={12} md={12} lg={8} xl={8}>
          <Card className="cardKFSPartner">
            <img alt="example" src={KFS_Store_Syd9} className="kfs-partner-store" />
            <div className="pl-10 divTxtAsianStore">
              <h3 className="textColor mtb-0 mtp-0"> Super Pinoy Sari Sari Store </h3>
              <p className="textColor mtb">228 Queen St, St Marys NSW 2760</p>
              <p className="textColor mtp-0">+61 411 785 448</p>
            </div>
          </Card>
        </Col>
        <Col xs={24} sm={12} md={12} lg={8} xl={8}>
          <Card className="cardKFSPartner">
            <img alt="example" src={KFS_Store_Syd10} className="kfs-partner-store" />
            <div className="pl-10 divTxtAsianStore">
              <h3 className="textColor mtb-0 mtp-0"> Teresa&apos;s Siopao and Catering </h3>
              <p className="textColor mtb">4 Mount St, Mount Druitt NSW 2770</p>
              <p className="textColor mtp-0">+61 416 960 352</p>
            </div>
          </Card>
        </Col>
        {/* <Col xs={24} sm={12} md={12} lg={8} xl={8}>
          <Card className="cardKFSPartner">
            <img alt="example" src={KFS_Store_Syd11} className="kfs-partner-store" />
            <div className="pl-10 divTxtAsianStore">
              <h3 className="textColor mtb-0 mtp-0"> SHOPinoy Merrylands </h3>
              <p className="textColor mtb">Unit 5/10-16 Mason St, Merrylands NSW 2160</p>
              <p className="textColor mtp-0">+61 423 129 156</p>
            </div>
          </Card>
        </Col> */}
        <Col xs={24} sm={12} md={12} lg={8} xl={8}>
          <Card className="cardKFSPartner">
            <img alt="example" src={KFS_Store_Syd12} className="kfs-partner-store" />
            <div className="pl-10 divTxtAsianStore">
              <h3 className="textColor mtb-0 mtp-0"> MerKado Filipino / Asian Grocery </h3>
              <p className="textColor mtb"> 1/274 Queen St, Campbelltown NSW 2560</p>
              <p className="textColor mtp-0">+61 466 034 461</p>
            </div>
          </Card>
        </Col>
        <Col xs={24} sm={12} md={12} lg={8} xl={8}>
          <Card className="cardKFSPartner">
            <img alt="example" src={KFS_Store_Syd13} className="kfs-partner-store" />
            <div className="pl-10 divTxtAsianStore">
              <h3 className="textColor mtb-0 mtp-0"> Mix n Match Bakehouse</h3>
              <p className="textColor mtb"> 63 Rooty Hill Rd N, Rooty Hill NSW 2766</p>
              <p className="textColor mtp-0">+61 2 8609 5696</p>
            </div>
          </Card>
        </Col>
        <Col xs={24} sm={12} md={12} lg={8} xl={8}>
          <Card className="cardKFSPartner">
            <img alt="example" src={KFS_Store_Syd14} className="kfs-partner-store" />
            <div className="pl-10 divTxtAsianStore">
              <h3 className="textColor mtb-0 mtp-0"> MRT Pilipino Foods and Products</h3>
              <p className="textColor mtb"> Shop C10, 5/9 Freedom plaza, Cabramatta NSW 2166</p>
              <p className="textColor mtp-0">+61 404 656 389</p>
            </div>
          </Card>
        </Col>
        <Col xs={24} sm={12} md={12} lg={8} xl={8}>
          <Card className="cardKFSPartner">
            <img alt="example" src={KFS_Store_Syd15} className="kfs-partner-store" />
            <div className="pl-10 divTxtAsianStore">
              <h3 className="textColor mtb-0 mtp-0"> The BangkƐta Grocer </h3>
              <p className="textColor mtb"> Shop 1/35 Lawson St, Penrith NSW 2750</p>
              <p className="textColor mtp-0">+61 412 080 122</p>
            </div>
          </Card>
        </Col>
        <Col xs={24} sm={12} md={12} lg={8} xl={8}>
          <Card className="cardKFSPartner">
            <img alt="example" src={KFS_Store_Syd16} className="kfs-partner-store" />
            <div className="pl-10 divTxtAsianStore">
              <h3 className="textColor mtb-0 mtp-0"> Woy Woy Sari Sari Filipino Store </h3>
              <p className="textColor mtb"> 32 Blackwall Rd, Woy Woy NSW 2256</p>
              <p className="textColor mtp-0">+61 413 822 937</p>
            </div>
          </Card>
        </Col>
        <Col xs={24} sm={12} md={12} lg={8} xl={8}>
          <Card className="cardKFSPartner">
            <img alt="example" src={KFS_Store_Syd18} className="kfs-partner-store" />
            <div className="pl-10 divTxtAsianStore">
              <h3 className="textColor mtb-0 mtp-0"> Filo Pantry Minimart </h3>
              <p className="textColor mtb"> McFarlane St, Merrylands NSW 2160</p>
              <p className="textColor mtp-0">+61 405 433 522</p>
            </div>
          </Card>
        </Col>
      </Row>
      <br />
      <Row gutter={0} align="middle">
        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
          <WebsiteFooter />
        </Col>
      </Row>
    </>
  );
};
