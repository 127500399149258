import { Button, Col, DatePicker, Form, Row, Select, message } from 'antd';
import { useHistory } from 'react-router-dom';
import { Constants, Roles } from 'common/constants';
import { SenderListingOrderBox } from './SenderListingOrderBox';
import { PATHS } from '@routing/routes';
import { filterOption, selectOptions20 } from 'common/utils/utility';
import TextArea from 'antd/es/input/TextArea';
import { AccountSelector } from '@features/Account';
import { useDispatch, useSelector } from 'react-redux';
import { SenderDetailsSelector } from '@features/SenderDetails';
import { BoxOrderViewModel } from 'common/models';
import { SubmitOrderBox, UpdateOrderBox } from '../business/OrderBoxBusiness';
import { useEffect, useState } from 'react';
import { OrderBoxSelector } from '../store/OrderBoxSelector';
import { OperationStatus } from 'store/rootTypes';
import { TransactionSelector } from '@features/Transaction';
import { EmployeeSelector } from '@features/Employee';
import { commonSelector } from '@features/Common/store/commonSelector';
import dayjs from 'dayjs';
export const OrderBox = () => {
  const history = useHistory();
  const accountState = useSelector(AccountSelector);
  const senderDetailsState = useSelector(SenderDetailsSelector);
  const orderAboxState = useSelector(OrderBoxSelector);
  const transactionState = useSelector(TransactionSelector);
  const employeeState = useSelector(EmployeeSelector);
  const commonState = useSelector(commonSelector);
  const dispatch = useDispatch();
  const [lastStatusCode, setLastStatusCode] = useState<string | null>(null);
  const onFinish = async (values: BoxOrderViewModel) => {
    if (transactionState.FromUpdateLinkOrderABox && transactionState.TransactionSummary?.Transactions.Id) {
      const data = { ...transactionState.TransactionSummary?.BoxOrder }; // Create a shallow copy
      data.Quantity = values.Quantity;
      data.Remarks = values.Remarks;
      data.PickupBy = values.PickupBy;
      if (accountState.RoleName != Roles.Customer) {
        const formattedDate = values.Date ? dayjs(values.Date).format('YYYY-MM-DD') : null;
        data.Date = formattedDate ? new Date(formattedDate) : undefined;
      }
      await dispatch(UpdateOrderBox(data));
      message.success('Box order updated successfully');
      history.push(PATHS.SummaryOrderBox.path);
    } else if (commonState?.GlobalSenderId) {
      values.SenderId = commonState?.GlobalSenderId;
      if (!values.Date) {
        values.Date = undefined;
      }
      await dispatch(SubmitOrderBox(values));
      history.push(PATHS.OrderABoxConfirmation.path);
    } else {
      console.error('SelectedId is undefined');
    }
  };

  // Assign lastStatus.Code to state on page load
  useEffect(() => {
    if (
      transactionState.TransactionSummary?.TransStatusDisplay &&
      transactionState.TransactionSummary?.TransStatusDisplay.length > 0
    ) {
      const lastStatus =
        transactionState.TransactionSummary?.TransStatusDisplay[
          transactionState.TransactionSummary?.TransStatusDisplay.length - 1
        ];
      setLastStatusCode(lastStatus?.Code || null);
      console.log(lastStatus.Code);
    }
  }, [transactionState.TransactionSummary?.TransStatusDisplay]);

  useEffect(() => {
    if (orderAboxState.submitStatus === OperationStatus.succeeded && !transactionState.FromUpdateLinkOrderABox) {
      history.push(PATHS.OrderABoxConfirmation.path);
    }
    // else {
    //   history.push(PATHS.SummaryOrderBox.path);
    // }
  }, [orderAboxState.submitStatus, history]);

  return (
    <div className="App">
      <Row justify="center" align="middle">
        <h1>Order an empty box </h1>
      </Row>

      <Form name="senderForm" layout="vertical" onFinish={onFinish}>
        <Row justify="center" align="middle">
          <Col xs={24} sm={20} md={12} lg={8} xl={6}>
            <Form.Item
              label="Quantity"
              name="Quantity"
              rules={[{ required: true, message: Constants.REQUIRED_FIELD }]}
              initialValue={transactionState.TransactionSummary?.BoxOrder.Quantity}
            >
              <Select
                size="large"
                showSearch
                placeholder="Select"
                optionFilterProp="children"
                filterOption={filterOption}
              >
                {selectOptions20.map((option) => (
                  <Select.Option key={option.value} value={option.value}>
                    {option.label}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item
              label="Delivery notes (optional)"
              name="Remarks"
              initialValue={transactionState.TransactionSummary?.BoxOrder.Remarks}
            >
              <TextArea size="large" />
            </Form.Item>
            {accountState.RoleName != Roles.Customer && (
              <>
                <Form.Item
                  label="Drop off date"
                  name="Date"
                  initialValue={
                    transactionState.FromUpdateLinkOrderABox && transactionState.TransactionSummary?.BoxOrder.Date
                      ? dayjs(transactionState.TransactionSummary?.BoxOrder?.Date)
                      : ''
                  }
                  // rules={[
                  //   {
                  //     required: transactionState.FromUpdateLinkOrderABox ? true : false,
                  //     message: Constants.REQUIRED_FIELD,
                  //   },
                  // ]}
                  rules={[
                    {
                      required:
                        transactionState.FromUpdateLinkOrderABox &&
                        !(lastStatusCode === 'NW' || lastStatusCode === 'FD'),
                      message: Constants.REQUIRED_FIELD,
                    },
                  ]}
                >
                  <DatePicker size="large" className="full-width" placeholder="Drop off date" format="YYYY-MM-DD" />
                </Form.Item>
              </>
            )}
            {accountState.RoleName != Roles.Customer && (
              <Form.Item
                label="Agent"
                name="PickupBy"
                initialValue={
                  transactionState.TransactionSummary?.Employee?.some(
                    (emp) => emp.UserId === transactionState.TransactionSummary?.BoxOrder.PickupBy
                  )
                    ? transactionState.TransactionSummary?.BoxOrder.PickupBy
                    : undefined
                }
                // rules={[{ required: true, message: Constants.REQUIRED_FIELD }]}
              >
                <Select
                  size="large"
                  showSearch
                  placeholder="Select"
                  optionFilterProp="children"
                  filterOption={filterOption}
                >
                  {employeeState.Employee?.map((emp) => (
                    <Select.Option key={emp.UserId} value={emp.UserId}>
                      {emp.FirstName} {emp.LastName}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            )}
            <div>
              <Button
                className="prevSenderBtn"
                size="large"
                type="default"
                onClick={() =>
                  history.push(
                    !transactionState.FromUpdateLinkOrderABox || !transactionState.SelectedTransId
                      ? PATHS.SenderListingOrderBox.path
                      : PATHS.SummaryOrderBox.path
                  )
                }
              >
                {Constants.BTN_BACK}
              </Button>
              <Button className="nextSenderBtn" size="large" type="primary" htmlType="submit">
                {transactionState.TransactionSummary?.Transactions.Id ? Constants.BTN_UPDATE : Constants.BTN_SUBMIT}
              </Button>
            </div>
          </Col>
        </Row>
      </Form>
    </div>
  );
};
