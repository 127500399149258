import { PATHS } from '@routing/routes';
import { Button, Card, Col, DatePicker, Form, Input, Row, Select, Table } from 'antd';
import { BookingTypeCodeList, ContainerStatusTypeCodeList } from 'common/code/BookingCode';
import { Constants } from 'common/constants';
import { ContainerViewModel } from 'common/models/ContainerTypes';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { InterStateSelector } from '../store/InterStateSelector';
import { OperationStatus } from 'store/rootTypes';
import { GetAll, Save } from '../business/InterStateBusiness';
import { TableLocaleCustom } from '@components/Custom/CustomTable';
import { PlusOutlined } from '@ant-design/icons';
import { useEffect } from 'react';
import { InterStateActions } from '../store/InterStateSlice';
import moment from 'moment';
import { formatContainerTimestamp } from 'common/utils/utility';
import { PlusCircleOutlined, ExclamationCircleFilled } from '@ant-design/icons';
import { InterStateViewModel } from 'common/models/InterStateTypes';
import { InterStateStatus } from './InterStateStatus';

export const InterStateProfile = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [form] = Form.useForm();

  const interState = useSelector(InterStateSelector);
  let data: InterStateViewModel[] = interState.InterStateList || [];
  const onFinish = async (values: InterStateViewModel) => {
    if (interState.InterState) {
      values.Id = interState.SelectedInterStateId ?? '';
    }
    await dispatch(Save(values));
  };

  const handleReferenceClick = async (id) => {
    history.push(PATHS.ContainerProfile.path);
  };

  const handleLoadClick = async (id) => {
    history.push(PATHS.ContainerLoading.path);
  };

  useEffect(() => {
    data = interState.InterStateList || [];
  }, [interState.InterStateList]);

  const showConfirmDelete = () => {
    alert('');
  };

  return (
    <div className="App">
      <Form name="containerForm" onFinish={onFinish} form={form} layout="vertical">
        <Row justify="center" align="middle">
          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            <Card className="card-custom">
              <Row justify="center" align="middle">
                <h1>{interState.InterState ? 'Interstate profile' : 'Create interstate profile'}</h1>
              </Row>
              <Row gutter={[10, 0]}>
                <Col xs={24} sm={20} md={12} lg={8} xl={5}>
                  <Form.Item
                    label="Interstate id"
                    name="TrfId"
                    initialValue={interState.InterState?.TrfId}
                    rules={[{ required: true, message: Constants.REQUIRED_FIELD }]}
                  >
                    <Input size="large" maxLength={25} />
                  </Form.Item>
                </Col>
              </Row>
              Number of box(es) loaded: <b>{interState.InterState?.BoxCount}</b>
              <br />
              <br />
              {data.length == 0 && interState.InterState && !interState.InterState.HasTransaction ? (
                <>
                  <a className="underline" onClick={showConfirmDelete}>
                    Delete container
                  </a>
                  <br />
                  <br />
                </>
              ) : null}
              <Row gutter={[10, 0]}>
                <Col xs={24} sm={20} md={12} lg={5} xl={5}>
                  <Button
                    className="prevSenderBtn"
                    size="large"
                    type="default"
                    loading={interState.submitStatus == OperationStatus.pending}
                    onClick={() => history.push(PATHS.InterStateListing.path)}
                  >
                    {Constants.BTN_CLOSE}
                  </Button>
                  <Button
                    className="nextSenderBtn"
                    size="large"
                    type="primary"
                    htmlType="submit"
                    loading={interState.submitStatus == OperationStatus.pending}
                  >
                    {interState.InterState ? Constants.BTN_UPDATE : Constants.BTN_SAVE}
                  </Button>
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
      </Form>
      <InterStateStatus />
    </div>
  );
};
