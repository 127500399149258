import { CapitalizeWords } from 'common/utils/utility';

export interface AddressWithRate {
  SourceCode: string; // The code representing the source
  IslandCode: string; // The code representing the island
  Island: string; // The name of the island
  Freight: string; // The type of freight (e.g., SEA)
  Province: string; // The province (can be empty)
  Location: string; // The location name
  Rate: string; // The rate (assumed to be string; change to number if numeric)
  Remarks: string; // Additional remarks or notes
  LeadTime: number | null; // Lead time in days (nullable)
}

export function getDistinctProvinces(): string[] {
  const provinces = AddressWithRateList.map((address) => address.Province) // Extract the Province field
    .filter((province) => province !== ''); // Exclude empty provinces

  return Array.from(new Set(provinces)); // Remove duplicates using Set
}

export function getLocationsByProvince(province: string): string[] {
  return Array.from(
    new Set(
      AddressWithRateList.filter((address) => CapitalizeWords(address.Province) === province) // Filter by the selected province
        .map((address) => address.Location) // Extract the Location field
    )
  );
}

export function getRateByProvinceAndLocation(province: string, location: string): string | null {
  const address = AddressWithRateList.find((entry) => entry.Province === province && entry.Location === location);
  return address ? address.Rate : null; // Return the Rate if found, otherwise return null
}

export const AddressWithRateList: AddressWithRate[] = [
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: '',
    Location: 'CAGAYAN VALLEY',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'ABRA',
    Location: 'BANGUED',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'ABRA',
    Location: 'BOLINEY',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'ABRA',
    Location: 'BUCAY',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'ABRA',
    Location: 'BUCLOC',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'ABRA',
    Location: 'DAGUIOMAN',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'ABRA',
    Location: 'DANGLAS',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'ABRA',
    Location: 'DOLORES',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'ABRA',
    Location: 'LA PAZ',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'ABRA',
    Location: 'LACUB',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'ABRA',
    Location: 'LAGANGILAN',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'ABRA',
    Location: 'LAGAYAN',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'ABRA',
    Location: 'LANGIDEN',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'ABRA',
    Location: 'LICUAN',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'ABRA',
    Location: 'LUBA',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'ABRA',
    Location: 'MALIBCONG',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'ABRA',
    Location: 'MANABO',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'ABRA',
    Location: 'PENARRUBIA',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'ABRA',
    Location: 'PIDIGAN',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'ABRA',
    Location: 'PILAR',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'ABRA',
    Location: 'SAL-LAPADAN',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'ABRA',
    Location: 'SAN ISIDRO',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'ABRA',
    Location: 'SAN JUAN',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'ABRA',
    Location: 'SAN QUINTIN',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'ABRA',
    Location: 'TAYUM',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'ABRA',
    Location: 'TINEG',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'ABRA',
    Location: 'TUBO',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'ABRA',
    Location: 'VILLAVICIOSA',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'ALBAY',
    Location: 'BACACAY',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'ALBAY',
    Location: 'CAMALIG',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'ALBAY',
    Location: 'DARAGA',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'ALBAY',
    Location: 'GUINOBATAN',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'ALBAY',
    Location: 'JOVELLAR',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'ALBAY',
    Location: 'LEGASPI CITY',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'ALBAY',
    Location: 'LIBON',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'ALBAY',
    Location: 'LIGAO',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'ALBAY',
    Location: 'MALILIPOT',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'ALBAY',
    Location: 'MALINAO',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'ALBAY',
    Location: 'MANITO',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'ALBAY',
    Location: 'OAS',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'ALBAY',
    Location: 'PIO DURAN',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'ALBAY',
    Location: 'POLANGUI',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'ALBAY',
    Location: 'RAPU RAPU',
    Rate: '165',
    Remarks: 'ISLAND TRANSFER',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'ALBAY',
    Location: 'SANTO DOMINGO',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'ALBAY',
    Location: 'TABACO',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'ALBAY',
    Location: 'TIWI',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'APAYAO',
    Location: 'CALANASAN',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'APAYAO',
    Location: 'CONNER',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'APAYAO',
    Location: 'FLORA',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'APAYAO',
    Location: 'KABUGAO',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'APAYAO',
    Location: 'LUNA',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'APAYAO',
    Location: 'PUDTOL',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'APAYAO',
    Location: 'SANTA MARCELA',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'AURORA',
    Location: 'BALER',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'AURORA',
    Location: 'CASIGURAN',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'AURORA',
    Location: 'DILASAG',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'AURORA',
    Location: 'DINALUNGAN',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'AURORA',
    Location: 'DINGALAN',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'AURORA',
    Location: 'DIPACULAO',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'AURORA',
    Location: 'MARIA AURORA',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'AURORA',
    Location: 'SAN LUIS',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'BATAAN',
    Location: 'ABUCAY',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'BATAAN',
    Location: 'BAGAC',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'BATAAN',
    Location: 'BALANGA',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'BATAAN',
    Location: 'BATAAN EPZA',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'BATAAN',
    Location: 'DINALUPIHAN',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'BATAAN',
    Location: 'HERMOSA',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'BATAAN',
    Location: 'LIMAY',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'BATAAN',
    Location: 'MARIVELES',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'BATAAN',
    Location: 'MORONG',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'BATAAN',
    Location: 'ORANI',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'BATAAN',
    Location: 'ORION',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'BATAAN',
    Location: 'PILAR',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'BATAAN',
    Location: 'SAMAL',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'BATANGAS',
    Location: 'AGONCILLO',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'BATANGAS',
    Location: 'ALITAGTAG',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'BATANGAS',
    Location: 'BALAYAN',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'BATANGAS',
    Location: 'BALETE',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'BATANGAS',
    Location: 'BATANGAS CITY',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'BATANGAS',
    Location: 'BAUAN',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'BATANGAS',
    Location: 'CALACA',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'BATANGAS',
    Location: 'CALATAGAN',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'BATANGAS',
    Location: 'CUENCA',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'BATANGAS',
    Location: 'FERNANDO AIR BASE',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'BATANGAS',
    Location: 'IBAAN',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'BATANGAS',
    Location: 'LAUREL',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'BATANGAS',
    Location: 'LEMERY',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'BATANGAS',
    Location: 'LIAN',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'BATANGAS',
    Location: 'LIPA CITY',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'BATANGAS',
    Location: 'LOBO',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'BATANGAS',
    Location: 'MABINI',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'BATANGAS',
    Location: 'MALVAR',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'BATANGAS',
    Location: 'MATAAS NA KAHOY',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'BATANGAS',
    Location: 'NASUGBU',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'BATANGAS',
    Location: 'PADRE GARCIA',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'BATANGAS',
    Location: 'ROSARIO',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'BATANGAS',
    Location: 'SAN JOSE',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'BATANGAS',
    Location: 'SAN JUAN',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'BATANGAS',
    Location: 'SAN LUIS',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'BATANGAS',
    Location: 'SAN NICOLAS',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'BATANGAS',
    Location: 'SAN PASCUAL',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'BATANGAS',
    Location: 'STA. TERESITA',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'BATANGAS',
    Location: 'STO. TOMAS',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'BATANGAS',
    Location: 'TAAL',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'BATANGAS',
    Location: 'TALISAY',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'BATANGAS',
    Location: 'TANAUAN',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'BATANGAS',
    Location: 'TAYSAN',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'BATANGAS',
    Location: 'TINGLOY',
    Rate: '165',
    Remarks: 'ISLAND TRANSFER',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'BATANGAS',
    Location: 'TUY',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'BENGUET',
    Location: 'ATOK',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'BENGUET',
    Location: 'BAGUIO CITY',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'BENGUET',
    Location: 'BAKUN',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'BENGUET',
    Location: 'BOKOD',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'BENGUET',
    Location: 'BUGUIAS',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'BENGUET',
    Location: 'ITOGON',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'BENGUET',
    Location: 'KABAYAN',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'BENGUET',
    Location: 'KAPANGAN',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'BENGUET',
    Location: 'KIBUNGAN',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'BENGUET',
    Location: 'LA TRINIDAD',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'BENGUET',
    Location: 'MANKAYAN',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'BENGUET',
    Location: 'SABLAN',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'BENGUET',
    Location: 'TUBA',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'BENGUET',
    Location: 'TUBLAY',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'BULACAN',
    Location: 'ANGAT',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'BULACAN',
    Location: 'BALAGTAS',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'BULACAN',
    Location: 'BALUIAG',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'BULACAN',
    Location: 'BOCAUE',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'BULACAN',
    Location: 'BULACAN',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'BULACAN',
    Location: 'BUSTOS',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'BULACAN',
    Location: 'CALUMPIT',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'BULACAN',
    Location: 'DOÑA REMEDIOS TRINIDAD',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'BULACAN',
    Location: 'GUIGUINTO',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'BULACAN',
    Location: 'HAGONOY',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'BULACAN',
    Location: 'MALOLOS',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'BULACAN',
    Location: 'MARILAO',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'BULACAN',
    Location: 'MEYCUAYAN',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'BULACAN',
    Location: 'NORSAGARAY',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'BULACAN',
    Location: 'OBANDO',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'BULACAN',
    Location: 'PANDI',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'BULACAN',
    Location: 'PAOMBONG',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'BULACAN',
    Location: 'PLARIDEL',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'BULACAN',
    Location: 'PULILAN',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'BULACAN',
    Location: 'SAN ILDEFONSO',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'BULACAN',
    Location: 'SAN JOSE DEL MONTE',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'BULACAN',
    Location: 'SAN MIGUEL',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'BULACAN',
    Location: 'SAN RAFAEL',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'BULACAN',
    Location: 'SAPANGPALAY',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'BULACAN',
    Location: 'STA. MARIA',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'CAGAYAN VALLEY',
    Location: 'ABULUG',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'CAGAYAN VALLEY',
    Location: 'ALCALA',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'CAGAYAN VALLEY',
    Location: 'ALLACAPAN',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'CAGAYAN VALLEY',
    Location: 'AMULUNG',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'CAGAYAN VALLEY',
    Location: 'APARRI',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'CAGAYAN VALLEY',
    Location: 'BAGGAO',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'CAGAYAN VALLEY',
    Location: 'BALLESTERROS',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'CAGAYAN VALLEY',
    Location: 'BUGUEY',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'CAGAYAN VALLEY',
    Location: 'CALAYAN',
    Rate: '165',
    Remarks: 'ISLAND TRANSFER',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'CAGAYAN VALLEY',
    Location: 'CAMALANIUGAN',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'CAGAYAN VALLEY',
    Location: 'CLAVERIA',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'CAGAYAN VALLEY',
    Location: 'ENRILE',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'CAGAYAN VALLEY',
    Location: 'GATTARAN',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'CAGAYAN VALLEY',
    Location: 'GONZAGA',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'CAGAYAN VALLEY',
    Location: 'IGUIG',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'CAGAYAN VALLEY',
    Location: 'LAL-LO',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'CAGAYAN VALLEY',
    Location: 'LASSAM',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'CAGAYAN VALLEY',
    Location: 'PAMPLONA',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'CAGAYAN VALLEY',
    Location: 'PENABLANCA',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'CAGAYAN VALLEY',
    Location: 'PIAT',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'CAGAYAN VALLEY',
    Location: 'RIZAL',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'CAGAYAN VALLEY',
    Location: 'SANCHEZ-MIRA',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'CAGAYAN VALLEY',
    Location: 'SANTA ANA',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'CAGAYAN VALLEY',
    Location: 'SANTA PRAXEDES',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'CAGAYAN VALLEY',
    Location: 'SANTA TERESITA',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'CAGAYAN VALLEY',
    Location: 'SANTO NINO',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'CAGAYAN VALLEY',
    Location: 'SOLANA',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'CAGAYAN VALLEY',
    Location: 'TUAO',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'CAGAYAN VALLEY',
    Location: 'TUGUEGARAO',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'CAMARINES NORTE',
    Location: 'BASUD',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'CAMARINES NORTE',
    Location: 'CAPALONGA',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'CAMARINES NORTE',
    Location: 'DAET',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'CAMARINES NORTE',
    Location: 'IMELDA',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'CAMARINES NORTE',
    Location: 'JOSE PANGANIBAN',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'CAMARINES NORTE',
    Location: 'LABO',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'CAMARINES NORTE',
    Location: 'MERCEDES',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'CAMARINES NORTE',
    Location: 'PARACALE',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'CAMARINES NORTE',
    Location: 'SAN VICENTE',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'CAMARINES NORTE',
    Location: 'STA. ELENA',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'CAMARINES NORTE',
    Location: 'TALISAY',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'CAMARINES NORTE',
    Location: 'TULA-NA-LUPA',
    Rate: '0',
    Remarks: 'UNSERVICEABLE',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'CAMARINES NORTE',
    Location: 'VINZONS',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'CAMARINES SUR',
    Location: 'BAAO',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'CAMARINES SUR',
    Location: 'BALATAN',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'CAMARINES SUR',
    Location: 'BATO',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'CAMARINES SUR',
    Location: 'BOMBON',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'CAMARINES SUR',
    Location: 'BUHI',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'CAMARINES SUR',
    Location: 'BULA',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'CAMARINES SUR',
    Location: 'CABUSAO',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'CAMARINES SUR',
    Location: 'CALABANGA',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'CAMARINES SUR',
    Location: 'CAMALIGAN',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'CAMARINES SUR',
    Location: 'CANAMAN',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'CAMARINES SUR',
    Location: 'CARAMOAN',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'CAMARINES SUR',
    Location: 'DEL GALLEGO',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'CAMARINES SUR',
    Location: 'GAINZA',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'CAMARINES SUR',
    Location: 'GARCHITORENA',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'CAMARINES SUR',
    Location: 'GOA',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'CAMARINES SUR',
    Location: 'IRIGA CITY',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'CAMARINES SUR',
    Location: 'LAGONOY',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'CAMARINES SUR',
    Location: 'LIBMANAN',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'CAMARINES SUR',
    Location: 'LUPI',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'CAMARINES SUR',
    Location: 'MAGARAO',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'CAMARINES SUR',
    Location: 'MILAOR',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'CAMARINES SUR',
    Location: 'MINALABAC',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'CAMARINES SUR',
    Location: 'NABUA',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'CAMARINES SUR',
    Location: 'NAGA CITY',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'CAMARINES SUR',
    Location: 'OCAMPO',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'CAMARINES SUR',
    Location: 'PAMPLONA',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'CAMARINES SUR',
    Location: 'PASACAO',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'CAMARINES SUR',
    Location: 'PILI',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'CAMARINES SUR',
    Location: 'PRESENTACION',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'CAMARINES SUR',
    Location: 'RAGAY',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'CAMARINES SUR',
    Location: 'SAGNAY',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'CAMARINES SUR',
    Location: 'SAN FERNANDO',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'CAMARINES SUR',
    Location: 'SAN JOSE',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'CAMARINES SUR',
    Location: 'SIPOCOT',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'CAMARINES SUR',
    Location: 'SIRUMA',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'CAMARINES SUR',
    Location: 'TIGAON',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'CAMARINES SUR',
    Location: 'TINAMBAC',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'CATANDUANES',
    Location: 'BAGAMANOC',
    Rate: '165',
    Remarks: 'ISLAND TRANSFER',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'CATANDUANES',
    Location: 'BARAS',
    Rate: '165',
    Remarks: 'ISLAND TRANSFER',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'CATANDUANES',
    Location: 'BATO',
    Rate: '165',
    Remarks: 'ISLAND TRANSFER',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'CATANDUANES',
    Location: 'CARAMORAN',
    Rate: '165',
    Remarks: 'ISLAND TRANSFER',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'CATANDUANES',
    Location: 'GIGMOTO',
    Rate: '165',
    Remarks: 'ISLAND TRANSFER',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'CATANDUANES',
    Location: 'PANDAN',
    Rate: '165',
    Remarks: 'ISLAND TRANSFER',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'CATANDUANES',
    Location: 'PANGANIBAN',
    Rate: '165',
    Remarks: 'ISLAND TRANSFER',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'CATANDUANES',
    Location: 'SAN ANDRES',
    Rate: '165',
    Remarks: 'ISLAND TRANSFER',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'CATANDUANES',
    Location: 'SAN MIGUEL',
    Rate: '165',
    Remarks: 'ISLAND TRANSFER',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'CATANDUANES',
    Location: 'VIGA',
    Rate: '165',
    Remarks: 'ISLAND TRANSFER',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'CATANDUANES',
    Location: 'VIRAC',
    Rate: '165',
    Remarks: 'ISLAND TRANSFER',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'CAVITE',
    Location: 'ALFONSO',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'CAVITE',
    Location: 'AMADEO',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'CAVITE',
    Location: 'BACOOR',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'CAVITE',
    Location: 'CARMONA',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'CAVITE',
    Location: 'CAVITE CITY',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'CAVITE',
    Location: 'CAVITE EPZA',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'CAVITE',
    Location: 'DASMARIÑAS',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'CAVITE',
    Location: 'GEN. AGUINALDO',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'CAVITE',
    Location: 'GEN. MARIANO ALVAREZ',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'CAVITE',
    Location: 'GEN. TRIAS',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'CAVITE',
    Location: 'IMUS',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'CAVITE',
    Location: 'INDANG',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'CAVITE',
    Location: 'KAWIT',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'CAVITE',
    Location: 'MAGALLANES',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'CAVITE',
    Location: 'MARAGONDON',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'CAVITE',
    Location: 'MENDEZ',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'CAVITE',
    Location: 'NAIC',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'CAVITE',
    Location: 'NOVELETA',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'CAVITE',
    Location: 'ROSARIO',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'CAVITE',
    Location: 'SILANG',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'CAVITE',
    Location: 'TAGAYTAY CITY',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'CAVITE',
    Location: 'TANZA',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'CAVITE',
    Location: 'TERNATE',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'CAVITE',
    Location: 'TRECE MARTIREZ CITY',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'IFUGAO',
    Location: 'AGUINALDO',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'IFUGAO',
    Location: 'ASIPULO',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'IFUGAO',
    Location: 'BANAUE',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'IFUGAO',
    Location: 'HINGYON',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'IFUGAO',
    Location: 'HUNGDUAN',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'IFUGAO',
    Location: 'KIANGAN',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'IFUGAO',
    Location: 'LAGAWE',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'IFUGAO',
    Location: 'LAMUT',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'IFUGAO',
    Location: 'MAYAOYAO',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'IFUGAO',
    Location: 'POTIA',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'IFUGAO',
    Location: 'TINOC',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'ILOCOS NORTE',
    Location: 'ADAMS',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'ILOCOS NORTE',
    Location: 'BACARRA',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'ILOCOS NORTE',
    Location: 'BADOC',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'ILOCOS NORTE',
    Location: 'BANGUI',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'ILOCOS NORTE',
    Location: 'BATAC',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'ILOCOS NORTE',
    Location: 'BURGOS',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'ILOCOS NORTE',
    Location: 'CARAZI',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'ILOCOS NORTE',
    Location: 'CURRIMAO',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'ILOCOS NORTE',
    Location: 'DINGRAS',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'ILOCOS NORTE',
    Location: 'DUMALNEG',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'ILOCOS NORTE',
    Location: 'ESPIRITU',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'ILOCOS NORTE',
    Location: 'LAOAG',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'ILOCOS NORTE',
    Location: 'MARCOS',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'ILOCOS NORTE',
    Location: 'NUEVA ERA',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'ILOCOS NORTE',
    Location: 'PAGUDPOD',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'ILOCOS NORTE',
    Location: 'PAOAY',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'ILOCOS NORTE',
    Location: 'PASUQUIN',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'ILOCOS NORTE',
    Location: 'PIDDIG',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'ILOCOS NORTE',
    Location: 'PINILI',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'ILOCOS NORTE',
    Location: 'SAN NICOLAS',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'ILOCOS NORTE',
    Location: 'SARRAT',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'ILOCOS NORTE',
    Location: 'SOLSONA',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'ILOCOS NORTE',
    Location: 'VINTAR',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'ILOCOS SUR',
    Location: 'ALILEM',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'ILOCOS SUR',
    Location: 'BANAYOYO',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'ILOCOS SUR',
    Location: 'BANTAY',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'ILOCOS SUR',
    Location: 'BURGOS',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'ILOCOS SUR',
    Location: 'CABUGAO',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'ILOCOS SUR',
    Location: 'CANDON',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'ILOCOS SUR',
    Location: 'CAOAYAN',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'ILOCOS SUR',
    Location: 'CERVANTES',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'ILOCOS SUR',
    Location: 'GALIMUYOD',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'ILOCOS SUR',
    Location: 'GREGORIO DEL PILAR',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'ILOCOS SUR',
    Location: 'LIDLIDDA',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'ILOCOS SUR',
    Location: 'MAGSINGAL',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'ILOCOS SUR',
    Location: 'NAGBUKEL',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'ILOCOS SUR',
    Location: 'NARVACAN',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'ILOCOS SUR',
    Location: 'QUIRINO',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'ILOCOS SUR',
    Location: 'SALCEDO',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'ILOCOS SUR',
    Location: 'SAN EMILIO',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'ILOCOS SUR',
    Location: 'SAN ESTEBAN',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'ILOCOS SUR',
    Location: 'SAN ILDEFONSO',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'ILOCOS SUR',
    Location: 'SAN JUAN',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'ILOCOS SUR',
    Location: 'SAN VICENTE',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'ILOCOS SUR',
    Location: 'SANTA',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'ILOCOS SUR',
    Location: 'SANTA CATALINA',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'ILOCOS SUR',
    Location: 'SANTA CRUZ',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'ILOCOS SUR',
    Location: 'SANTA LUCIA',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'ILOCOS SUR',
    Location: 'SANTA MARIA',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'ILOCOS SUR',
    Location: 'SANTIAGO',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'ILOCOS SUR',
    Location: 'SANTO DOMINGO',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'ILOCOS SUR',
    Location: 'SIGAY',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'ILOCOS SUR',
    Location: 'SINAIT',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'ILOCOS SUR',
    Location: 'SUGPON',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'ILOCOS SUR',
    Location: 'SUYO',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'ILOCOS SUR',
    Location: 'TAGUDIN',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'ILOCOS SUR',
    Location: 'VIGAN',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'ISABELA',
    Location: 'ALICIA',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'ISABELA',
    Location: 'ANGADANAN',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'ISABELA',
    Location: 'AURORA',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'ISABELA',
    Location: 'BENITO SOLIVEN',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'ISABELA',
    Location: 'BURGOS',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'ISABELA',
    Location: 'CABAGAN',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'ISABELA',
    Location: 'CABATUAN',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'ISABELA',
    Location: 'CAUAYAN',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'ISABELA',
    Location: 'CORDON',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'ISABELA',
    Location: 'DELFIN ALBANO',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'ISABELA',
    Location: 'DINAPIGUI',
    Rate: '0',
    Remarks: 'UNSERVICEABLE',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'ISABELA',
    Location: 'DIVILACAN',
    Rate: '0',
    Remarks: 'UNSERVICEABLE',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'ISABELA',
    Location: 'ECHAGUE',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'ISABELA',
    Location: 'GAMU',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'ISABELA',
    Location: 'ILAGAN',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'ISABELA',
    Location: 'JONES',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'ISABELA',
    Location: 'LUNA',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'ISABELA',
    Location: 'MACONACON',
    Rate: '0',
    Remarks: 'UNSERVICEABLE',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'ISABELA',
    Location: 'MALLIG',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'ISABELA',
    Location: 'NAGUILLAN',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'ISABELA',
    Location: 'PALANAN',
    Rate: '0',
    Remarks: 'UNSERVICEABLE',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'ISABELA',
    Location: 'QUEZON',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'ISABELA',
    Location: 'QUIRINO',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'ISABELA',
    Location: 'RAMON',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'ISABELA',
    Location: 'REINA MERCEDES',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'ISABELA',
    Location: 'ROXAS',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'ISABELA',
    Location: 'SAN AGUSTIN',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'ISABELA',
    Location: 'SAN GUILLERMO',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'ISABELA',
    Location: 'SAN ISIDRO',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'ISABELA',
    Location: 'SAN MANUEL',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'ISABELA',
    Location: 'SAN MARIANO',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'ISABELA',
    Location: 'SAN MATEO',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'ISABELA',
    Location: 'SAN PABLO',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'ISABELA',
    Location: 'SANTA MARIA',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'ISABELA',
    Location: 'SANTIAGO',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'ISABELA',
    Location: 'SANTO TOMAS',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'ISABELA',
    Location: 'TUMAUINI',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'KALINGA',
    Location: 'BALBALAN',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'KALINGA',
    Location: 'LUBUAGAN',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'KALINGA',
    Location: 'PASIL',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'KALINGA',
    Location: 'PINUKPUK',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'KALINGA',
    Location: 'RIZAL',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'KALINGA',
    Location: 'TABUK',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'KALINGA',
    Location: 'TANUDAN',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'KALINGA',
    Location: 'TINGLAYAN',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'LA UNION ',
    Location: 'AGOO',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'LA UNION ',
    Location: 'ARINGAY',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'LA UNION ',
    Location: 'BACNOTAN',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'LA UNION ',
    Location: 'BAGULIN',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'LA UNION ',
    Location: 'BALAOAN',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'LA UNION ',
    Location: 'BANGAR',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'LA UNION ',
    Location: 'BAUANG',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'LA UNION ',
    Location: 'BURGOS',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'LA UNION ',
    Location: 'CABA',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'LA UNION ',
    Location: 'LUNA',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'LA UNION ',
    Location: 'NAGUILLAN',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'LA UNION ',
    Location: 'PUGO',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'LA UNION ',
    Location: 'ROSARIO',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'LA UNION ',
    Location: 'SAN FERNANDO',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'LA UNION ',
    Location: 'SAN GABRIEL',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'LA UNION ',
    Location: 'SAN JUAN',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'LA UNION ',
    Location: 'SANTOL',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'LA UNION ',
    Location: 'STO. TOMAS',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'LA UNION ',
    Location: 'SUDEPEN',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'LA UNION ',
    Location: 'TUBAO',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'LAGUNA',
    Location: 'ALAMINOS',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'LAGUNA',
    Location: 'BAY',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'LAGUNA',
    Location: 'BIÑAN',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'LAGUNA',
    Location: 'BOTOCAN',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'LAGUNA',
    Location: 'CABUYAO',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'LAGUNA',
    Location: 'CALAMBA',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'LAGUNA',
    Location: 'CALAUAN',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'LAGUNA',
    Location: 'CANLUBANG',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'LAGUNA',
    Location: 'CAVINTI',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'LAGUNA',
    Location: 'FAMY',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'LAGUNA',
    Location: 'KALAYAAN',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'LAGUNA',
    Location: 'LILIW',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'LAGUNA',
    Location: 'LOS BAÑOS',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'LAGUNA',
    Location: 'LUISIANA',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'LAGUNA',
    Location: 'LUMBAN',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'LAGUNA',
    Location: 'MABITAC',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'LAGUNA',
    Location: 'MAGDALENA',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'LAGUNA',
    Location: 'MAJAYJAY',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'LAGUNA',
    Location: 'NAGCARLAN',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'LAGUNA',
    Location: 'PAETE',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'LAGUNA',
    Location: 'PAGSANJAN',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'LAGUNA',
    Location: 'PAKIL',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'LAGUNA',
    Location: 'PANGIL',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'LAGUNA',
    Location: 'PILA',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'LAGUNA',
    Location: 'RIZAL',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'LAGUNA',
    Location: 'SAN PABLO CITY',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'LAGUNA',
    Location: 'SAN PEDRO',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'LAGUNA',
    Location: 'SINILOAN',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'LAGUNA',
    Location: 'STA. CRUZ',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'LAGUNA',
    Location: 'STA. MARIA',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'LAGUNA',
    Location: 'STA. ROSA',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'LAGUNA',
    Location: 'VICTORIA',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'MT. PROVINCE',
    Location: 'BARLIG',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'MT. PROVINCE',
    Location: 'BAUKO',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'MT. PROVINCE',
    Location: 'BESAO',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'MT. PROVINCE',
    Location: 'BONTOC',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'MT. PROVINCE',
    Location: 'NATONIN',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'MT. PROVINCE',
    Location: 'PARACELIS',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'MT. PROVINCE',
    Location: 'SABANGAN',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'MT. PROVINCE',
    Location: 'SADANGA',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'MT. PROVINCE',
    Location: 'SAGADA',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'MT. PROVINCE',
    Location: 'TADIAN',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'NUEVA ECIJA',
    Location: 'ALIAGA',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'NUEVA ECIJA',
    Location: 'BONGABON',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'NUEVA ECIJA',
    Location: 'CABANATUAN',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'NUEVA ECIJA',
    Location: 'CABIAO',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'NUEVA ECIJA',
    Location: 'CARRANGLAN',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'NUEVA ECIJA',
    Location: 'CUYAPO',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'NUEVA ECIJA',
    Location: 'FORT MAGSAYSAY',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'NUEVA ECIJA',
    Location: 'GABALDON',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'NUEVA ECIJA',
    Location: 'GAPAN',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'NUEVA ECIJA',
    Location: 'GENERAL M. NATIVIDAD',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'NUEVA ECIJA',
    Location: 'GENERAL TINIO',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'NUEVA ECIJA',
    Location: 'GUIMBA',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'NUEVA ECIJA',
    Location: 'JAEN',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'NUEVA ECIJA',
    Location: 'LAUR',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'NUEVA ECIJA',
    Location: 'LICAB',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'NUEVA ECIJA',
    Location: 'LLANERA',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'NUEVA ECIJA',
    Location: 'LUPAO',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'NUEVA ECIJA',
    Location: 'MUÑOZ CITY',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'NUEVA ECIJA',
    Location: 'NAMPICUAN',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'NUEVA ECIJA',
    Location: 'PALAYAN CITY',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'NUEVA ECIJA',
    Location: 'PANTABANGAN',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'NUEVA ECIJA',
    Location: 'PENARANDA',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'NUEVA ECIJA',
    Location: 'QUEZON',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'NUEVA ECIJA',
    Location: 'RIZAL',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'NUEVA ECIJA',
    Location: 'SAN ANTONIO',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'NUEVA ECIJA',
    Location: 'SAN ISIDRO',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'NUEVA ECIJA',
    Location: 'SAN JOSE CITY',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'NUEVA ECIJA',
    Location: 'SAN LEONARDO',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'NUEVA ECIJA',
    Location: 'STA. ROSA',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'NUEVA ECIJA',
    Location: 'STO. DOMINGO',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'NUEVA ECIJA',
    Location: 'TALAVERA',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'NUEVA ECIJA',
    Location: 'TALUGTUG',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'NUEVA ECIJA',
    Location: 'ZARAGOZA',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'NUEVA VIZCAYA',
    Location: 'ALFONSO CASTANEDA',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'NUEVA VIZCAYA',
    Location: 'AMBAGUIO',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'NUEVA VIZCAYA',
    Location: 'ARITAO',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'NUEVA VIZCAYA',
    Location: 'BAGABAG',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'NUEVA VIZCAYA',
    Location: 'BAMBANG',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'NUEVA VIZCAYA',
    Location: 'BAYOMBONG',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'NUEVA VIZCAYA',
    Location: 'DIADI',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'NUEVA VIZCAYA',
    Location: 'DUPAX DEL NORTE',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'NUEVA VIZCAYA',
    Location: 'DUPAX DEL SUR',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'NUEVA VIZCAYA',
    Location: 'KASIBU',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'NUEVA VIZCAYA',
    Location: 'KAYAPA',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'NUEVA VIZCAYA',
    Location: 'QUEZON',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'NUEVA VIZCAYA',
    Location: 'SOLANO',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'NUEVA VIZCAYA',
    Location: 'STA. FE',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'NUEVA VIZCAYA',
    Location: 'VILLA VERDE',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'ORIENTAL MINDORO',
    Location: 'BACO',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'ORIENTAL MINDORO',
    Location: 'BANSUD',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'ORIENTAL MINDORO',
    Location: 'BONGABONG',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'ORIENTAL MINDORO',
    Location: 'BULALACAO',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'ORIENTAL MINDORO',
    Location: 'CALAPAN',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'ORIENTAL MINDORO',
    Location: 'GLORIA',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'ORIENTAL MINDORO',
    Location: 'MANSALAY',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'ORIENTAL MINDORO',
    Location: 'NAUJAN',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'ORIENTAL MINDORO',
    Location: 'PINAMALAYAN',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'ORIENTAL MINDORO',
    Location: 'POLA',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'ORIENTAL MINDORO',
    Location: 'PUERTO GALERA',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'ORIENTAL MINDORO',
    Location: 'ROXAS',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'ORIENTAL MINDORO',
    Location: 'SAN TEODORO',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'ORIENTAL MINDORO',
    Location: 'SOCORRO',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'ORIENTAL MINDORO',
    Location: 'VICTORIA',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'PAMPANGA',
    Location: 'ANGELES CITY',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'PAMPANGA',
    Location: 'APALIT',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'PAMPANGA',
    Location: 'ARAYAT',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'PAMPANGA',
    Location: 'BACOLOR',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'PAMPANGA',
    Location: 'CANDABA',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'PAMPANGA',
    Location: 'CLARK AIR BASE',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'PAMPANGA',
    Location: 'DAU',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'PAMPANGA',
    Location: 'FLORIDABLANCA',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'PAMPANGA',
    Location: 'GUAGUA',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'PAMPANGA',
    Location: 'LUBAO',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'PAMPANGA',
    Location: 'MABALACAT',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'PAMPANGA',
    Location: 'MACABEBE',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'PAMPANGA',
    Location: 'MAGALANG',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'PAMPANGA',
    Location: 'MASANTOL',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'PAMPANGA',
    Location: 'MEXICO',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'PAMPANGA',
    Location: 'MINALIN',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'PAMPANGA',
    Location: 'PORAC',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'PAMPANGA',
    Location: 'SAN FERNANDO',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'PAMPANGA',
    Location: 'SAN LUIS',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'PAMPANGA',
    Location: 'SAN SIMON',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'PAMPANGA',
    Location: 'SASMUAN',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'PAMPANGA',
    Location: 'STA. ANA',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'PAMPANGA',
    Location: 'STA. RITA',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'PAMPANGA',
    Location: 'STO. TOMAS',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'PANGASINAN',
    Location: 'AGNO',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'PANGASINAN',
    Location: 'AGUILAR',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'PANGASINAN',
    Location: 'ALAMINOS',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'PANGASINAN',
    Location: 'ALCALA',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'PANGASINAN',
    Location: 'ANDA',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'PANGASINAN',
    Location: 'ASIGNAN',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'PANGASINAN',
    Location: 'BALUNGAO',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'PANGASINAN',
    Location: 'BANI',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'PANGASINAN',
    Location: 'BASISTA',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'PANGASINAN',
    Location: 'BAUTISTA',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'PANGASINAN',
    Location: 'BAYAMBANG',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'PANGASINAN',
    Location: 'BINALONAN',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'PANGASINAN',
    Location: 'BINMALEY',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'PANGASINAN',
    Location: 'BOLINAO',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'PANGASINAN',
    Location: 'BUGALLON',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'PANGASINAN',
    Location: 'BURGOS',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'PANGASINAN',
    Location: 'CALASIAO',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'PANGASINAN',
    Location: 'DAGUPAN CITY',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'PANGASINAN',
    Location: 'DASOL',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'PANGASINAN',
    Location: 'INFANTA',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'PANGASINAN',
    Location: 'LABRADOR',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'PANGASINAN',
    Location: 'LAOAC',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'PANGASINAN',
    Location: 'LINGAYEN',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'PANGASINAN',
    Location: 'MABINI',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'PANGASINAN',
    Location: 'MALASIQUI',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'PANGASINAN',
    Location: 'MANAOAG',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'PANGASINAN',
    Location: 'MANGALDAN',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'PANGASINAN',
    Location: 'MANGATAREM',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'PANGASINAN',
    Location: 'MAPANDAN',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'PANGASINAN',
    Location: 'NATIVIDAD',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'PANGASINAN',
    Location: 'POZORRUBIO',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'PANGASINAN',
    Location: 'ROSALES',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'PANGASINAN',
    Location: 'SAN CARLOS CITY',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'PANGASINAN',
    Location: 'SAN FABIAN',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'PANGASINAN',
    Location: 'SAN JACINTO',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'PANGASINAN',
    Location: 'SAN MANUEL',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'PANGASINAN',
    Location: 'SAN NICOLAS',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'PANGASINAN',
    Location: 'SAN QUINTIN',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'PANGASINAN',
    Location: 'SISON',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'PANGASINAN',
    Location: 'STA. BARBARA',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'PANGASINAN',
    Location: 'STA. MARIA',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'PANGASINAN',
    Location: 'STO. TOMAS',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'PANGASINAN',
    Location: 'SUAL',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'PANGASINAN',
    Location: 'TAYUG',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'PANGASINAN',
    Location: 'UMINGAN',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'PANGASINAN',
    Location: 'URBIZTONDO',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'PANGASINAN',
    Location: 'URDANETA',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'PANGASINAN',
    Location: 'VILLASIS',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'QUEZON',
    Location: 'AGDANGAN',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'QUEZON',
    Location: 'ALABAT',
    Rate: '165',
    Remarks: 'ISLAND TRANSFER',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'QUEZON',
    Location: 'ATIMONAN',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'QUEZON',
    Location: 'BUENAVISTA',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'QUEZON',
    Location: 'BURDEOS',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'QUEZON',
    Location: 'CALAUAG',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'QUEZON',
    Location: 'CANDELARIA',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'QUEZON',
    Location: 'CATANAUAN',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'QUEZON',
    Location: 'DOLORES',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'QUEZON',
    Location: 'GENERAL LUNA',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'QUEZON',
    Location: 'GENERAL NAKAR',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'QUEZON',
    Location: 'GUINAYANGAN',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'QUEZON',
    Location: 'GUMACA',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'QUEZON',
    Location: 'HONDAGUA',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'QUEZON',
    Location: 'INFANTA',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'QUEZON',
    Location: 'JOMALIG',
    Rate: '165',
    Remarks: 'ISLAND TRANSFER',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'QUEZON',
    Location: 'LOPEZ',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'QUEZON',
    Location: 'LUCBAN',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'QUEZON',
    Location: 'LUCENA',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'QUEZON',
    Location: 'MACALELON',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'QUEZON',
    Location: 'MAUBAN',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'QUEZON',
    Location: 'MULANAY',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'QUEZON',
    Location: 'PADRE BURGOS',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'QUEZON',
    Location: 'PAGBILAO',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'QUEZON',
    Location: 'PANUKULAN',
    Rate: '165',
    Remarks: 'ISLAND TRANSFER',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'QUEZON',
    Location: 'PATNANONGAN',
    Rate: '165',
    Remarks: 'ISLAND TRANSFER',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'QUEZON',
    Location: 'PEREZ',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'QUEZON',
    Location: 'PITOGO',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'QUEZON',
    Location: 'PLARIDEL',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'QUEZON',
    Location: 'POLILLO',
    Rate: '165',
    Remarks: 'ISLAND TRANSFER',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'QUEZON',
    Location: 'QUEZON',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'QUEZON',
    Location: 'REAL',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'QUEZON',
    Location: 'SAMPALOC',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'QUEZON',
    Location: 'SAN ANDRES',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'QUEZON',
    Location: 'SAN ANTONIO',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'QUEZON',
    Location: 'SAN FRANCISCO',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'QUEZON',
    Location: 'SAN NARCISO',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'QUEZON',
    Location: 'SARIAYA',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'QUEZON',
    Location: 'TAGKAWAYAN',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'QUEZON',
    Location: 'TAYABAS',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'QUEZON',
    Location: 'TIAONG',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'QUEZON',
    Location: 'UNISAN',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'QUIRINO',
    Location: 'AGLIPAY',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'QUIRINO',
    Location: 'CABARROGUIS',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'QUIRINO',
    Location: 'DIFFUN',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'QUIRINO',
    Location: 'MADDELA',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'QUIRINO',
    Location: 'NAGTIPUNAN',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'QUIRINO',
    Location: 'SAGUDAY',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'RIZAL',
    Location: 'ANGONO',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'RIZAL',
    Location: 'ANTIPOLO',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'RIZAL',
    Location: 'BARAS',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'RIZAL',
    Location: 'BINANGONAN',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'RIZAL',
    Location: 'CAINTA',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'RIZAL',
    Location: 'CARDONA',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'RIZAL',
    Location: 'JALA JALA',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'RIZAL',
    Location: 'MORONG',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'RIZAL',
    Location: 'PILILLA',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'RIZAL',
    Location: 'RODRIGUEZ (MONTALBAN)',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'RIZAL',
    Location: 'SAN MATEO',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'RIZAL',
    Location: 'TANAY',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'RIZAL',
    Location: 'TAYTAY',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'RIZAL',
    Location: 'TERESA',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'SORSOGON',
    Location: 'BACON',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'SORSOGON',
    Location: 'BARCELONA',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'SORSOGON',
    Location: 'BGY CALAYOAN',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'SORSOGON',
    Location: 'BULAN',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'SORSOGON',
    Location: 'BULUSAN',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'SORSOGON',
    Location: 'CASIGURAN',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'SORSOGON',
    Location: 'CASTILLA',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'SORSOGON',
    Location: 'DONSOL',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'SORSOGON',
    Location: 'GUBAT',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'SORSOGON',
    Location: 'IROSIN',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'SORSOGON',
    Location: 'JUBAN',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'SORSOGON',
    Location: 'MAGALLANES',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'SORSOGON',
    Location: 'MATNOG',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'SORSOGON',
    Location: 'PILAR',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'SORSOGON',
    Location: 'PRIETO DIAZ',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'SORSOGON',
    Location: 'SORSOGON',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'SORSOGON',
    Location: 'STA. MAGDALENA',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'TARLAC',
    Location: 'ANAO',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'TARLAC',
    Location: 'BAMBAN',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'TARLAC',
    Location: 'CAMILING',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'TARLAC',
    Location: 'CAPAS',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'TARLAC',
    Location: 'CONCEPCION',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'TARLAC',
    Location: 'GERONA',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'TARLAC',
    Location: 'LA PAZ',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'TARLAC',
    Location: 'MAYANTOC',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'TARLAC',
    Location: 'MONCADA',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'TARLAC',
    Location: 'PANIQUI',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'TARLAC',
    Location: 'PURA',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'TARLAC',
    Location: 'RAMOS',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'TARLAC',
    Location: 'SAN CLEMENTE',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'TARLAC',
    Location: 'SAN JOSE',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'TARLAC',
    Location: 'SAN MANUEL',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'TARLAC',
    Location: 'SAN MIGUEL',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'TARLAC',
    Location: 'STA. IGNACIA',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'TARLAC',
    Location: 'TARLAC',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'TARLAC',
    Location: 'VICTORIA',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'ZAMBALES',
    Location: 'BOTOLAN',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'ZAMBALES',
    Location: 'CABANGAN',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'ZAMBALES',
    Location: 'CANDELARIA',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'ZAMBALES',
    Location: 'CASTILLEJOS',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'ZAMBALES',
    Location: 'IBA',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'ZAMBALES',
    Location: 'MASINLOC',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'ZAMBALES',
    Location: 'OLONGAPO CITY',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'ZAMBALES',
    Location: 'PALAUIG CITY',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'ZAMBALES',
    Location: 'SAN ANTONIO',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'ZAMBALES',
    Location: 'SAN FELIPE',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'ZAMBALES',
    Location: 'SAN MARCELINO',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'ZAMBALES',
    Location: 'SAN NARCISO',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'ZAMBALES',
    Location: 'STA. CRUZ',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'ZAMBALES',
    Location: 'SUBIC',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'AGUSAN DEL NORTE',
    Location: 'BUENAVISTA',
    Rate: '140',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'AGUSAN DEL NORTE',
    Location: 'BUTUAN CITY',
    Rate: '140',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'AGUSAN DEL NORTE',
    Location: 'CABADBARAN',
    Rate: '140',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'AGUSAN DEL NORTE',
    Location: 'CARMEN',
    Rate: '140',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'AGUSAN DEL NORTE',
    Location: 'JABONGA',
    Rate: '140',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'AGUSAN DEL NORTE',
    Location: 'KITCHARAO',
    Rate: '140',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'AGUSAN DEL NORTE',
    Location: 'LAS NIEVES',
    Rate: '140',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'AGUSAN DEL NORTE',
    Location: 'MAGALLANES',
    Rate: '140',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'AGUSAN DEL NORTE',
    Location: 'NASIPIT',
    Rate: '140',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'AGUSAN DEL NORTE',
    Location: 'RT ROMUALDEZ',
    Rate: '140',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'AGUSAN DEL NORTE',
    Location: 'SANTIAGO',
    Rate: '140',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'AGUSAN DEL NORTE',
    Location: 'TUBAY',
    Rate: '140',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'AGUSAN DEL SUR',
    Location: 'BAYUGAN',
    Rate: '140',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'AGUSAN DEL SUR',
    Location: 'BUNAWAN',
    Rate: '190',
    Remarks: 'WITH TRANSSHIPMENT CHARGE',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'AGUSAN DEL SUR',
    Location: 'ESPERANZA',
    Rate: '140',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'AGUSAN DEL SUR',
    Location: 'LA PAZ',
    Rate: '140',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'AGUSAN DEL SUR',
    Location: 'LORETO',
    Rate: '190',
    Remarks: 'WITH TRANSSHIPMENT CHARGE',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'AGUSAN DEL SUR',
    Location: 'PROSPERIDAD',
    Rate: '190',
    Remarks: 'WITH TRANSSHIPMENT CHARGE',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'AGUSAN DEL SUR',
    Location: 'ROSARIO',
    Rate: '140',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'AGUSAN DEL SUR',
    Location: 'SAN FRANCISCO',
    Rate: '140',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'AGUSAN DEL SUR',
    Location: 'SAN LUIS',
    Rate: '190',
    Remarks: 'WITH TRANSSHIPMENT CHARGE',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'AGUSAN DEL SUR',
    Location: 'SANTA JOSEFA',
    Rate: '190',
    Remarks: 'WITH TRANSSHIPMENT CHARGE',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'AGUSAN DEL SUR',
    Location: 'TALACOGON',
    Rate: '190',
    Remarks: 'WITH TRANSSHIPMENT CHARGE',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'AGUSAN DEL SUR',
    Location: 'TRENTO',
    Rate: '190',
    Remarks: 'WITH TRANSSHIPMENT CHARGE',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'AGUSAN DEL SUR',
    Location: 'VERUELA',
    Rate: '140',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'BASILAN',
    Location: 'AKBAR',
    Rate: '190',
    Remarks: 'PICK-UP AT ZAMBOANGA STATION',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'BASILAN',
    Location: 'AL-BARKA',
    Rate: '190',
    Remarks: 'PICK-UP AT ZAMBOANGA STATION',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'BASILAN',
    Location: 'HADJI MOHAMMAD',
    Rate: '190',
    Remarks: 'PICK-UP AT ZAMBOANGA STATION',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'BASILAN',
    Location: 'HADJI MUHTAMAD',
    Rate: '190',
    Remarks: 'PICK-UP AT ZAMBOANGA STATION',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'BASILAN',
    Location: 'ISABELA CITY',
    Rate: '240',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'BASILAN',
    Location: 'LAMITAN CITY',
    Rate: '190',
    Remarks: 'PICK-UP AT ZAMBOANGA STATION',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'BASILAN',
    Location: 'LANTAWAN',
    Rate: '190',
    Remarks: 'PICK-UP AT ZAMBOANGA STATION',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'BASILAN',
    Location: 'MALUSO',
    Rate: '190',
    Remarks: 'PICK-UP AT ZAMBOANGA STATION',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'BASILAN',
    Location: 'SUMISIP',
    Rate: '190',
    Remarks: 'PICK-UP AT ZAMBOANGA STATION',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'BASILAN',
    Location: 'TABUAN-LASA',
    Rate: '190',
    Remarks: 'PICK-UP AT ZAMBOANGA STATION',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'BASILAN',
    Location: 'TIPO-TIPO',
    Rate: '190',
    Remarks: 'PICK-UP AT ZAMBOANGA STATION',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'BASILAN',
    Location: 'TUBURAN',
    Rate: '190',
    Remarks: 'PICK-UP AT ZAMBOANGA STATION',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'BASILAN',
    Location: 'UNGKAYA PUKAN',
    Rate: '190',
    Remarks: 'PICK-UP AT ZAMBOANGA STATION',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'BUKIDNON',
    Location: 'BAUNGON',
    Rate: '140',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'BUKIDNON',
    Location: 'CABANGLASAN',
    Rate: '190',
    Remarks: 'PICK-UP MALAYBALAY STATION ',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'BUKIDNON',
    Location: 'DAMULOG',
    Rate: '190',
    Remarks: 'WITH TRANSSHIPMENT CHARGE',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'BUKIDNON',
    Location: 'DANGCAGAN',
    Rate: '190',
    Remarks: 'WITH TRANSSHIPMENT CHARGE',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'BUKIDNON',
    Location: 'DON CARLOS',
    Rate: '140',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'BUKIDNON',
    Location: 'IMPASUGONG',
    Rate: '140',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'BUKIDNON',
    Location: 'KADINGILAN',
    Rate: '190',
    Remarks: 'WITH TRANSSHIPMENT CHARGE',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'BUKIDNON',
    Location: 'KALILANGAN',
    Rate: '190',
    Remarks: 'WITH TRANSSHIPMENT CHARGE',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'BUKIDNON',
    Location: 'KIBAWE',
    Rate: '190',
    Remarks: 'WITH TRANSSHIPMENT CHARGE',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'BUKIDNON',
    Location: 'KISOLON',
    Rate: '140',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'BUKIDNON',
    Location: 'KITAOTAO',
    Rate: '190',
    Remarks: 'WITH TRANSSHIPMENT CHARGE',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'BUKIDNON',
    Location: 'LANTAPAN',
    Rate: '190',
    Remarks: 'WITH TRANSSHIPMENT CHARGE',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'BUKIDNON',
    Location: 'LIBONA',
    Rate: '140',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'BUKIDNON',
    Location: 'MALAYBALAY',
    Rate: '190',
    Remarks: 'WITH TRANSSHIPMENT CHARGE',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'BUKIDNON',
    Location: 'MALITBOG',
    Rate: '190',
    Remarks: 'PICK-UP AT CAGAYAN STATION',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'BUKIDNON',
    Location: 'MANOLO FORTICH',
    Rate: '140',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'BUKIDNON',
    Location: 'MARAMAG',
    Rate: '190',
    Remarks: 'WITH TRANSSHIPMENT CHARGE',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'BUKIDNON',
    Location: 'PANGANTUCAN',
    Rate: '190',
    Remarks: 'WITH TRANSSHIPMENT CHARGE',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'BUKIDNON',
    Location: 'QUEZON',
    Rate: '190',
    Remarks: 'WITH TRANSSHIPMENT CHARGE',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'BUKIDNON',
    Location: 'SAN FERNANDO',
    Rate: '190',
    Remarks: 'PICK-UP MALAYBALAY STATION ',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'BUKIDNON',
    Location: 'SUMILAO',
    Rate: '140',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'BUKIDNON',
    Location: 'TALAKAG',
    Rate: '140',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'BUKIDNON',
    Location: 'VALENCIA',
    Rate: '190',
    Remarks: 'WITH TRANSSHIPMENT CHARGE',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'CAMIGUIN ISLAND',
    Location: 'CATARMAN',
    Rate: '240',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'CAMIGUIN ISLAND',
    Location: 'GUINSILIBAN',
    Rate: '240',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'CAMIGUIN ISLAND',
    Location: 'MAHINOG',
    Rate: '240',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'CAMIGUIN ISLAND',
    Location: 'MAMBAJAO',
    Rate: '240',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'CAMIGUIN ISLAND',
    Location: 'SAGAY',
    Rate: '240',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'COMPOSTELLA VALLEY',
    Location: 'COMPOSTELA',
    Rate: '140',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'COMPOSTELLA VALLEY',
    Location: 'LAAK (SAN VICENTE)',
    Rate: '140',
    Remarks: 'PICK-UP DAVAO STATION',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'COMPOSTELLA VALLEY',
    Location: 'MABINI (DONA ALICIA)',
    Rate: '140',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'COMPOSTELLA VALLEY',
    Location: 'MACO',
    Rate: '140',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'COMPOSTELLA VALLEY',
    Location: 'MARAGUSAN',
    Rate: '140',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'COMPOSTELLA VALLEY',
    Location: 'MAWAB',
    Rate: '140',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'COMPOSTELLA VALLEY',
    Location: 'MONKAYO',
    Rate: '140',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'COMPOSTELLA VALLEY',
    Location: 'MONTEVISTA',
    Rate: '140',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'COMPOSTELLA VALLEY',
    Location: 'NABUTURAN',
    Rate: '140',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'COMPOSTELLA VALLEY',
    Location: 'NEW BATAAN',
    Rate: '140',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'COMPOSTELLA VALLEY',
    Location: 'PANTUKAN',
    Rate: '140',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'COTABATO, NORTH',
    Location: 'ALAMADA',
    Rate: '140',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'COTABATO, NORTH',
    Location: 'ALEOSAN',
    Rate: '140',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'COTABATO, NORTH',
    Location: 'ANTIPAS',
    Rate: '140',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'COTABATO, NORTH',
    Location: 'BANISILAN',
    Rate: '140',
    Remarks: 'PICK-UP COTABATO STATION',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'COTABATO, NORTH',
    Location: 'CARMEN',
    Rate: '140',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'COTABATO, NORTH',
    Location: 'KABACAN',
    Rate: '140',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'COTABATO, NORTH',
    Location: 'KIDAPAWAN',
    Rate: '140',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'COTABATO, NORTH',
    Location: 'LIBUNGAN',
    Rate: '140',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'COTABATO, NORTH',
    Location: 'MAGPET',
    Rate: '140',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'COTABATO, NORTH',
    Location: 'MAKILALA',
    Rate: '140',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'COTABATO, NORTH',
    Location: 'MATALAM',
    Rate: '140',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'COTABATO, NORTH',
    Location: 'MIDSAYAP',
    Rate: '140',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'COTABATO, NORTH',
    Location: "M'LANG",
    Rate: '140',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'COTABATO, NORTH',
    Location: 'PIGKAWAYAN',
    Rate: '140',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'COTABATO, NORTH',
    Location: 'PIKIT',
    Rate: '140',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'COTABATO, NORTH',
    Location: 'PRESIDENT ROXAS',
    Rate: '140',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'COTABATO, NORTH',
    Location: 'TULUNAN',
    Rate: '140',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'COTABATO, SOUTH',
    Location: 'BANGA',
    Rate: '140',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'COTABATO, SOUTH',
    Location: 'GENERAL SANTOS CITY',
    Rate: '140',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'COTABATO, SOUTH',
    Location: 'KORONADAL',
    Rate: '140',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'COTABATO, SOUTH',
    Location: 'LAKE SEBU',
    Rate: '140',
    Remarks: 'PICK-UP AT GEN SAN STATION',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'COTABATO, SOUTH',
    Location: 'NORALA',
    Rate: '140',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'COTABATO, SOUTH',
    Location: 'POLOMOLOK',
    Rate: '140',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'COTABATO, SOUTH',
    Location: 'STO. NIÑO',
    Rate: '140',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'COTABATO, SOUTH',
    Location: 'SURALLAH',
    Rate: '140',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'COTABATO, SOUTH',
    Location: 'TAMPAKAN',
    Rate: '140',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'COTABATO, SOUTH',
    Location: 'TANTANGAN',
    Rate: '140',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'COTABATO, SOUTH',
    Location: "T'BOLI",
    Rate: '140',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'COTABATO, SOUTH',
    Location: 'TUPI',
    Rate: '140',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'DAVAO DEL NORTE',
    Location: 'ASUNCION',
    Rate: '140',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'DAVAO DEL NORTE',
    Location: 'BRAULIO E. DUJALI',
    Rate: '140',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'DAVAO DEL NORTE',
    Location: 'CARMEN',
    Rate: '140',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'DAVAO DEL NORTE',
    Location: 'KAPALONG',
    Rate: '140',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'DAVAO DEL NORTE',
    Location: 'NEW CORELLA',
    Rate: '140',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'DAVAO DEL NORTE',
    Location: 'PANABO CITY',
    Rate: '140',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'DAVAO DEL NORTE',
    Location: 'SAMAL CITY (SAMAL ISLAND)',
    Rate: '240',
    Remarks: '1 ON 1 DELIVERY',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'DAVAO DEL NORTE',
    Location: 'SAN ISIDRO',
    Rate: '190',
    Remarks: 'WITH TRANSSHIPMENT CHARGE',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'DAVAO DEL NORTE',
    Location: 'SANTO TOMAS',
    Rate: '140',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'DAVAO DEL NORTE',
    Location: 'TAGUM CITY',
    Rate: '140',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'DAVAO DEL NORTE',
    Location: 'TALAINGOD',
    Rate: '140',
    Remarks: 'PICK-UP AT DAVAO STATION',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'DAVAO DEL SUR ',
    Location: 'BANSALAN',
    Rate: '140',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'DAVAO DEL SUR ',
    Location: 'DAVAO CITY',
    Rate: '140',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'DAVAO DEL SUR ',
    Location: 'DIGOS CITY',
    Rate: '140',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'DAVAO DEL SUR ',
    Location: 'DON MARCELINO',
    Rate: '140',
    Remarks: 'PICK-UP AT DAVAO STATION',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'DAVAO DEL SUR ',
    Location: 'HAGONOY',
    Rate: '140',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'DAVAO DEL SUR ',
    Location: 'JOSE ABAD SANTOS',
    Rate: '140',
    Remarks: 'PICK-UP AT DAVAO STATION',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'DAVAO DEL SUR ',
    Location: 'KIBLAWAN',
    Rate: '140',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'DAVAO DEL SUR ',
    Location: 'MAGSAYSAY',
    Rate: '140',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'DAVAO DEL SUR ',
    Location: 'MALALAG',
    Rate: '140',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'DAVAO DEL SUR ',
    Location: 'MALITA',
    Rate: '190',
    Remarks: 'WITH TRANSSHIPMENT CHARGE',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'DAVAO DEL SUR ',
    Location: 'MATANAO',
    Rate: '140',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'DAVAO DEL SUR ',
    Location: 'PADADA',
    Rate: '140',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'DAVAO DEL SUR ',
    Location: 'SANTA CRUZ',
    Rate: '140',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'DAVAO DEL SUR ',
    Location: 'SANTA MARIA',
    Rate: '190',
    Remarks: 'WITH TRANSSHIPMENT CHARGE',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'DAVAO DEL SUR ',
    Location: 'SARANGANI  ',
    Rate: '140',
    Remarks: 'PICK-UP AT DAVAO STATION',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'DAVAO DEL SUR ',
    Location: 'SULOP',
    Rate: '140',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'DAVAO ORIENTAL',
    Location: 'BANAY-BANAY',
    Rate: '190',
    Remarks: 'WITH TRANSSHIPMENT CHARGE',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'DAVAO ORIENTAL',
    Location: 'BANGANGA',
    Rate: '140',
    Remarks: 'PICK-UP AT DAVAO STATION',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'DAVAO ORIENTAL',
    Location: 'BOSTON',
    Rate: '140',
    Remarks: 'PICK-UP AT DAVAO STATION',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'DAVAO ORIENTAL',
    Location: 'CARAGA',
    Rate: '140',
    Remarks: 'PICK-UP AT DAVAO STATION',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'DAVAO ORIENTAL',
    Location: 'CATEEL',
    Rate: '140',
    Remarks: 'PICK-UP AT DAVAO STATION',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'DAVAO ORIENTAL',
    Location: 'GOVERNOR GENEROSO',
    Rate: '190',
    Remarks: 'WITH TRANSSHIPMENT CHARGE',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'DAVAO ORIENTAL',
    Location: 'LUPON',
    Rate: '190',
    Remarks: 'WITH TRANSSHIPMENT CHARGE',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'DAVAO ORIENTAL',
    Location: 'MANAY',
    Rate: '140',
    Remarks: 'PICK-UP AT DAVAO STATION',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'DAVAO ORIENTAL',
    Location: 'MATI CITY',
    Rate: '190',
    Remarks: 'WITH TRANSSHIPMENT CHARGE',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'DAVAO ORIENTAL',
    Location: 'SAN ISIDRO',
    Rate: '190',
    Remarks: 'WITH TRANSSHIPMENT CHARGE',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'DAVAO ORIENTAL',
    Location: 'TARRAGONA',
    Rate: '140',
    Remarks: 'PICK-UP AT DAVAO STATION',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'LANAO DEL NORTE ',
    Location: 'BACOLOD ',
    Rate: '140',
    Remarks: 'PICK-UP AT ILIGAN STATION',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'LANAO DEL NORTE ',
    Location: 'BALOI',
    Rate: '140',
    Remarks: 'PICK-UP AT ILIGAN STATION',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'LANAO DEL NORTE ',
    Location: 'BAROY ',
    Rate: '140',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'LANAO DEL NORTE ',
    Location: 'ILIGAN CITY',
    Rate: '140',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'LANAO DEL NORTE ',
    Location: 'KAUSWAGAN',
    Rate: '140',
    Remarks: 'PICK-UP AT ILIGAN STATION',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'LANAO DEL NORTE ',
    Location: 'KOLAMBUGAN',
    Rate: '140',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'LANAO DEL NORTE ',
    Location: 'LALA (Maranding)',
    Rate: '190',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'LANAO DEL NORTE ',
    Location: 'LINAMON',
    Rate: '140',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'LANAO DEL NORTE ',
    Location: 'MAGSAYSAY',
    Rate: '140',
    Remarks: 'PICK-UP AT ILIGAN STATION',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'LANAO DEL NORTE ',
    Location: 'MAIGO',
    Rate: '140',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'LANAO DEL NORTE ',
    Location: 'MATUNGAO',
    Rate: '140',
    Remarks: 'PICK-UP AT ILIGAN STATION',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'LANAO DEL NORTE ',
    Location: 'MUNAI',
    Rate: '140',
    Remarks: 'PICK-UP AT ILIGAN STATION',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'LANAO DEL NORTE ',
    Location: 'NUNUNGAN',
    Rate: '140',
    Remarks: 'PICK-UP AT ILIGAN STATION',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'LANAO DEL NORTE ',
    Location: 'PANTAO RAGAT',
    Rate: '140',
    Remarks: 'PICK-UP AT ILIGAN STATION',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'LANAO DEL NORTE ',
    Location: 'PANTAR',
    Rate: '140',
    Remarks: 'PICK-UP AT ILIGAN STATION',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'LANAO DEL NORTE ',
    Location: 'POONA PIAGAPO',
    Rate: '140',
    Remarks: 'PICK-UP AT ILIGAN STATION',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'LANAO DEL NORTE ',
    Location: 'SALVADOR',
    Rate: '140',
    Remarks: 'PICK-UP AT ILIGAN STATION',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'LANAO DEL NORTE ',
    Location: 'SAPAD',
    Rate: '140',
    Remarks: 'PICK-UP AT ILIGAN STATION',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'LANAO DEL NORTE ',
    Location: 'SULTAN NAGA DIMAPORO',
    Rate: '140',
    Remarks: 'PICK-UP AT ILIGAN STATION',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'LANAO DEL NORTE ',
    Location: 'TAGOLOAN',
    Rate: '140',
    Remarks: 'PICK-UP AT ILIGAN STATION',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'LANAO DEL NORTE ',
    Location: 'TANGCAL',
    Rate: '140',
    Remarks: 'PICK-UP AT ILIGAN STATION',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'LANAO DEL NORTE ',
    Location: 'TUBOD',
    Rate: '140',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'LANAO DEL SUR',
    Location: 'BACOLOD GRANDE',
    Rate: '140',
    Remarks: 'PICK-UP AT ILIGAN STATION',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'LANAO DEL SUR',
    Location: 'BALABAGAN',
    Rate: '140',
    Remarks: 'PICK-UP AT ILIGAN STATION',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'LANAO DEL SUR',
    Location: 'BALINDONG',
    Rate: '140',
    Remarks: 'PICK-UP AT ILIGAN STATION',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'LANAO DEL SUR',
    Location: 'BAYANG',
    Rate: '140',
    Remarks: 'PICK-UP AT ILIGAN STATION',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'LANAO DEL SUR',
    Location: 'BINDAYAN',
    Rate: '140',
    Remarks: 'PICK-UP AT ILIGAN STATION',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'LANAO DEL SUR',
    Location: 'BUADIPOSO-BUNTONG',
    Rate: '140',
    Remarks: 'PICK-UP AT ILIGAN STATION',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'LANAO DEL SUR',
    Location: 'BUBONG',
    Rate: '140',
    Remarks: 'PICK-UP AT ILIGAN STATION',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'LANAO DEL SUR',
    Location: 'BUMBARAN',
    Rate: '140',
    Remarks: 'PICK-UP AT ILIGAN STATION',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'LANAO DEL SUR',
    Location: 'BUTIG',
    Rate: '140',
    Remarks: 'PICK-UP AT ILIGAN STATION',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'LANAO DEL SUR',
    Location: 'CALANOGAS',
    Rate: '140',
    Remarks: 'PICK-UP AT ILIGAN STATION',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'LANAO DEL SUR',
    Location: 'DITSAAN RAMAIN',
    Rate: '140',
    Remarks: 'PICK-UP AT ILIGAN STATION',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'LANAO DEL SUR',
    Location: 'GANASSI',
    Rate: '140',
    Remarks: 'PICK-UP AT ILIGAN STATION',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'LANAO DEL SUR',
    Location: 'KAPAI',
    Rate: '140',
    Remarks: 'PICK-UP AT ILIGAN STATION',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'LANAO DEL SUR',
    Location: 'KAPATAGAN ',
    Rate: '140',
    Remarks: 'PICK-UP AT ILIGAN STATION',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'LANAO DEL SUR',
    Location: 'LUMBA BAYABAO',
    Rate: '140',
    Remarks: 'PICK-UP AT ILIGAN STATION',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'LANAO DEL SUR',
    Location: 'LUMBACA-UNAYAN',
    Rate: '140',
    Remarks: 'PICK-UP AT ILIGAN STATION',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'LANAO DEL SUR',
    Location: 'LUMBATAN',
    Rate: '140',
    Remarks: 'PICK-UP AT ILIGAN STATION',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'LANAO DEL SUR',
    Location: 'LUMBAYANAGUE',
    Rate: '140',
    Remarks: 'PICK-UP AT ILIGAN STATION',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'LANAO DEL SUR',
    Location: 'MADALUM',
    Rate: '140',
    Remarks: 'PICK-UP AT ILIGAN STATION',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'LANAO DEL SUR',
    Location: 'MADAMBA',
    Rate: '140',
    Remarks: 'PICK-UP AT ILIGAN STATION',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'LANAO DEL SUR',
    Location: 'MAGUING',
    Rate: '140',
    Remarks: 'PICK-UP AT ILIGAN STATION',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'LANAO DEL SUR',
    Location: 'MALABANG',
    Rate: '140',
    Remarks: 'PICK-UP AT ILIGAN STATION',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'LANAO DEL SUR',
    Location: 'MARANTAO',
    Rate: '140',
    Remarks: 'PICK-UP AT ILIGAN STATION',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'LANAO DEL SUR',
    Location: 'MARAWI CITY',
    Rate: '140',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'LANAO DEL SUR',
    Location: 'MAROGONG',
    Rate: '140',
    Remarks: 'PICK-UP AT ILIGAN STATION',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'LANAO DEL SUR',
    Location: 'MASIU',
    Rate: '140',
    Remarks: 'PICK-UP AT ILIGAN STATION',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'LANAO DEL SUR',
    Location: 'MOLUNDO',
    Rate: '140',
    Remarks: 'PICK-UP AT ILIGAN STATION',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'LANAO DEL SUR',
    Location: 'PAGAYAWAN',
    Rate: '140',
    Remarks: 'PICK-UP AT ILIGAN STATION',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'LANAO DEL SUR',
    Location: 'PIAGAPO',
    Rate: '140',
    Remarks: 'PICK-UP AT ILIGAN STATION',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'LANAO DEL SUR',
    Location: 'POON-A-BAYABAO',
    Rate: '140',
    Remarks: 'PICK-UP AT ILIGAN STATION',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'LANAO DEL SUR',
    Location: 'PUALAS',
    Rate: '140',
    Remarks: 'PICK-UP AT ILIGAN STATION',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'LANAO DEL SUR',
    Location: 'SAGUIARAN',
    Rate: '140',
    Remarks: 'PICK-UP AT ILIGAN STATION',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'LANAO DEL SUR',
    Location: 'SULTAN DUMALONDON',
    Rate: '140',
    Remarks: 'PICK-UP AT ILIGAN STATION',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'LANAO DEL SUR',
    Location: 'SULTAN GUMANDER',
    Rate: '140',
    Remarks: 'PICK-UP AT ILIGAN STATION',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'LANAO DEL SUR',
    Location: 'TAGOLOAN II',
    Rate: '140',
    Remarks: 'PICK-UP AT ILIGAN STATION',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'LANAO DEL SUR',
    Location: 'TAMPARAN',
    Rate: '140',
    Remarks: 'PICK-UP AT ILIGAN STATION',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'LANAO DEL SUR',
    Location: 'TARAKA',
    Rate: '140',
    Remarks: 'PICK-UP AT ILIGAN STATION',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'LANAO DEL SUR',
    Location: 'TUBARAN',
    Rate: '140',
    Remarks: 'PICK-UP AT ILIGAN STATION',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'LANAO DEL SUR',
    Location: 'TUGAYA',
    Rate: '140',
    Remarks: 'PICK-UP AT ILIGAN STATION',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'LANAO DEL SUR',
    Location: 'WAO',
    Rate: '140',
    Remarks: 'PICK-UP AT ILIGAN STATION',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'MAGUINDANAO',
    Location: 'AMPATUAN',
    Rate: '190',
    Remarks: 'PICK-UP AT COTABATO STATION',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'MAGUINDANAO',
    Location: 'BARIRA',
    Rate: '190',
    Remarks: 'PICK-UP AT COTABATO STATION',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'MAGUINDANAO',
    Location: 'BULDON',
    Rate: '190',
    Remarks: 'PICK-UP AT COTABATO STATION',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'MAGUINDANAO',
    Location: 'BULUAN',
    Rate: '190',
    Remarks: 'PICK-UP AT COTABATO STATION',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'MAGUINDANAO',
    Location: 'COTABATO CITY',
    Rate: '190',
    Remarks: 'PICK-UP AT COTABATO STATION',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'MAGUINDANAO',
    Location: 'DATU ABDULLAH SANGKI',
    Rate: '190',
    Remarks: 'PICK-UP AT COTABATO STATION',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'MAGUINDANAO',
    Location: 'DATU ANGGAL MIDTIMBANG',
    Rate: '190',
    Remarks: 'PICK-UP AT COTABATO STATION',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'MAGUINDANAO',
    Location: 'DATU BLAH T. SINSUAT',
    Rate: '190',
    Remarks: 'PICK-UP AT COTABATO STATION',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'MAGUINDANAO',
    Location: 'DATU HOFFER AMPATUAN',
    Rate: '190',
    Remarks: 'PICK-UP AT COTABATO STATION',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'MAGUINDANAO',
    Location: 'DATU ODIN SINSUAT (DINAIG)',
    Rate: '190',
    Remarks: 'PICK-UP AT COTABATO STATION',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'MAGUINDANAO',
    Location: 'DATU PAGLAS',
    Rate: '190',
    Remarks: 'PICK-UP AT COTABATO STATION',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'MAGUINDANAO',
    Location: 'DATU PIANG',
    Rate: '190',
    Remarks: 'PICK-UP AT COTABATO STATION',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'MAGUINDANAO',
    Location: 'DATU SALIBO',
    Rate: '190',
    Remarks: 'PICK-UP AT COTABATO STATION',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'MAGUINDANAO',
    Location: 'DATU SAUDI AMPATUAN',
    Rate: '190',
    Remarks: 'PICK-UP AT COTABATO STATION',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'MAGUINDANAO',
    Location: 'DATU UNSAY',
    Rate: '190',
    Remarks: 'PICK-UP AT COTABATO STATION',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'MAGUINDANAO',
    Location: 'GEN. S.K. PENDATUN',
    Rate: '190',
    Remarks: 'PICK-UP AT COTABATO STATION',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'MAGUINDANAO',
    Location: 'GUINDULUNGAN',
    Rate: '190',
    Remarks: 'PICK-UP AT COTABATO STATION',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'MAGUINDANAO',
    Location: 'KABUNTALAN (TUMBAO)',
    Rate: '190',
    Remarks: 'PICK-UP AT COTABATO STATION',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'MAGUINDANAO',
    Location: 'MAMASAPANO',
    Rate: '190',
    Remarks: 'PICK-UP AT COTABATO STATION',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'MAGUINDANAO',
    Location: 'MANGUDADATU',
    Rate: '190',
    Remarks: 'PICK-UP AT COTABATO STATION',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'MAGUINDANAO',
    Location: 'MATANOG',
    Rate: '190',
    Remarks: 'PICK-UP AT COTABATO STATION',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'MAGUINDANAO',
    Location: 'NORTHERN KABUNTALAN',
    Rate: '190',
    Remarks: 'PICK-UP AT COTABATO STATION',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'MAGUINDANAO',
    Location: 'PAGAGAWAN',
    Rate: '190',
    Remarks: 'PICK-UP AT COTABATO STATION',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'MAGUINDANAO',
    Location: 'PAGALUNGAN',
    Rate: '190',
    Remarks: 'PICK-UP AT COTABATO STATION',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'MAGUINDANAO',
    Location: 'PAGLAT',
    Rate: '190',
    Remarks: 'PICK-UP AT COTABATO STATION',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'MAGUINDANAO',
    Location: 'PANDAG',
    Rate: '190',
    Remarks: 'PICK-UP AT COTABATO STATION',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'MAGUINDANAO',
    Location: 'PARANG',
    Rate: '190',
    Remarks: 'PICK-UP AT COTABATO STATION',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'MAGUINDANAO',
    Location: 'RAJAH BUAYAN',
    Rate: '190',
    Remarks: 'PICK-UP AT COTABATO STATION',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'MAGUINDANAO',
    Location: 'SHARIF AGUAK (MAGANOY)',
    Rate: '190',
    Remarks: 'PICK-UP AT COTABATO STATION',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'MAGUINDANAO',
    Location: 'SHARIFF SAYDONA MUSTAPHA',
    Rate: '190',
    Remarks: 'PICK-UP AT COTABATO STATION',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'MAGUINDANAO',
    Location: 'SOUTH UPI',
    Rate: '190',
    Remarks: 'PICK-UP AT COTABATO STATION',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'MAGUINDANAO',
    Location: 'SULTAN KUDARAT (NULING)',
    Rate: '190',
    Remarks: 'PICK-UP AT COTABATO STATION',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'MAGUINDANAO',
    Location: 'SULTAN MASTURA',
    Rate: '190',
    Remarks: 'PICK-UP AT COTABATO STATION',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'MAGUINDANAO',
    Location: 'SULTAN SA BARONGIS (LAMBAYONG)',
    Rate: '190',
    Remarks: 'PICK-UP AT COTABATO STATION',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'MAGUINDANAO',
    Location: 'TALAYAN',
    Rate: '190',
    Remarks: 'PICK-UP AT COTABATO STATION',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'MAGUINDANAO',
    Location: 'TALITAY',
    Rate: '190',
    Remarks: 'PICK-UP AT COTABATO STATION',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'MAGUINDANAO',
    Location: 'UPI',
    Rate: '190',
    Remarks: 'PICK-UP AT COTABATO STATION',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'MISAMIS OCCIDENTAL ',
    Location: 'ALORAN',
    Rate: '140',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'MISAMIS OCCIDENTAL ',
    Location: 'BALIANGAO',
    Rate: '140',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'MISAMIS OCCIDENTAL ',
    Location: 'BONIFACIO',
    Rate: '140',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'MISAMIS OCCIDENTAL ',
    Location: 'CALAMBA',
    Rate: '140',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'MISAMIS OCCIDENTAL ',
    Location: 'CLARIN',
    Rate: '140',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'MISAMIS OCCIDENTAL ',
    Location: 'CONCEPCION',
    Rate: '140',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'MISAMIS OCCIDENTAL ',
    Location: 'DON VICTORIANO',
    Rate: '140',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'MISAMIS OCCIDENTAL ',
    Location: 'JIMENEZ',
    Rate: '140',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'MISAMIS OCCIDENTAL ',
    Location: 'LOPEZ JAENA',
    Rate: '140',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'MISAMIS OCCIDENTAL ',
    Location: 'OROQUIETA CITY',
    Rate: '140',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'MISAMIS OCCIDENTAL ',
    Location: 'OZAMIS CITY',
    Rate: '140',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'MISAMIS OCCIDENTAL ',
    Location: 'PANAON',
    Rate: '140',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'MISAMIS OCCIDENTAL ',
    Location: 'PLARIDEL',
    Rate: '140',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'MISAMIS OCCIDENTAL ',
    Location: 'SAPANG DALAGA',
    Rate: '140',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'MISAMIS OCCIDENTAL ',
    Location: 'SINACABAN',
    Rate: '140',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'MISAMIS OCCIDENTAL ',
    Location: 'TANGUB CITY',
    Rate: '140',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'MISAMIS OCCIDENTAL ',
    Location: 'TUDELA',
    Rate: '140',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'MISAMIS ORIENTAL',
    Location: 'ALUBIJID',
    Rate: '140',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'MISAMIS ORIENTAL',
    Location: 'BALINGASAG',
    Rate: '140',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'MISAMIS ORIENTAL',
    Location: 'BALINGOAN',
    Rate: '140',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'MISAMIS ORIENTAL',
    Location: 'BINUANGAN',
    Rate: '140',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'MISAMIS ORIENTAL',
    Location: 'CAGAYAN DE ORO CITY',
    Rate: '140',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'MISAMIS ORIENTAL',
    Location: 'CLAVERIA',
    Rate: '140',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'MISAMIS ORIENTAL',
    Location: 'EL SALVADOR',
    Rate: '140',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'MISAMIS ORIENTAL',
    Location: 'GINGOOG CITY',
    Rate: '190',
    Remarks: 'WITH TRANSSHIPMENT CHARGE',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'MISAMIS ORIENTAL',
    Location: 'GITAGUM',
    Rate: '140',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'MISAMIS ORIENTAL',
    Location: 'INITAO',
    Rate: '140',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'MISAMIS ORIENTAL',
    Location: 'JASAAN',
    Rate: '140',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'MISAMIS ORIENTAL',
    Location: 'KINOGUITAN',
    Rate: '140',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'MISAMIS ORIENTAL',
    Location: 'LAGONGLONG',
    Rate: '140',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'MISAMIS ORIENTAL',
    Location: 'LAGUINDINGAN',
    Rate: '140',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'MISAMIS ORIENTAL',
    Location: 'LIBERTAD',
    Rate: '140',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'MISAMIS ORIENTAL',
    Location: 'LUGAIT',
    Rate: '140',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'MISAMIS ORIENTAL',
    Location: 'MAGSAYSAY',
    Rate: '190',
    Remarks: 'WITH TRANSSHIPMENT CHARGE',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'MISAMIS ORIENTAL',
    Location: 'MANTICAO',
    Rate: '140',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'MISAMIS ORIENTAL',
    Location: 'MEDINA',
    Rate: '190',
    Remarks: 'WITH TRANSSHIPMENT CHARGE',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'MISAMIS ORIENTAL',
    Location: 'NAAWAN',
    Rate: '140',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'MISAMIS ORIENTAL',
    Location: 'OPOL',
    Rate: '140',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'MISAMIS ORIENTAL',
    Location: 'SALAY',
    Rate: '140',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'MISAMIS ORIENTAL',
    Location: 'SUGBONGCOGON',
    Rate: '140',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'MISAMIS ORIENTAL',
    Location: 'TAGOLOAN',
    Rate: '140',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'MISAMIS ORIENTAL',
    Location: 'TALISAYAN',
    Rate: '140',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'MISAMIS ORIENTAL',
    Location: 'VILLANUEVA',
    Rate: '140',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'SARANGANI PROVICE',
    Location: 'ALABEL',
    Rate: '190',
    Remarks: 'WITH TRANSSHIPMENT CHARGE',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'SARANGANI PROVICE',
    Location: 'GLAN',
    Rate: '190',
    Remarks: 'WITH TRANSSHIPMENT CHARGE',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'SARANGANI PROVICE',
    Location: 'KIAMBA',
    Rate: '190',
    Remarks: 'WITH TRANSSHIPMENT CHARGE',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'SARANGANI PROVICE',
    Location: 'MAASIM',
    Rate: '190',
    Remarks: 'WITH TRANSSHIPMENT CHARGE',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'SARANGANI PROVICE',
    Location: 'MAITUM',
    Rate: '190',
    Remarks: 'WITH TRANSSHIPMENT CHARGE',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'SARANGANI PROVICE',
    Location: 'MALAPATAN',
    Rate: '190',
    Remarks: 'WITH TRANSSHIPMENT CHARGE',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'SARANGANI PROVICE',
    Location: 'MALUNGON',
    Rate: '190',
    Remarks: 'WITH TRANSSHIPMENT CHARGE',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'SULTAN KUDARAT',
    Location: 'BAGUMBAYAN',
    Rate: '190',
    Remarks: 'WITH TRANSSHIPMENT CHARGE',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'SULTAN KUDARAT',
    Location: 'COLUMBIO',
    Rate: '190',
    Remarks: 'WITH TRANSSHIPMENT CHARGE',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'SULTAN KUDARAT',
    Location: 'ESPERANZA',
    Rate: '190',
    Remarks: 'WITH TRANSSHIPMENT CHARGE',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'SULTAN KUDARAT',
    Location: 'ISULAN',
    Rate: '190',
    Remarks: 'WITH TRANSSHIPMENT CHARGE',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'SULTAN KUDARAT',
    Location: 'KALAMANSIG',
    Rate: '190',
    Remarks: 'WITH TRANSSHIPMENT CHARGE',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'SULTAN KUDARAT',
    Location: 'LAMBAYONG',
    Rate: '190',
    Remarks: 'WITH TRANSSHIPMENT CHARGE',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'SULTAN KUDARAT',
    Location: 'LEBAK ',
    Rate: '190',
    Remarks: 'WITH TRANSSHIPMENT CHARGE',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'SULTAN KUDARAT',
    Location: 'LUTAYAN',
    Rate: '190',
    Remarks: 'WITH TRANSSHIPMENT CHARGE',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'SULTAN KUDARAT',
    Location: 'PALIMBANG ',
    Rate: '190',
    Remarks: 'WITH TRANSSHIPMENT CHARGE',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'SULTAN KUDARAT',
    Location: 'PRESIDENT QUIRINO',
    Rate: '190',
    Remarks: 'WITH TRANSSHIPMENT CHARGE',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'SULTAN KUDARAT',
    Location: 'SEN. NINOY AQUINO',
    Rate: '190',
    Remarks: 'WITH TRANSSHIPMENT CHARGE',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'SULTAN KUDARAT',
    Location: 'TACURONG',
    Rate: '190',
    Remarks: 'WITH TRANSSHIPMENT CHARGE',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'SULU',
    Location: 'HADJI PANGLIMA TAHIL (MARUNGGAS)',
    Rate: '240',
    Remarks: 'PICK-UP AT ZAMBOANGA STATION (MEET UP ONLY)',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'SULU',
    Location: 'INDANAN',
    Rate: '240',
    Remarks: 'PICK-UP AT ZAMBOANGA STATION (MEET UP ONLY)',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'SULU',
    Location: 'JOLO',
    Rate: '240',
    Remarks: 'PICK-UP AT ZAMBOANGA STATION (MEET UP ONLY)',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'SULU',
    Location: 'KALINGALAN CALUANG',
    Rate: '240',
    Remarks: 'PICK-UP AT ZAMBOANGA STATION (MEET UP ONLY)',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'SULU',
    Location: 'LUGUS',
    Rate: '240',
    Remarks: 'PICK-UP AT ZAMBOANGA STATION (MEET UP ONLY)',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'SULU',
    Location: 'LUUK',
    Rate: '240',
    Remarks: 'PICK-UP AT ZAMBOANGA STATION (MEET UP ONLY)',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'SULU',
    Location: 'MAIMBUNG',
    Rate: '240',
    Remarks: 'PICK-UP AT ZAMBOANGA STATION (MEET UP ONLY)',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'SULU',
    Location: 'OLD PANAMAO',
    Rate: '240',
    Remarks: 'PICK-UP AT ZAMBOANGA STATION (MEET UP ONLY)',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'SULU',
    Location: 'OMAR',
    Rate: '240',
    Remarks: 'PICK-UP AT ZAMBOANGA STATION (MEET UP ONLY)',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'SULU',
    Location: 'PANDAMI',
    Rate: '240',
    Remarks: 'PICK-UP AT ZAMBOANGA STATION (MEET UP ONLY)',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'SULU',
    Location: 'PANGLIMA ESTINO (NEW PANAMAO)',
    Rate: '240',
    Remarks: 'PICK-UP AT ZAMBOANGA STATION (MEET UP ONLY)',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'SULU',
    Location: 'PANGUTARAN',
    Rate: '240',
    Remarks: 'PICK-UP AT ZAMBOANGA STATION (MEET UP ONLY)',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'SULU',
    Location: 'PARANG',
    Rate: '240',
    Remarks: 'PICK-UP AT ZAMBOANGA STATION (MEET UP ONLY)',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'SULU',
    Location: 'PATA',
    Rate: '240',
    Remarks: 'PICK-UP AT ZAMBOANGA STATION (MEET UP ONLY)',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'SULU',
    Location: 'PATIKUL',
    Rate: '240',
    Remarks: 'PICK-UP AT ZAMBOANGA STATION (MEET UP ONLY)',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'SULU',
    Location: 'SIASI',
    Rate: '240',
    Remarks: 'PICK-UP AT ZAMBOANGA STATION (MEET UP ONLY)',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'SULU',
    Location: 'TALIPAO',
    Rate: '240',
    Remarks: 'PICK-UP AT ZAMBOANGA STATION (MEET UP ONLY)',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'SULU',
    Location: 'TAPUL',
    Rate: '240',
    Remarks: 'PICK-UP AT ZAMBOANGA STATION (MEET UP ONLY)',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'SULU',
    Location: 'TONGKIL',
    Rate: '240',
    Remarks: 'PICK-UP AT ZAMBOANGA STATION (MEET UP ONLY)',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'SURIGAO DEL NORTE',
    Location: 'ALEGRIA',
    Rate: '140',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'SURIGAO DEL NORTE',
    Location: 'BACUAG',
    Rate: '140',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'SURIGAO DEL NORTE',
    Location: 'BURGOS',
    Rate: '140',
    Remarks: 'PICK-UP AT SURIGAO STATION',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'SURIGAO DEL NORTE',
    Location: 'CLAVERIA',
    Rate: '140',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'SURIGAO DEL NORTE',
    Location: 'DAPA',
    Rate: '140',
    Remarks: 'PICK-UP AT SURIGAO STATION',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'SURIGAO DEL NORTE',
    Location: 'DEL CARMEN',
    Rate: '140',
    Remarks: 'PICK-UP AT SURIGAO STATION',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'SURIGAO DEL NORTE',
    Location: 'GEN. LUNA',
    Rate: '140',
    Remarks: 'PICK-UP AT SURIGAO STATION',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'SURIGAO DEL NORTE',
    Location: 'GIGAQUIT',
    Rate: '140',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'SURIGAO DEL NORTE',
    Location: 'MAINIT',
    Rate: '140',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'SURIGAO DEL NORTE',
    Location: 'MALIMONO',
    Rate: '140',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'SURIGAO DEL NORTE',
    Location: 'PILAR',
    Rate: '140',
    Remarks: 'PICK-UP AT SURIGAO STATION',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'SURIGAO DEL NORTE',
    Location: 'PLACER',
    Rate: '190',
    Remarks: 'WITH TRANSSHIPMENT CHARGE',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'SURIGAO DEL NORTE',
    Location: 'SAN BENITO',
    Rate: '140',
    Remarks: 'PICK-UP AT SURIGAO STATION',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'SURIGAO DEL NORTE',
    Location: 'SAN FRANCISCO (ANAO-AOAN)',
    Rate: '140',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'SURIGAO DEL NORTE',
    Location: 'SAN ISIDRO',
    Rate: '140',
    Remarks: 'PICK-UP AT SURIGAO STATION',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'SURIGAO DEL NORTE',
    Location: 'SIARGAO ISLAND',
    Rate: '290',
    Remarks: 'ISLAND TRANSFER (MEET UP NEAREST PORT)',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'SURIGAO DEL NORTE',
    Location: 'SISON',
    Rate: '140',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'SURIGAO DEL NORTE',
    Location: 'SOCORRO',
    Rate: '140',
    Remarks: 'PICK-UP AT SURIGAO STATION',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'SURIGAO DEL NORTE',
    Location: 'STA MONICA',
    Rate: '140',
    Remarks: 'PICK-UP AT SURIGAO STATION',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'SURIGAO DEL NORTE',
    Location: 'SURIGAO CITY',
    Rate: '140',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'SURIGAO DEL NORTE',
    Location: 'TAGANA-AN',
    Rate: '190',
    Remarks: 'WITH TRANSSHIPMENT CHARGE',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'SURIGAO DEL NORTE',
    Location: 'TUBOD',
    Rate: '140',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'SURIGAO DEL SUR',
    Location: 'BAROBO',
    Rate: '140',
    Remarks: 'WITH DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'SURIGAO DEL SUR',
    Location: 'BAYABAS',
    Rate: '140',
    Remarks: 'WITH DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'SURIGAO DEL SUR',
    Location: 'BISLIG',
    Rate: '140',
    Remarks: 'WITH DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'SURIGAO DEL SUR',
    Location: 'CAGWAIT',
    Rate: '140',
    Remarks: 'WITH DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'SURIGAO DEL SUR',
    Location: 'CANTILAN',
    Rate: '140',
    Remarks: 'WITH DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'SURIGAO DEL SUR',
    Location: 'CARMEN',
    Rate: '140',
    Remarks: 'WITH DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'SURIGAO DEL SUR',
    Location: 'CARRASCAL',
    Rate: '140',
    Remarks: 'WITH DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'SURIGAO DEL SUR',
    Location: 'CORTES',
    Rate: '140',
    Remarks: 'WITH DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'SURIGAO DEL SUR',
    Location: 'HINATUAN',
    Rate: '140',
    Remarks: 'WITH DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'SURIGAO DEL SUR',
    Location: 'LANUZA',
    Rate: '140',
    Remarks: 'WITH DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'SURIGAO DEL SUR',
    Location: 'LIANGA',
    Rate: '140',
    Remarks: 'WITH DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'SURIGAO DEL SUR',
    Location: 'LINGIG',
    Rate: '140',
    Remarks: 'WITH DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'SURIGAO DEL SUR',
    Location: 'MADRID',
    Rate: '140',
    Remarks: 'WITH DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'SURIGAO DEL SUR',
    Location: 'MARIHATAG',
    Rate: '140',
    Remarks: 'WITH DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'SURIGAO DEL SUR',
    Location: 'SAN AGUSTIN',
    Rate: '140',
    Remarks: 'WITH DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'SURIGAO DEL SUR',
    Location: 'SAN MIGUEL',
    Rate: '140',
    Remarks: 'WITH DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'SURIGAO DEL SUR',
    Location: 'TAGBINA',
    Rate: '140',
    Remarks: 'WITH DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'SURIGAO DEL SUR',
    Location: 'TAGO',
    Rate: '140',
    Remarks: 'WITH DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'SURIGAO DEL SUR',
    Location: 'TANDAG',
    Rate: '140',
    Remarks: 'WITH DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'TAWI-TAWI',
    Location: 'ANGUYAN',
    Rate: '240',
    Remarks: 'PICK-UP AT ZAMBOANGA STATION (MEET UP ONLY)',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'TAWI-TAWI',
    Location: 'BONGAO',
    Rate: '240',
    Remarks: 'PICK-UP AT ZAMBOANGA STATION (MEET UP ONLY)',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'TAWI-TAWI',
    Location: 'MAPUN',
    Rate: '240',
    Remarks: 'PICK-UP AT ZAMBOANGA STATION (MEET UP ONLY)',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'TAWI-TAWI',
    Location: 'PANGLIMA SUGALA',
    Rate: '240',
    Remarks: 'PICK-UP AT ZAMBOANGA STATION (MEET UP ONLY)',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'TAWI-TAWI',
    Location: 'SAPA-SAPA',
    Rate: '240',
    Remarks: 'PICK-UP AT ZAMBOANGA STATION (MEET UP ONLY)',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'TAWI-TAWI',
    Location: 'SIBUTU',
    Rate: '240',
    Remarks: 'PICK-UP AT ZAMBOANGA STATION (MEET UP ONLY)',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'TAWI-TAWI',
    Location: 'SIMUNUL',
    Rate: '240',
    Remarks: 'PICK-UP AT ZAMBOANGA STATION (MEET UP ONLY)',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'TAWI-TAWI',
    Location: 'SITANGKAI',
    Rate: '240',
    Remarks: 'PICK-UP AT ZAMBOANGA STATION (MEET UP ONLY)',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'TAWI-TAWI',
    Location: 'SOUTH UBIAN',
    Rate: '240',
    Remarks: 'PICK-UP AT ZAMBOANGA STATION (MEET UP ONLY)',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'TAWI-TAWI',
    Location: 'TANDUBAS',
    Rate: '240',
    Remarks: 'PICK-UP AT ZAMBOANGA STATION (MEET UP ONLY)',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'TAWI-TAWI',
    Location: 'TURTLE ISLANDS',
    Rate: '240',
    Remarks: 'PICK-UP AT ZAMBOANGA STATION (MEET UP ONLY)',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'ZAMBOANGA DEL NORTE',
    Location: 'BACUNGAN (LEON T. POSTIGO)',
    Rate: '140',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'ZAMBOANGA DEL NORTE',
    Location: 'BALIGUIAN',
    Rate: '140',
    Remarks: 'PICK-UP AT DIPOLOG STATION',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'ZAMBOANGA DEL NORTE',
    Location: 'DAPITAN CITY',
    Rate: '140',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'ZAMBOANGA DEL NORTE',
    Location: 'DIPOLOG CITY',
    Rate: '140',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'ZAMBOANGA DEL NORTE',
    Location: 'GODOD',
    Rate: '140',
    Remarks: 'PICK-UP AT DIPOLOG STATION',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'ZAMBOANGA DEL NORTE',
    Location: 'GUTALAC',
    Rate: '140',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'ZAMBOANGA DEL NORTE',
    Location: 'JOSE DALMAN (PONOT)',
    Rate: '140',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'ZAMBOANGA DEL NORTE',
    Location: 'KALAWIT',
    Rate: '140',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'ZAMBOANGA DEL NORTE',
    Location: 'KATIPUNAN',
    Rate: '140',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'ZAMBOANGA DEL NORTE',
    Location: 'LA LIBERTAD',
    Rate: '140',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'ZAMBOANGA DEL NORTE',
    Location: 'LABASON',
    Rate: '140',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'ZAMBOANGA DEL NORTE',
    Location: 'LILOY',
    Rate: '140',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'ZAMBOANGA DEL NORTE',
    Location: 'MANUKAN',
    Rate: '140',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'ZAMBOANGA DEL NORTE',
    Location: 'MUTIA',
    Rate: '140',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'ZAMBOANGA DEL NORTE',
    Location: 'PINAN (NEW PINAN)',
    Rate: '140',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'ZAMBOANGA DEL NORTE',
    Location: 'POLANCO',
    Rate: '140',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'ZAMBOANGA DEL NORTE',
    Location: 'PRES. MANUEL A ROXAS',
    Rate: '140',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'ZAMBOANGA DEL NORTE',
    Location: 'RIZAL',
    Rate: '140',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'ZAMBOANGA DEL NORTE',
    Location: 'SALUG',
    Rate: '140',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'ZAMBOANGA DEL NORTE',
    Location: 'SERGIO OSMENA, SR.',
    Rate: '140',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'ZAMBOANGA DEL NORTE',
    Location: 'SIAYAN',
    Rate: '140',
    Remarks: 'PICK-UP AT DIPOLOG STATION',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'ZAMBOANGA DEL NORTE',
    Location: 'SIBUCO',
    Rate: '140',
    Remarks: 'PICK-UP AT DIPOLOG STATION',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'ZAMBOANGA DEL NORTE',
    Location: 'SIBUTAD',
    Rate: '140',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'ZAMBOANGA DEL NORTE',
    Location: 'SINDANGAN',
    Rate: '190',
    Remarks: 'WITH TRANSSHIPMENT CHARGE',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'ZAMBOANGA DEL NORTE',
    Location: 'SIOCON',
    Rate: '140',
    Remarks: 'PICK-UP AT DIPOLOG STATION',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'ZAMBOANGA DEL NORTE',
    Location: 'SIRAWAI',
    Rate: '140',
    Remarks: 'PICK-UP AT DIPOLOG STATION',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'ZAMBOANGA DEL NORTE',
    Location: 'TAMPILISAN',
    Rate: '140',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'ZAMBOANGA DEL SUR',
    Location: 'AURORA',
    Rate: '140',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'ZAMBOANGA DEL SUR',
    Location: 'BAYOG',
    Rate: '140',
    Remarks: 'PICK-UP AT PAGADIAN STATION',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'ZAMBOANGA DEL SUR',
    Location: 'DIMATALING  ',
    Rate: '140',
    Remarks: 'PICK-UP AT PAGADIAN STATION',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'ZAMBOANGA DEL SUR',
    Location: 'DINAS',
    Rate: '140',
    Remarks: 'PICK-UP AT PAGADIAN STATION',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'ZAMBOANGA DEL SUR',
    Location: 'DUMALINAO',
    Rate: '140',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'ZAMBOANGA DEL SUR',
    Location: 'DUMINGAG',
    Rate: '140',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'ZAMBOANGA DEL SUR',
    Location: 'GUIPOS',
    Rate: '140',
    Remarks: 'PICK-UP AT PAGADIAN STATION',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'ZAMBOANGA DEL SUR',
    Location: 'JOSEFINA',
    Rate: '140',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'ZAMBOANGA DEL SUR',
    Location: 'KUMALARANG',
    Rate: '140',
    Remarks: 'PICK-UP AT PAGADIAN STATION',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'ZAMBOANGA DEL SUR',
    Location: 'LABANGAN',
    Rate: '140',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'ZAMBOANGA DEL SUR',
    Location: 'LAKEWOOD',
    Rate: '140',
    Remarks: 'PICK-UP AT PAGADIAN STATION',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'ZAMBOANGA DEL SUR',
    Location: 'LAPUYAN',
    Rate: '140',
    Remarks: 'PICK-UP AT PAGADIAN STATION',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'ZAMBOANGA DEL SUR',
    Location: 'MAHAYAG',
    Rate: '140',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'ZAMBOANGA DEL SUR',
    Location: 'MARGOSATUBIG',
    Rate: '140',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'ZAMBOANGA DEL SUR',
    Location: 'MIDSALIP',
    Rate: '140',
    Remarks: 'PICK-UP AT PAGADIAN STATION',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'ZAMBOANGA DEL SUR',
    Location: 'MOLAVE',
    Rate: '140',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'ZAMBOANGA DEL SUR',
    Location: 'PAGADIAN CITY',
    Rate: '140',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'ZAMBOANGA DEL SUR',
    Location: 'PITOGO',
    Rate: '140',
    Remarks: 'PICK-UP AT PAGADIAN STATION',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'ZAMBOANGA DEL SUR',
    Location: 'RAMON MAGSAYSAY',
    Rate: '140',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'ZAMBOANGA DEL SUR',
    Location: 'SAN MIGUEL',
    Rate: '140',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'ZAMBOANGA DEL SUR',
    Location: 'SAN PABLO',
    Rate: '140',
    Remarks: 'PICK-UP AT PAGADIAN STATION',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'ZAMBOANGA DEL SUR',
    Location: 'SOMINOT (DON MARIANO)',
    Rate: '140',
    Remarks: 'PICK-UP AT PAGADIAN STATION',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'ZAMBOANGA DEL SUR',
    Location: 'TABINA',
    Rate: '140',
    Remarks: 'PICK-UP AT PAGADIAN STATION',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'ZAMBOANGA DEL SUR',
    Location: 'TAMBULIG',
    Rate: '140',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'ZAMBOANGA DEL SUR',
    Location: 'TIGBAO',
    Rate: '140',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'ZAMBOANGA DEL SUR',
    Location: 'TUKURAN',
    Rate: '140',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'ZAMBOANGA DEL SUR',
    Location: 'VINCENZO A. SAGUN',
    Rate: '140',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'ZAMBOANGA DEL SUR',
    Location: 'ZAMBOANGA CITY',
    Rate: '140',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'ZAMBOANGA SIBUGAY',
    Location: 'ALICIA',
    Rate: '140',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'ZAMBOANGA SIBUGAY',
    Location: 'BUUG',
    Rate: '140',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'ZAMBOANGA SIBUGAY',
    Location: 'DIPLAHAN',
    Rate: '140',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'ZAMBOANGA SIBUGAY',
    Location: 'IMELDA',
    Rate: '140',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'ZAMBOANGA SIBUGAY',
    Location: 'IPIL',
    Rate: '140',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'ZAMBOANGA SIBUGAY',
    Location: 'KABASALAN',
    Rate: '140',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'ZAMBOANGA SIBUGAY',
    Location: 'MABUHAY',
    Rate: '140',
    Remarks: 'PICK-UP AT ZAMBOANGA STATION',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'ZAMBOANGA SIBUGAY',
    Location: 'MALANGAS',
    Rate: '140',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'ZAMBOANGA SIBUGAY',
    Location: 'NAGA',
    Rate: '140',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'ZAMBOANGA SIBUGAY',
    Location: 'OLUTANGA',
    Rate: '140',
    Remarks: 'PICK-UP AT ZAMBOANGA STATION',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'ZAMBOANGA SIBUGAY',
    Location: 'PAYAO',
    Rate: '140',
    Remarks: 'PICK-UP AT ZAMBOANGA STATION',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'ZAMBOANGA SIBUGAY',
    Location: 'ROSELLER T. LIM',
    Rate: '140',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'ZAMBOANGA SIBUGAY',
    Location: 'SIAYAN',
    Rate: '140',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'ZAMBOANGA SIBUGAY',
    Location: 'TALUSAN',
    Rate: '140',
    Remarks: 'PICK-UP AT ZAMBOANGA STATION',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'ZAMBOANGA SIBUGAY',
    Location: 'TITAY',
    Rate: '140',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'ZAMBOANGA SIBUGAY',
    Location: 'TUNGAWAN',
    Rate: '140',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'AKLAN',
    Location: 'ALTAVAS',
    Rate: '130',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'AKLAN',
    Location: 'BALETE',
    Rate: '130',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'AKLAN',
    Location: 'BANGA',
    Rate: '130',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'AKLAN',
    Location: 'BATAN',
    Rate: '130',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'AKLAN',
    Location: 'BURUANGA',
    Rate: '230',
    Remarks: '1 ON 1 DELIVERY',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'AKLAN',
    Location: 'IBAJAY',
    Rate: '130',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'AKLAN',
    Location: 'KALIBO',
    Rate: '130',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'AKLAN',
    Location: 'LEZO',
    Rate: '130',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'AKLAN',
    Location: 'LIBACAO',
    Rate: '130',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'AKLAN',
    Location: 'MADALAG',
    Rate: '230',
    Remarks: '1 ON 1 DELIVERY',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'AKLAN',
    Location: 'MAKATO',
    Rate: '130',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'AKLAN',
    Location: 'MALAY ',
    Rate: '130',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'AKLAN',
    Location: 'MALINAO',
    Rate: '130',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'AKLAN',
    Location: 'NABAS',
    Rate: '130',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'AKLAN',
    Location: 'NEW WASHINGTON',
    Rate: '130',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'AKLAN',
    Location: 'NUMANCIA',
    Rate: '130',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'AKLAN',
    Location: 'TANGALAN',
    Rate: '130',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'ANTIQUE ',
    Location: 'ANINI-Y',
    Rate: '130',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'ANTIQUE ',
    Location: 'BARBAZA',
    Rate: '130',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'ANTIQUE ',
    Location: 'BELISON',
    Rate: '130',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'ANTIQUE ',
    Location: 'BUGASONG',
    Rate: '130',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'ANTIQUE ',
    Location: 'CALUYA ISLAND',
    Rate: '230',
    Remarks: '1 ON 1 DELIVERY',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'ANTIQUE ',
    Location: 'CULASI',
    Rate: '130',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'ANTIQUE ',
    Location: 'HAMTIC',
    Rate: '130',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'ANTIQUE ',
    Location: 'LAUA-AN',
    Rate: '130',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'ANTIQUE ',
    Location: 'LIBERTAD',
    Rate: '230',
    Remarks: '1 ON 1 DELIVERY',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'ANTIQUE ',
    Location: 'PANDAN',
    Rate: '130',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'ANTIQUE ',
    Location: 'PATNONGON',
    Rate: '130',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'ANTIQUE ',
    Location: 'SAN JOSE DE BUENAVISTA',
    Rate: '130',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'ANTIQUE ',
    Location: 'SAN REMIGIO',
    Rate: '230',
    Remarks: '1 ON 1 DELIVERY',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'ANTIQUE ',
    Location: 'SEBASTE',
    Rate: '180',
    Remarks: 'WITH TRANSSHIPMENT CHARGE',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'ANTIQUE ',
    Location: 'SIBALOM',
    Rate: '130',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'ANTIQUE ',
    Location: 'TIBIAO',
    Rate: '130',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'ANTIQUE ',
    Location: 'TOBIAS FORNIER (DAO)',
    Rate: '130',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'ANTIQUE ',
    Location: 'VALDERRAMA',
    Rate: '130',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'BILIRAN',
    Location: 'ALMERIA',
    Rate: '180',
    Remarks: 'WITH TRANSSHIPMENT CHARGE',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'BILIRAN',
    Location: 'BILIRAN',
    Rate: '180',
    Remarks: 'WITH TRANSSHIPMENT CHARGE',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'BILIRAN',
    Location: 'CABUGCAYAN',
    Rate: '180',
    Remarks: 'WITH TRANSSHIPMENT CHARGE',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'BILIRAN',
    Location: 'CAIBIRAN',
    Rate: '180',
    Remarks: 'WITH TRANSSHIPMENT CHARGE',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'BILIRAN',
    Location: 'CULABA',
    Rate: '180',
    Remarks: 'WITH TRANSSHIPMENT CHARGE',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'BILIRAN',
    Location: 'KAWAYAN',
    Rate: '180',
    Remarks: 'WITH TRANSSHIPMENT CHARGE',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'BILIRAN',
    Location: 'MARIPIPI',
    Rate: '230',
    Remarks: '1 ON 1 DELIVERY',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'BILIRAN',
    Location: 'NAVAL',
    Rate: '180',
    Remarks: 'WITH TRANSSHIPMENT CHARGE',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'BOHOL ',
    Location: 'ALBURQUERQUE',
    Rate: '130',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'BOHOL ',
    Location: 'ALICIA',
    Rate: '180',
    Remarks: 'WITH TRANSSHIPMENT CHARGE',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'BOHOL ',
    Location: 'ANDA',
    Rate: '230',
    Remarks: '1 ON 1 DELIVERY',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'BOHOL ',
    Location: 'ANTEQUERA',
    Rate: '130',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'BOHOL ',
    Location: 'BACLAYON',
    Rate: '130',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'BOHOL ',
    Location: 'BALILIHAN',
    Rate: '130',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'BOHOL ',
    Location: 'BATUAN',
    Rate: '130',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'BOHOL ',
    Location: 'BILAR',
    Rate: '130',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'BOHOL ',
    Location: 'BUENAVISTA',
    Rate: '130',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'BOHOL ',
    Location: 'CALAPE',
    Rate: '130',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'BOHOL ',
    Location: 'CANDIJAY',
    Rate: '130',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'BOHOL ',
    Location: 'CARMEN',
    Rate: '130',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'BOHOL ',
    Location: 'CATIGBIAN',
    Rate: '130',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'BOHOL ',
    Location: 'CLARIN',
    Rate: '130',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'BOHOL ',
    Location: 'CORELLA',
    Rate: '130',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'BOHOL ',
    Location: 'CORTES',
    Rate: '130',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'BOHOL ',
    Location: 'DAGOHOY',
    Rate: '130',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'BOHOL ',
    Location: 'DANAO',
    Rate: '130',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'BOHOL ',
    Location: 'DAUIS',
    Rate: '130',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'BOHOL ',
    Location: 'DIMIAO',
    Rate: '130',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'BOHOL ',
    Location: 'DUERO',
    Rate: '130',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'BOHOL ',
    Location: 'GARCIA HERNANDEZ',
    Rate: '130',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'BOHOL ',
    Location: 'GUINDULMAN',
    Rate: '130',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'BOHOL ',
    Location: 'INABANGA',
    Rate: '130',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'BOHOL ',
    Location: 'JAGNA',
    Rate: '130',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'BOHOL ',
    Location: 'JANOPOL',
    Rate: '130',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'BOHOL ',
    Location: 'JETAFE',
    Rate: '130',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'BOHOL ',
    Location: 'LILA',
    Rate: '130',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'BOHOL ',
    Location: 'LOAY',
    Rate: '130',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'BOHOL ',
    Location: 'LOBOC',
    Rate: '130',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'BOHOL ',
    Location: 'LOON',
    Rate: '130',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'BOHOL ',
    Location: 'MABINI',
    Rate: '230',
    Remarks: '1 ON 1 DELIVERY',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'BOHOL ',
    Location: 'MARIBOJOC',
    Rate: '130',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'BOHOL ',
    Location: 'PANGLAO',
    Rate: '130',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'BOHOL ',
    Location: 'PILAR',
    Rate: '130',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'BOHOL ',
    Location: 'PRES. CARLOS P. GARCIA IS.',
    Rate: '230',
    Remarks: '1 ON 1 DELIVERY',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'BOHOL ',
    Location: 'SAGBAYAN',
    Rate: '130',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'BOHOL ',
    Location: 'SAN ISIDRO',
    Rate: '130',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'BOHOL ',
    Location: 'SAN MIGUEL',
    Rate: '130',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'BOHOL ',
    Location: 'SEVILLA',
    Rate: '130',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'BOHOL ',
    Location: 'SIERRA BULLONES',
    Rate: '130',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'BOHOL ',
    Location: 'SIKATUNA',
    Rate: '130',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'BOHOL ',
    Location: 'TAGBILARAN CITY',
    Rate: '130',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'BOHOL ',
    Location: 'TALIBON',
    Rate: '130',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'BOHOL ',
    Location: 'TRINIDAD',
    Rate: '130',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'BOHOL ',
    Location: 'TUBIGON',
    Rate: '130',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'BOHOL ',
    Location: 'UBAY',
    Rate: '180',
    Remarks: 'WITH TRANSSHIPMENT CHARGE',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'BOHOL ',
    Location: 'VALENCIA',
    Rate: '130',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'CAPIZ',
    Location: 'CUARTERO',
    Rate: '130',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'CAPIZ',
    Location: 'DAO',
    Rate: '130',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'CAPIZ',
    Location: 'DUMALAG',
    Rate: '130',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'CAPIZ',
    Location: 'DUMARAO',
    Rate: '130',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'CAPIZ',
    Location: 'IVISAN',
    Rate: '130',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'CAPIZ',
    Location: 'JAMINDAN',
    Rate: '130',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'CAPIZ',
    Location: 'MA-AYON',
    Rate: '130',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'CAPIZ',
    Location: 'MAMBUSAO',
    Rate: '130',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'CAPIZ',
    Location: 'PANAY',
    Rate: '130',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'CAPIZ',
    Location: 'PANIT-AN',
    Rate: '130',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'CAPIZ',
    Location: 'PILAR',
    Rate: '130',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'CAPIZ',
    Location: 'PONTEVEDRA',
    Rate: '130',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'CAPIZ',
    Location: 'PRESIDENT ROXAS',
    Rate: '130',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'CAPIZ',
    Location: 'ROXAS CITY',
    Rate: '130',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'CAPIZ',
    Location: 'SAPI-AN',
    Rate: '130',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'CAPIZ',
    Location: 'SIGMA',
    Rate: '130',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'CAPIZ',
    Location: 'TAPAZ',
    Rate: '130',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'CEBU',
    Location: 'ALCANTARA',
    Rate: '130',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'CEBU',
    Location: 'ALCOY',
    Rate: '130',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'CEBU',
    Location: 'ALEGRIA',
    Rate: '130',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'CEBU',
    Location: 'ALOGUINSAN',
    Rate: '130',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'CEBU',
    Location: 'ARGAO',
    Rate: '130',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'CEBU',
    Location: 'ASTURIAS',
    Rate: '130',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'CEBU',
    Location: 'BADIAN',
    Rate: '130',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'CEBU',
    Location: 'BALAMBAN',
    Rate: '130',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'CEBU',
    Location: 'BANTAYAN IS.',
    Rate: '230',
    Remarks: '1 ON 1 DELIVERY',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'CEBU',
    Location: 'BARILI',
    Rate: '130',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'CEBU',
    Location: 'BOGO',
    Rate: '130',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'CEBU',
    Location: 'BOLJOON',
    Rate: '130',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'CEBU',
    Location: 'BORBON',
    Rate: '130',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'CEBU',
    Location: 'CARCAR',
    Rate: '130',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'CEBU',
    Location: 'CARMEN',
    Rate: '130',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'CEBU',
    Location: 'CATMON',
    Rate: '130',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'CEBU',
    Location: 'CEBU CITY',
    Rate: '130',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'CEBU',
    Location: 'COMPOSTELA',
    Rate: '130',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'CEBU',
    Location: 'CONSOLACION',
    Rate: '130',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'CEBU',
    Location: 'CORDOVA',
    Rate: '130',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'CEBU',
    Location: 'DAAN BANTAYAN',
    Rate: '130',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'CEBU',
    Location: 'DALAGUETE',
    Rate: '130',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'CEBU',
    Location: 'DANAO CTY',
    Rate: '130',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'CEBU',
    Location: 'DUMANJUG',
    Rate: '130',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'CEBU',
    Location: 'GINATILAN',
    Rate: '130',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'CEBU',
    Location: 'LAPU-LAPU CITY',
    Rate: '130',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'CEBU',
    Location: 'LILOAN',
    Rate: '130',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'CEBU',
    Location: 'MADRIDEJOS, BANTAYAN IS.',
    Rate: '230',
    Remarks: '1 ON 1 DELIVERY',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'CEBU',
    Location: 'MALABUYOC',
    Rate: '130',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'CEBU',
    Location: 'MANDAUE CITY',
    Rate: '130',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'CEBU',
    Location: 'MEDELLIN',
    Rate: '130',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'CEBU',
    Location: 'MINGLANILLA',
    Rate: '130',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'CEBU',
    Location: 'MOALBOAL',
    Rate: '130',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'CEBU',
    Location: 'NAGA',
    Rate: '130',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'CEBU',
    Location: 'OSLOB',
    Rate: '130',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'CEBU',
    Location: 'PILAR, CAMOTES IS.',
    Rate: '230',
    Remarks: '1 ON 1 DELIVERY',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'CEBU',
    Location: 'PINAMUNGAHAN',
    Rate: '130',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'CEBU',
    Location: 'PORO, CAMOTES IS.',
    Rate: '230',
    Remarks: '1 ON 1 DELIVERY',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'CEBU',
    Location: 'RONDA',
    Rate: '130',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'CEBU',
    Location: 'SAMBOAN',
    Rate: '130',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'CEBU',
    Location: 'SAN FERNANDO',
    Rate: '130',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'CEBU',
    Location: 'SAN FRANCISCO, CAMOTES IS.',
    Rate: '230',
    Remarks: '1 ON 1 DELIVERY',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'CEBU',
    Location: 'SAN REMIGIO',
    Rate: '130',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'CEBU',
    Location: 'SANTA FE, BANTAYAN IS.',
    Rate: '230',
    Remarks: '1 ON 1 DELIVERY',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'CEBU',
    Location: 'SANTANDER',
    Rate: '130',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'CEBU',
    Location: 'SIBONGA',
    Rate: '130',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'CEBU',
    Location: 'SOGOD',
    Rate: '130',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'CEBU',
    Location: 'TABOGON',
    Rate: '130',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'CEBU',
    Location: 'TABUELAN',
    Rate: '130',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'CEBU',
    Location: 'TALISAY CITY',
    Rate: '130',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'CEBU',
    Location: 'TOLEDO CITY',
    Rate: '130',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'CEBU',
    Location: 'TUBURAN',
    Rate: '130',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'CEBU',
    Location: 'TUDELA, CAMOTES IS.',
    Rate: '230',
    Remarks: '1 ON 1 DELIVERY',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'GUIMARAS ISLAND ',
    Location: 'BUENAVISTA',
    Rate: '230',
    Remarks: '1 ON 1 DELIVERY',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'GUIMARAS ISLAND ',
    Location: 'JORDAN',
    Rate: '230',
    Remarks: '1 ON 1 DELIVERY',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'GUIMARAS ISLAND ',
    Location: 'NUEVA VALENCIA',
    Rate: '230',
    Remarks: '1 ON 1 DELIVERY',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'GUIMARAS ISLAND ',
    Location: 'SAN LORENZO',
    Rate: '230',
    Remarks: '1 ON 1 DELIVERY',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'GUIMARAS ISLAND ',
    Location: 'SIBUNAG',
    Rate: '230',
    Remarks: '1 ON 1 DELIVERY',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'ILOILO',
    Location: 'AJUY',
    Rate: '130',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'ILOILO',
    Location: 'ALIMODIAN',
    Rate: '130',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'ILOILO',
    Location: 'ANILAO',
    Rate: '130',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'ILOILO',
    Location: 'AREVALO',
    Rate: '130',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'ILOILO',
    Location: 'BADIANGAN',
    Rate: '230',
    Remarks: '1 ON 1 DELIVERY',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'ILOILO',
    Location: 'BALASAN',
    Rate: '180',
    Remarks: 'WITH TRANSSHIPMENT CHARGE',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'ILOILO',
    Location: 'BANATE',
    Rate: '130',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'ILOILO',
    Location: 'BAROTAC NUEVO',
    Rate: '130',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'ILOILO',
    Location: 'BAROTAC VIEJO',
    Rate: '130',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'ILOILO',
    Location: 'BATAD',
    Rate: '180',
    Remarks: 'WITH TRANSSHIPMENT CHARGE',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'ILOILO',
    Location: 'BINGAWAN',
    Rate: '130',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'ILOILO',
    Location: 'CABATUAN',
    Rate: '130',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'ILOILO',
    Location: 'CALINOG',
    Rate: '130',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'ILOILO',
    Location: 'CARLES',
    Rate: '180',
    Remarks: 'WITH TRANSSHIPMENT CHARGE',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'ILOILO',
    Location: 'CONCEPCION',
    Rate: '180',
    Remarks: 'WITH TRANSSHIPMENT CHARGE',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'ILOILO',
    Location: 'DINGLE',
    Rate: '130',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'ILOILO',
    Location: 'DUEÑAS',
    Rate: '130',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'ILOILO',
    Location: 'DUMANGAS',
    Rate: '130',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'ILOILO',
    Location: 'ESTANCIA',
    Rate: '180',
    Remarks: 'WITH TRANSSHIPMENT CHARGE',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'ILOILO',
    Location: 'GUIMBAL',
    Rate: '130',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'ILOILO',
    Location: 'IGBARAS',
    Rate: '230',
    Remarks: '1 ON 1 DELIVERY',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'ILOILO',
    Location: 'ILOILO CITY',
    Rate: '130',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'ILOILO',
    Location: 'JANIUAY',
    Rate: '130',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'ILOILO',
    Location: 'JARO',
    Rate: '130',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'ILOILO',
    Location: 'LA PAZ',
    Rate: '130',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'ILOILO',
    Location: 'LAMBUNAO',
    Rate: '130',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'ILOILO',
    Location: 'LAWIGAN',
    Rate: '130',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'ILOILO',
    Location: 'LEGANES',
    Rate: '130',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'ILOILO',
    Location: 'LEMERY',
    Rate: '130',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'ILOILO',
    Location: 'LEON',
    Rate: '130',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'ILOILO',
    Location: 'MAASIN',
    Rate: '130',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'ILOILO',
    Location: 'MANDURRIAO',
    Rate: '130',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'ILOILO',
    Location: 'MIAGAO',
    Rate: '130',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'ILOILO',
    Location: 'MINA',
    Rate: '130',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'ILOILO',
    Location: 'MOLO',
    Rate: '130',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'ILOILO',
    Location: 'NEW LUCENA',
    Rate: '130',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'ILOILO',
    Location: 'OTON',
    Rate: '130',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'ILOILO',
    Location: 'PASSI CITY',
    Rate: '130',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'ILOILO',
    Location: 'PAVIA',
    Rate: '130',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'ILOILO',
    Location: 'POTOTAN',
    Rate: '130',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'ILOILO',
    Location: 'SAN DIONISIO',
    Rate: '180',
    Remarks: 'WITH TRANSSHIPMENT CHARGE',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'ILOILO',
    Location: 'SAN ENRIQUE',
    Rate: '130',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'ILOILO',
    Location: 'SAN JOAQUIN',
    Rate: '130',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'ILOILO',
    Location: 'SAN MIGUEL',
    Rate: '130',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'ILOILO',
    Location: 'SAN RAFAEL',
    Rate: '130',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'ILOILO',
    Location: 'SANTA BARBARA',
    Rate: '130',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'ILOILO',
    Location: 'SARA',
    Rate: '180',
    Remarks: 'WITH TRANSSHIPMENT CHARGE',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'ILOILO',
    Location: 'TIGBAUAN',
    Rate: '130',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'ILOILO',
    Location: 'TUBUNGAN',
    Rate: '230',
    Remarks: '1 ON 1 DELIVERY',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'ILOILO',
    Location: 'ZARRAGA',
    Rate: '130',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'LEYTE ',
    Location: 'ABUYOG',
    Rate: '130',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'LEYTE ',
    Location: 'ALANGALANG',
    Rate: '130',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'LEYTE ',
    Location: 'ALBUERA',
    Rate: '130',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'LEYTE ',
    Location: 'BABATNGON',
    Rate: '130',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'LEYTE ',
    Location: 'BARUGO',
    Rate: '130',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'LEYTE ',
    Location: 'BATO',
    Rate: '130',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'LEYTE ',
    Location: 'BAYBAY',
    Rate: '130',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'LEYTE ',
    Location: 'BURAUEN',
    Rate: '130',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'LEYTE ',
    Location: 'CALUBIAN',
    Rate: '130',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'LEYTE ',
    Location: 'CAPOOCAN',
    Rate: '130',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'LEYTE ',
    Location: 'CARIGARA',
    Rate: '130',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'LEYTE ',
    Location: 'DAGAMI',
    Rate: '130',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'LEYTE ',
    Location: 'DULAG',
    Rate: '130',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'LEYTE ',
    Location: 'HILONGOS',
    Rate: '130',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'LEYTE ',
    Location: 'HINDANG',
    Rate: '130',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'LEYTE ',
    Location: 'INOPACAN',
    Rate: '130',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'LEYTE ',
    Location: 'ISABEL',
    Rate: '130',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'LEYTE ',
    Location: 'JARO',
    Rate: '130',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'LEYTE ',
    Location: 'JAVIER',
    Rate: '130',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'LEYTE ',
    Location: 'JULITA',
    Rate: '130',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'LEYTE ',
    Location: 'KANANGA',
    Rate: '130',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'LEYTE ',
    Location: 'LA PAZ',
    Rate: '130',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'LEYTE ',
    Location: 'LEYTE',
    Rate: '130',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'LEYTE ',
    Location: 'MAC ARTHUR',
    Rate: '130',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'LEYTE ',
    Location: 'MAHAPLAG',
    Rate: '130',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'LEYTE ',
    Location: 'MATAG-OB',
    Rate: '130',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'LEYTE ',
    Location: 'MATALOM',
    Rate: '130',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'LEYTE ',
    Location: 'MAYORGA',
    Rate: '130',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'LEYTE ',
    Location: 'MERIDA',
    Rate: '130',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'LEYTE ',
    Location: 'ORMOC CITY',
    Rate: '130',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'LEYTE ',
    Location: 'PALO',
    Rate: '130',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'LEYTE ',
    Location: 'PALOMPON',
    Rate: '130',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'LEYTE ',
    Location: 'PASTRANA',
    Rate: '130',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'LEYTE ',
    Location: 'SAN ISIDRO',
    Rate: '130',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'LEYTE ',
    Location: 'SAN MIGUEL',
    Rate: '130',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'LEYTE ',
    Location: 'SANTA FE',
    Rate: '130',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'LEYTE ',
    Location: 'TABANGO',
    Rate: '130',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'LEYTE ',
    Location: 'TABONTABON',
    Rate: '130',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'LEYTE ',
    Location: 'TACLOBAN CITY',
    Rate: '130',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'LEYTE ',
    Location: 'TANAUAN',
    Rate: '130',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'LEYTE ',
    Location: 'TOLOSA',
    Rate: '130',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'LEYTE ',
    Location: 'TUNGA',
    Rate: '130',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'LEYTE ',
    Location: 'VILLABA',
    Rate: '130',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'LEYTE (SOUTHERN)',
    Location: 'ANAHAWAN',
    Rate: '130',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'LEYTE (SOUTHERN)',
    Location: 'BONTOC',
    Rate: '130',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'LEYTE (SOUTHERN)',
    Location: 'HINUNANGAN',
    Rate: '130',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'LEYTE (SOUTHERN)',
    Location: 'HINUNDAYAN',
    Rate: '130',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'LEYTE (SOUTHERN)',
    Location: 'LIBAGON',
    Rate: '130',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'LEYTE (SOUTHERN)',
    Location: 'LILOAN',
    Rate: '130',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'LEYTE (SOUTHERN)',
    Location: 'LIMASAWA ISLAND',
    Rate: '230',
    Remarks: '1 ON 1 DELIVERY',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'LEYTE (SOUTHERN)',
    Location: 'MAASIN',
    Rate: '130',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'LEYTE (SOUTHERN)',
    Location: 'MACROHON',
    Rate: '130',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'LEYTE (SOUTHERN)',
    Location: 'MALITBOG',
    Rate: '130',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'LEYTE (SOUTHERN)',
    Location: 'PADRE BURGOS',
    Rate: '130',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'LEYTE (SOUTHERN)',
    Location: 'PINTUYAN',
    Rate: '230',
    Remarks: '1 ON 1 DELIVERY',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'LEYTE (SOUTHERN)',
    Location: 'SAINT BERNARD',
    Rate: '230',
    Remarks: '1 ON 1 DELIVERY',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'LEYTE (SOUTHERN)',
    Location: 'SAN FRANCISCO',
    Rate: '130',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'LEYTE (SOUTHERN)',
    Location: 'SAN JUAN',
    Rate: '130',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'LEYTE (SOUTHERN)',
    Location: 'SAN RICARDO',
    Rate: '130',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'LEYTE (SOUTHERN)',
    Location: 'SILAGO',
    Rate: '130',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'LEYTE (SOUTHERN)',
    Location: 'SOGOD',
    Rate: '130',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'LEYTE (SOUTHERN)',
    Location: 'TOMAS OPPUS',
    Rate: '130',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'MASBATE',
    Location: 'MASBATE',
    Rate: '180',
    Remarks: 'ISLAND TRANSFER',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'NEGROS OCCIDENTAL',
    Location: 'BACOLOD CITY',
    Rate: '130',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'NEGROS OCCIDENTAL',
    Location: 'BAGO CITY',
    Rate: '130',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'NEGROS OCCIDENTAL',
    Location: 'BINALBAGAN',
    Rate: '130',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'NEGROS OCCIDENTAL',
    Location: 'CADIZ CITY',
    Rate: '130',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'NEGROS OCCIDENTAL',
    Location: 'CALATRAVA',
    Rate: '180',
    Remarks: 'WITH TRANSSHIPMENT CHARGE',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'NEGROS OCCIDENTAL',
    Location: 'CANDONI',
    Rate: '180',
    Remarks: 'WITH TRANSSHIPMENT CHARGE',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'NEGROS OCCIDENTAL',
    Location: 'CAUAYAN',
    Rate: '180',
    Remarks: 'WITH TRANSSHIPMENT CHARGE',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'NEGROS OCCIDENTAL',
    Location: 'ENRIQUE B. MAGALONA',
    Rate: '130',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'NEGROS OCCIDENTAL',
    Location: 'ESCALANTE',
    Rate: '130',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'NEGROS OCCIDENTAL',
    Location: 'HIMAMAYLAN',
    Rate: '130',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'NEGROS OCCIDENTAL',
    Location: 'HINIGARAN',
    Rate: '130',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'NEGROS OCCIDENTAL',
    Location: 'HINOBA-AN',
    Rate: '180',
    Remarks: 'WITH TRANSSHIPMENT CHARGE',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'NEGROS OCCIDENTAL',
    Location: 'ILOG',
    Rate: '180',
    Remarks: 'WITH TRANSSHIPMENT CHARGE',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'NEGROS OCCIDENTAL',
    Location: 'ISABELA',
    Rate: '130',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'NEGROS OCCIDENTAL',
    Location: 'KABANKALAN CITY',
    Rate: '130',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'NEGROS OCCIDENTAL',
    Location: 'LA CARLOTA CITY',
    Rate: '130',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'NEGROS OCCIDENTAL',
    Location: 'LA CASTELLANA',
    Rate: '130',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'NEGROS OCCIDENTAL',
    Location: 'MANAPLA',
    Rate: '130',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'NEGROS OCCIDENTAL',
    Location: 'MOISES PADILLA',
    Rate: '180',
    Remarks: 'WITH TRANSSHIPMENT CHARGE',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'NEGROS OCCIDENTAL',
    Location: 'MURCIA',
    Rate: '130',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'NEGROS OCCIDENTAL',
    Location: 'PONTEVEDRA',
    Rate: '130',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'NEGROS OCCIDENTAL',
    Location: 'PULUPANDAN',
    Rate: '130',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'NEGROS OCCIDENTAL',
    Location: 'SAGAY CITY',
    Rate: '130',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'NEGROS OCCIDENTAL',
    Location: 'SALVADOR BENEDICTO',
    Rate: '180',
    Remarks: 'WITH TRANSSHIPMENT CHARGE',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'NEGROS OCCIDENTAL',
    Location: 'SAN CARLOS CITY',
    Rate: '180',
    Remarks: 'WITH TRANSSHIPMENT CHARGE',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'NEGROS OCCIDENTAL',
    Location: 'SAN ENRIQUE',
    Rate: '130',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'NEGROS OCCIDENTAL',
    Location: 'SILAY CITY',
    Rate: '130',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'NEGROS OCCIDENTAL',
    Location: 'SIPALAY',
    Rate: '180',
    Remarks: 'WITH TRANSSHIPMENT CHARGE',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'NEGROS OCCIDENTAL',
    Location: 'SUM-AG',
    Rate: '130',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'NEGROS OCCIDENTAL',
    Location: 'TALISAY CITY',
    Rate: '130',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'NEGROS OCCIDENTAL',
    Location: 'TOBOSO',
    Rate: '180',
    Remarks: 'WITH TRANSSHIPMENT CHARGE',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'NEGROS OCCIDENTAL',
    Location: 'VALLADOLID',
    Rate: '130',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'NEGROS OCCIDENTAL',
    Location: 'VICTORIAS CITY',
    Rate: '130',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'NEGROS ORIENTAL',
    Location: 'AMLAN',
    Rate: '130',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'NEGROS ORIENTAL',
    Location: 'AYUNGON',
    Rate: '130',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'NEGROS ORIENTAL',
    Location: 'BACONG',
    Rate: '130',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'NEGROS ORIENTAL',
    Location: 'BAIS CITY',
    Rate: '130',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'NEGROS ORIENTAL',
    Location: 'BASAY',
    Rate: '180',
    Remarks: 'WITH TRANSSHIPMENT CHARGE',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'NEGROS ORIENTAL',
    Location: 'BAYAWAN CITY',
    Rate: '180',
    Remarks: 'WITH TRANSSHIPMENT CHARGE',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'NEGROS ORIENTAL',
    Location: 'BINDOY',
    Rate: '130',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'NEGROS ORIENTAL',
    Location: 'CANLAON CITY',
    Rate: '180',
    Remarks: 'WITH TRANSSHIPMENT CHARGE',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'NEGROS ORIENTAL',
    Location: 'DAUIN',
    Rate: '130',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'NEGROS ORIENTAL',
    Location: 'DUMAGUETE CITY',
    Rate: '130',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'NEGROS ORIENTAL',
    Location: 'GUIHULNGAN',
    Rate: '180',
    Remarks: 'WITH TRANSSHIPMENT CHARGE',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'NEGROS ORIENTAL',
    Location: 'JIMALALUD',
    Rate: '130',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'NEGROS ORIENTAL',
    Location: 'LA LIBERTAD',
    Rate: '180',
    Remarks: 'WITH TRANSSHIPMENT CHARGE',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'NEGROS ORIENTAL',
    Location: 'MABINAY',
    Rate: '130',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'NEGROS ORIENTAL',
    Location: 'MANJUYOD',
    Rate: '130',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'NEGROS ORIENTAL',
    Location: 'PAMPLONA',
    Rate: '130',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'NEGROS ORIENTAL',
    Location: 'SAN JOSE',
    Rate: '130',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'NEGROS ORIENTAL',
    Location: 'SANTA CATALINA',
    Rate: '130',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'NEGROS ORIENTAL',
    Location: 'SIATON',
    Rate: '130',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'NEGROS ORIENTAL',
    Location: 'SIBULAN',
    Rate: '130',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'NEGROS ORIENTAL',
    Location: 'TANJAY',
    Rate: '130',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'NEGROS ORIENTAL',
    Location: 'TAYASAN',
    Rate: '130',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'NEGROS ORIENTAL',
    Location: 'VALENCIA',
    Rate: '130',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'NEGROS ORIENTAL',
    Location: 'VALLEHERMOSA',
    Rate: '130',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'NEGROS ORIENTAL',
    Location: 'ZAMBOAGUITA',
    Rate: '130',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'PALAWAN',
    Location: 'ABORLAN',
    Rate: '180',
    Remarks: 'WITH TRANSSHIPMENT CHARGE',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'PALAWAN',
    Location: 'AGUTAYA (VIA CUYO ISLAND)',
    Rate: '180',
    Remarks: 'PICK-UP AT PUERTO PRINCESA STATION',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'PALAWAN',
    Location: 'ARACELI',
    Rate: '180',
    Remarks: 'PICK-UP AT ROXAS POBLACION STATION',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'PALAWAN',
    Location: 'BALABAC',
    Rate: '230',
    Remarks: "PICK-UP AT BROOKE'S POINT STATION",
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'PALAWAN',
    Location: 'BATARAZA',
    Rate: '180',
    Remarks: 'WITH TRANSSHIPMENT CHARGE',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'PALAWAN',
    Location: "BROOKE'S POINT",
    Rate: '180',
    Remarks: 'WITH TRANSSHIPMENT CHARGE',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'PALAWAN',
    Location: 'BUSUANGA',
    Rate: '230',
    Remarks: 'PICK-UP AT CORON STATION',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'PALAWAN',
    Location: 'CAGAYANCILLO',
    Rate: '180',
    Remarks: 'PICK-UP AT PUERTO PRINCESA STATION',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'PALAWAN',
    Location: 'CORON (Calamian Group of Islands)',
    Rate: '230',
    Remarks: 'PUO - PICKUP MAIN OFFICE',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'PALAWAN',
    Location: 'CULION',
    Rate: '230',
    Remarks: 'PICK-UP AT CORON STATION',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'PALAWAN',
    Location: 'CUYO',
    Rate: '180',
    Remarks: 'PICK-UP AT PUERTO PRINCESA STATION',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'PALAWAN',
    Location: 'EL NIDO (BAQUIT)',
    Rate: '230',
    Remarks: '1 ON 1 DELIVERY',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'PALAWAN',
    Location: 'IWAHIG',
    Rate: '180',
    Remarks: 'PICK-UP AT PUERTO PRINCESA STATION',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'PALAWAN',
    Location: 'KALAYAAN',
    Rate: '180',
    Remarks: 'PICK-UP AT PUERTO PRINCESA STATION',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'PALAWAN',
    Location: 'LINAPACAN',
    Rate: '180',
    Remarks: 'PICK-UP AT ROXAS POBLACION STATION',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'PALAWAN',
    Location: 'MAGSAYSAY',
    Rate: '130',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'PALAWAN',
    Location: 'NARRA',
    Rate: '180',
    Remarks: 'WITH TRANSSHIPMENT CHARGE',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'PALAWAN',
    Location: 'PUERTO PRINCESA CITY',
    Rate: '130',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'PALAWAN',
    Location: 'QUEZON',
    Rate: '180',
    Remarks: 'WITH TRANSSHIPMENT CHARGE',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'PALAWAN',
    Location: 'RIZAL',
    Rate: '230',
    Remarks: '1 ON 1 DELIVERY',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'PALAWAN',
    Location: 'ROXAS',
    Rate: '180',
    Remarks: 'WITH TRANSSHIPMENT CHARGE',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'PALAWAN',
    Location: 'SAN VICENTE',
    Rate: '230',
    Remarks: '1 ON 1 DELIVERY',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'PALAWAN',
    Location: 'SOFRONIO ESPANOLA',
    Rate: '230',
    Remarks: "PICK-UP AT BROOKE'S POINT STATION",
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'PALAWAN',
    Location: 'TAYTAY',
    Rate: '230',
    Remarks: '1 ON 1 DELIVERY',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'ROMBLON ISLAND',
    Location: 'ROMBLON ISLAND',
    Rate: '180',
    Remarks: 'ISLAND TRANSFER',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'SAMAR, EASTERN',
    Location: 'ARTECHE',
    Rate: '180',
    Remarks: 'WITH TRANSSHIPMENT CHARGE',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'SAMAR, EASTERN',
    Location: 'BALANGIGA',
    Rate: '130',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'SAMAR, EASTERN',
    Location: 'BALANGKAYAN',
    Rate: '130',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'SAMAR, EASTERN',
    Location: 'BORONGAN',
    Rate: '130',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'SAMAR, EASTERN',
    Location: 'CAN-AVID',
    Rate: '130',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'SAMAR, EASTERN',
    Location: 'DOLORES',
    Rate: '180',
    Remarks: 'WITH TRANSSHIPMENT CHARGE',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'SAMAR, EASTERN',
    Location: 'GENERAL MACARTHUR',
    Rate: '130',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'SAMAR, EASTERN',
    Location: 'GIPORLOS',
    Rate: '130',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'SAMAR, EASTERN',
    Location: 'GUIUAN',
    Rate: '130',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'SAMAR, EASTERN',
    Location: 'HERNANI',
    Rate: '130',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'SAMAR, EASTERN',
    Location: 'JIPAPAD',
    Rate: '180',
    Remarks: 'WITH TRANSSHIPMENT CHARGE',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'SAMAR, EASTERN',
    Location: 'LAWAAN',
    Rate: '130',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'SAMAR, EASTERN',
    Location: 'LLORENTE',
    Rate: '130',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'SAMAR, EASTERN',
    Location: 'MASLOG',
    Rate: '130',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'SAMAR, EASTERN',
    Location: 'MAYDOLONG',
    Rate: '130',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'SAMAR, EASTERN',
    Location: 'MERCEDEZ',
    Rate: '130',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'SAMAR, EASTERN',
    Location: 'ORAS',
    Rate: '180',
    Remarks: 'WITH TRANSSHIPMENT CHARGE',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'SAMAR, EASTERN',
    Location: 'QUINAPUNDAN',
    Rate: '130',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'SAMAR, EASTERN',
    Location: 'SALCEDO',
    Rate: '130',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'SAMAR, EASTERN',
    Location: 'SAN JULIAN',
    Rate: '180',
    Remarks: 'WITH TRANSSHIPMENT CHARGE',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'SAMAR, EASTERN',
    Location: 'SAN POLICARPO',
    Rate: '130',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'SAMAR, EASTERN',
    Location: 'SULAT',
    Rate: '130',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'SAMAR, EASTERN',
    Location: 'TAFT',
    Rate: '130',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'SAMAR, NORTHERN',
    Location: 'ALLEN',
    Rate: '130',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'SAMAR, NORTHERN',
    Location: 'BIRI',
    Rate: '180',
    Remarks: 'PICK-UP AT NEAREST PORT',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'SAMAR, NORTHERN',
    Location: 'BOBON',
    Rate: '130',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'SAMAR, NORTHERN',
    Location: 'BURAY',
    Rate: '130',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'SAMAR, NORTHERN',
    Location: 'CAPUL',
    Rate: '180',
    Remarks: 'PICK-UP AT NEAREST PORT',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'SAMAR, NORTHERN',
    Location: 'CATARMAN',
    Rate: '130',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'SAMAR, NORTHERN',
    Location: 'CATUBIG',
    Rate: '180',
    Remarks: 'WITH TRANSSHIPMENT CHARGE',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'SAMAR, NORTHERN',
    Location: 'GAMAY',
    Rate: '180',
    Remarks: 'WITH TRANSSHIPMENT CHARGE',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'SAMAR, NORTHERN',
    Location: 'LAOANG',
    Rate: '180',
    Remarks: 'WITH TRANSSHIPMENT CHARGE',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'SAMAR, NORTHERN',
    Location: 'LAPINIG',
    Rate: '180',
    Remarks: 'WITH TRANSSHIPMENT CHARGE',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'SAMAR, NORTHERN',
    Location: 'LAS NAVAS',
    Rate: '180',
    Remarks: 'WITH TRANSSHIPMENT CHARGE',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'SAMAR, NORTHERN',
    Location: 'LAVEZARES',
    Rate: '180',
    Remarks: 'WITH TRANSSHIPMENT CHARGE',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'SAMAR, NORTHERN',
    Location: 'LOPE DE VEGA',
    Rate: '180',
    Remarks: 'WITH TRANSSHIPMENT CHARGE',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'SAMAR, NORTHERN',
    Location: 'MAPANAS',
    Rate: '180',
    Remarks: 'WITH TRANSSHIPMENT CHARGE',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'SAMAR, NORTHERN',
    Location: 'MONDRAGON',
    Rate: '180',
    Remarks: 'WITH TRANSSHIPMENT CHARGE',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'SAMAR, NORTHERN',
    Location: 'PALAPAG',
    Rate: '180',
    Remarks: 'WITH TRANSSHIPMENT CHARGE',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'SAMAR, NORTHERN',
    Location: 'PAMBUJAN',
    Rate: '180',
    Remarks: 'WITH TRANSSHIPMENT CHARGE',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'SAMAR, NORTHERN',
    Location: 'ROSARIO',
    Rate: '180',
    Remarks: 'WITH TRANSSHIPMENT CHARGE',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'SAMAR, NORTHERN',
    Location: 'SAN ANTONIO',
    Rate: '180',
    Remarks: 'PICK-UP AT NEAREST PORT',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'SAMAR, NORTHERN',
    Location: 'SAN ISIDRO',
    Rate: '180',
    Remarks: 'WITH TRANSSHIPMENT CHARGE',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'SAMAR, NORTHERN',
    Location: 'SAN JOSE',
    Rate: '180',
    Remarks: 'WITH TRANSSHIPMENT CHARGE',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'SAMAR, NORTHERN',
    Location: 'SAN ROQUE',
    Rate: '180',
    Remarks: 'WITH TRANSSHIPMENT CHARGE',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'SAMAR, NORTHERN',
    Location: 'SAN VICENTE',
    Rate: '180',
    Remarks: 'PICK-UP AT NEAREST PORT',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'SAMAR, NORTHERN',
    Location: 'SILVINO LOBOS',
    Rate: '180',
    Remarks: 'WITH TRANSSHIPMENT CHARGE',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'SAMAR, NORTHERN',
    Location: 'VICTORIA ',
    Rate: '180',
    Remarks: 'WITH TRANSSHIPMENT CHARGE',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'SAMAR, WESTERN',
    Location: 'ALMAGRO',
    Rate: '180',
    Remarks: 'PICK-UP AT NEAREST PORT',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'SAMAR, WESTERN',
    Location: 'BASEY',
    Rate: '130',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'SAMAR, WESTERN',
    Location: 'CALBAYOG CITY',
    Rate: '130',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'SAMAR, WESTERN',
    Location: 'CALBIGA',
    Rate: '130',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'SAMAR, WESTERN',
    Location: 'CATBALOGAN',
    Rate: '130',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'SAMAR, WESTERN',
    Location: 'CONCORD',
    Rate: '180',
    Remarks: 'WITH TRANSSHIPMENT CHARGE',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'SAMAR, WESTERN',
    Location: 'DARAM',
    Rate: '180',
    Remarks: 'PICK-UP AT NEAREST PORT',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'SAMAR, WESTERN',
    Location: 'GANDARA',
    Rate: '130',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'SAMAR, WESTERN',
    Location: 'HINABANGAN',
    Rate: '130',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'SAMAR, WESTERN',
    Location: 'JIABONG',
    Rate: '130',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'SAMAR, WESTERN',
    Location: 'MARABUT ISLAND',
    Rate: '180',
    Remarks: 'PICK-UP AT NEAREST PORT',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'SAMAR, WESTERN',
    Location: 'MATUGUINAO',
    Rate: '130',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'SAMAR, WESTERN',
    Location: 'MOTIONG',
    Rate: '130',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'SAMAR, WESTERN',
    Location: 'OQUENDO',
    Rate: '180',
    Remarks: 'WITH TRANSSHIPMENT CHARGE',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'SAMAR, WESTERN',
    Location: 'PAGSANHAN',
    Rate: '180',
    Remarks: 'WITH TRANSSHIPMENT CHARGE',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'SAMAR, WESTERN',
    Location: 'PINABACDAO',
    Rate: '130',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'SAMAR, WESTERN',
    Location: 'SAN JORGE',
    Rate: '130',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'SAMAR, WESTERN',
    Location: 'SAN JOSE DE BUAN',
    Rate: '180',
    Remarks: 'WITH TRANSSHIPMENT CHARGE',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'SAMAR, WESTERN',
    Location: 'SAN SEBASTIAN',
    Rate: '180',
    Remarks: 'WITH TRANSSHIPMENT CHARGE',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'SAMAR, WESTERN',
    Location: 'SANTA MARGARITA',
    Rate: '130',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'SAMAR, WESTERN',
    Location: 'SANTA RITA',
    Rate: '130',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'SAMAR, WESTERN',
    Location: 'SANTO NINO',
    Rate: '180',
    Remarks: 'WITH TRANSSHIPMENT CHARGE',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'SAMAR, WESTERN',
    Location: 'TAGAPUL-AN',
    Rate: '180',
    Remarks: 'PICK-UP AT NEAREST PORT',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'SAMAR, WESTERN',
    Location: 'TALALORA',
    Rate: '130',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'SAMAR, WESTERN',
    Location: 'TARANGNAN',
    Rate: '130',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'SAMAR, WESTERN',
    Location: 'TINAMBACAN',
    Rate: '130',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'SAMAR, WESTERN',
    Location: 'VILLAREAL',
    Rate: '180',
    Remarks: 'WITH TRANSSHIPMENT CHARGE',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'SAMAR, WESTERN',
    Location: 'WRIGHT',
    Rate: '130',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'SAMAR, WESTERN',
    Location: 'ZUMARRAGA',
    Rate: '180',
    Remarks: 'PICK-UP AT NEAREST PORT',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'SIQUIJOR ISLAND',
    Location: 'ENRIQUE VILLANUEVA',
    Rate: '180',
    Remarks: 'ISLAND TRANSFER',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'SIQUIJOR ISLAND',
    Location: 'LARENA',
    Rate: '180',
    Remarks: 'ISLAND TRANSFER',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'SIQUIJOR ISLAND',
    Location: 'LAZI',
    Rate: '180',
    Remarks: 'ISLAND TRANSFER',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'SIQUIJOR ISLAND',
    Location: 'MARIA',
    Rate: '180',
    Remarks: 'ISLAND TRANSFER',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'SIQUIJOR ISLAND',
    Location: 'SAN JUAN',
    Rate: '180',
    Remarks: 'ISLAND TRANSFER',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'SIQUIJOR ISLAND',
    Location: 'SIQUIJOR',
    Rate: '180',
    Remarks: 'ISLAND TRANSFER',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: '',
    Location: 'CAGAYAN VALLEY',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'ABRA',
    Location: 'BANGUED',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'ABRA',
    Location: 'BOLINEY',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'ABRA',
    Location: 'BUCAY',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'ABRA',
    Location: 'BUCLOC',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'ABRA',
    Location: 'DAGUIOMAN',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'ABRA',
    Location: 'DANGLAS',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'ABRA',
    Location: 'DOLORES',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'ABRA',
    Location: 'LA PAZ',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'ABRA',
    Location: 'LACUB',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'ABRA',
    Location: 'LAGANGILAN',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'ABRA',
    Location: 'LAGAYAN',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'ABRA',
    Location: 'LANGIDEN',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'ABRA',
    Location: 'LICUAN',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'ABRA',
    Location: 'LUBA',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'ABRA',
    Location: 'MALIBCONG',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'ABRA',
    Location: 'MANABO',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'ABRA',
    Location: 'PENARRUBIA',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'ABRA',
    Location: 'PIDIGAN',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'ABRA',
    Location: 'PILAR',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'ABRA',
    Location: 'SAL-LAPADAN',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'ABRA',
    Location: 'SAN ISIDRO',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'ABRA',
    Location: 'SAN JUAN',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'ABRA',
    Location: 'SAN QUINTIN',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'ABRA',
    Location: 'TAYUM',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'ABRA',
    Location: 'TINEG',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'ABRA',
    Location: 'TUBO',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'ABRA',
    Location: 'VILLAVICIOSA',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'ALBAY',
    Location: 'BACACAY',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'ALBAY',
    Location: 'CAMALIG',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'ALBAY',
    Location: 'DARAGA',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'ALBAY',
    Location: 'GUINOBATAN',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'ALBAY',
    Location: 'JOVELLAR',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'ALBAY',
    Location: 'LEGASPI CITY',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'ALBAY',
    Location: 'LIBON',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'ALBAY',
    Location: 'LIGAO',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'ALBAY',
    Location: 'MALILIPOT',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'ALBAY',
    Location: 'MALINAO',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'ALBAY',
    Location: 'MANITO',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'ALBAY',
    Location: 'OAS',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'ALBAY',
    Location: 'PIO DURAN',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'ALBAY',
    Location: 'POLANGUI',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'ALBAY',
    Location: 'RAPU RAPU',
    Rate: '165',
    Remarks: 'ISLAND TRANSFER',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'ALBAY',
    Location: 'SANTO DOMINGO',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'ALBAY',
    Location: 'TABACO',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'ALBAY',
    Location: 'TIWI',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'APAYAO',
    Location: 'CALANASAN',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'APAYAO',
    Location: 'CONNER',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'APAYAO',
    Location: 'FLORA',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'APAYAO',
    Location: 'KABUGAO',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'APAYAO',
    Location: 'LUNA',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'APAYAO',
    Location: 'PUDTOL',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'APAYAO',
    Location: 'SANTA MARCELA',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'AURORA',
    Location: 'BALER',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'AURORA',
    Location: 'CASIGURAN',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'AURORA',
    Location: 'DILASAG',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'AURORA',
    Location: 'DINALUNGAN',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'AURORA',
    Location: 'DINGALAN',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'AURORA',
    Location: 'DIPACULAO',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'AURORA',
    Location: 'MARIA AURORA',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'AURORA',
    Location: 'SAN LUIS',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'BATAAN',
    Location: 'ABUCAY',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'BATAAN',
    Location: 'BAGAC',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'BATAAN',
    Location: 'BALANGA',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'BATAAN',
    Location: 'BATAAN EPZA',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'BATAAN',
    Location: 'DINALUPIHAN',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'BATAAN',
    Location: 'HERMOSA',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'BATAAN',
    Location: 'LIMAY',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'BATAAN',
    Location: 'MARIVELES',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'BATAAN',
    Location: 'MORONG',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'BATAAN',
    Location: 'ORANI',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'BATAAN',
    Location: 'ORION',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'BATAAN',
    Location: 'PILAR',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'BATAAN',
    Location: 'SAMAL',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'BATANGAS',
    Location: 'AGONCILLO',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'BATANGAS',
    Location: 'ALITAGTAG',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'BATANGAS',
    Location: 'BALAYAN',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'BATANGAS',
    Location: 'BALETE',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'BATANGAS',
    Location: 'BATANGAS CITY',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'BATANGAS',
    Location: 'BAUAN',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'BATANGAS',
    Location: 'CALACA',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'BATANGAS',
    Location: 'CALATAGAN',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'BATANGAS',
    Location: 'CUENCA',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'BATANGAS',
    Location: 'FERNANDO AIR BASE',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'BATANGAS',
    Location: 'IBAAN',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'BATANGAS',
    Location: 'LAUREL',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'BATANGAS',
    Location: 'LEMERY',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'BATANGAS',
    Location: 'LIAN',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'BATANGAS',
    Location: 'LIPA CITY',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'BATANGAS',
    Location: 'LOBO',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'BATANGAS',
    Location: 'MABINI',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'BATANGAS',
    Location: 'MALVAR',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'BATANGAS',
    Location: 'MATAAS NA KAHOY',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'BATANGAS',
    Location: 'NASUGBU',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'BATANGAS',
    Location: 'PADRE GARCIA',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'BATANGAS',
    Location: 'ROSARIO',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'BATANGAS',
    Location: 'SAN JOSE',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'BATANGAS',
    Location: 'SAN JUAN',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'BATANGAS',
    Location: 'SAN LUIS',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'BATANGAS',
    Location: 'SAN NICOLAS',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'BATANGAS',
    Location: 'SAN PASCUAL',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'BATANGAS',
    Location: 'STA. TERESITA',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'BATANGAS',
    Location: 'STO. TOMAS',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'BATANGAS',
    Location: 'TAAL',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'BATANGAS',
    Location: 'TALISAY',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'BATANGAS',
    Location: 'TANAUAN',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'BATANGAS',
    Location: 'TAYSAN',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'BATANGAS',
    Location: 'TINGLOY',
    Rate: '165',
    Remarks: 'ISLAND TRANSFER',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'BATANGAS',
    Location: 'TUY',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'BENGUET',
    Location: 'ATOK',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'BENGUET',
    Location: 'BAGUIO CITY',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'BENGUET',
    Location: 'BAKUN',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'BENGUET',
    Location: 'BOKOD',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'BENGUET',
    Location: 'BUGUIAS',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'BENGUET',
    Location: 'ITOGON',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'BENGUET',
    Location: 'KABAYAN',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'BENGUET',
    Location: 'KAPANGAN',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'BENGUET',
    Location: 'KIBUNGAN',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'BENGUET',
    Location: 'LA TRINIDAD',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'BENGUET',
    Location: 'MANKAYAN',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'BENGUET',
    Location: 'SABLAN',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'BENGUET',
    Location: 'TUBA',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'BENGUET',
    Location: 'TUBLAY',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'BULACAN',
    Location: 'ANGAT',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'BULACAN',
    Location: 'BALAGTAS',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'BULACAN',
    Location: 'BALUIAG',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'BULACAN',
    Location: 'BOCAUE',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'BULACAN',
    Location: 'BULACAN',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'BULACAN',
    Location: 'BUSTOS',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'BULACAN',
    Location: 'CALUMPIT',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'BULACAN',
    Location: 'DOÑA REMEDIOS TRINIDAD',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'BULACAN',
    Location: 'GUIGUINTO',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'BULACAN',
    Location: 'HAGONOY',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'BULACAN',
    Location: 'MALOLOS',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'BULACAN',
    Location: 'MARILAO',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'BULACAN',
    Location: 'MEYCUAYAN',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'BULACAN',
    Location: 'NORSAGARAY',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'BULACAN',
    Location: 'OBANDO',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'BULACAN',
    Location: 'PANDI',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'BULACAN',
    Location: 'PAOMBONG',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'BULACAN',
    Location: 'PLARIDEL',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'BULACAN',
    Location: 'PULILAN',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'BULACAN',
    Location: 'SAN ILDEFONSO',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'BULACAN',
    Location: 'SAN JOSE DEL MONTE',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'BULACAN',
    Location: 'SAN MIGUEL',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'BULACAN',
    Location: 'SAN RAFAEL',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'BULACAN',
    Location: 'SAPANGPALAY',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'BULACAN',
    Location: 'STA. MARIA',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'CAGAYAN VALLEY',
    Location: 'ABULUG',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'CAGAYAN VALLEY',
    Location: 'ALCALA',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'CAGAYAN VALLEY',
    Location: 'ALLACAPAN',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'CAGAYAN VALLEY',
    Location: 'AMULUNG',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'CAGAYAN VALLEY',
    Location: 'APARRI',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'CAGAYAN VALLEY',
    Location: 'BAGGAO',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'CAGAYAN VALLEY',
    Location: 'BALLESTERROS',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'CAGAYAN VALLEY',
    Location: 'BUGUEY',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'CAGAYAN VALLEY',
    Location: 'CALAYAN',
    Rate: '165',
    Remarks: 'ISLAND TRANSFER',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'CAGAYAN VALLEY',
    Location: 'CAMALANIUGAN',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'CAGAYAN VALLEY',
    Location: 'CLAVERIA',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'CAGAYAN VALLEY',
    Location: 'ENRILE',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'CAGAYAN VALLEY',
    Location: 'GATTARAN',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'CAGAYAN VALLEY',
    Location: 'GONZAGA',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'CAGAYAN VALLEY',
    Location: 'IGUIG',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'CAGAYAN VALLEY',
    Location: 'LAL-LO',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'CAGAYAN VALLEY',
    Location: 'LASSAM',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'CAGAYAN VALLEY',
    Location: 'PAMPLONA',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'CAGAYAN VALLEY',
    Location: 'PENABLANCA',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'CAGAYAN VALLEY',
    Location: 'PIAT',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'CAGAYAN VALLEY',
    Location: 'RIZAL',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'CAGAYAN VALLEY',
    Location: 'SANCHEZ-MIRA',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'CAGAYAN VALLEY',
    Location: 'SANTA ANA',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'CAGAYAN VALLEY',
    Location: 'SANTA PRAXEDES',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'CAGAYAN VALLEY',
    Location: 'SANTA TERESITA',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'CAGAYAN VALLEY',
    Location: 'SANTO NINO',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'CAGAYAN VALLEY',
    Location: 'SOLANA',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'CAGAYAN VALLEY',
    Location: 'TUAO',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'CAGAYAN VALLEY',
    Location: 'TUGUEGARAO',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'CAMARINES NORTE',
    Location: 'BASUD',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'CAMARINES NORTE',
    Location: 'CAPALONGA',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'CAMARINES NORTE',
    Location: 'DAET',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'CAMARINES NORTE',
    Location: 'IMELDA',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'CAMARINES NORTE',
    Location: 'JOSE PANGANIBAN',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'CAMARINES NORTE',
    Location: 'LABO',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'CAMARINES NORTE',
    Location: 'MERCEDES',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'CAMARINES NORTE',
    Location: 'PARACALE',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'CAMARINES NORTE',
    Location: 'SAN VICENTE',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'CAMARINES NORTE',
    Location: 'STA. ELENA',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'CAMARINES NORTE',
    Location: 'TALISAY',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'CAMARINES NORTE',
    Location: 'TULA-NA-LUPA',
    Rate: '0',
    Remarks: 'UNSERVICEABLE',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'CAMARINES NORTE',
    Location: 'VINZONS',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'CAMARINES SUR',
    Location: 'BAAO',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'CAMARINES SUR',
    Location: 'BALATAN',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'CAMARINES SUR',
    Location: 'BATO',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'CAMARINES SUR',
    Location: 'BOMBON',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'CAMARINES SUR',
    Location: 'BUHI',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'CAMARINES SUR',
    Location: 'BULA',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'CAMARINES SUR',
    Location: 'CABUSAO',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'CAMARINES SUR',
    Location: 'CALABANGA',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'CAMARINES SUR',
    Location: 'CAMALIGAN',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'CAMARINES SUR',
    Location: 'CANAMAN',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'CAMARINES SUR',
    Location: 'CARAMOAN',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'CAMARINES SUR',
    Location: 'DEL GALLEGO',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'CAMARINES SUR',
    Location: 'GAINZA',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'CAMARINES SUR',
    Location: 'GARCHITORENA',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'CAMARINES SUR',
    Location: 'GOA',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'CAMARINES SUR',
    Location: 'IRIGA CITY',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'CAMARINES SUR',
    Location: 'LAGONOY',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'CAMARINES SUR',
    Location: 'LIBMANAN',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'CAMARINES SUR',
    Location: 'LUPI',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'CAMARINES SUR',
    Location: 'MAGARAO',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'CAMARINES SUR',
    Location: 'MILAOR',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'CAMARINES SUR',
    Location: 'MINALABAC',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'CAMARINES SUR',
    Location: 'NABUA',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'CAMARINES SUR',
    Location: 'NAGA CITY',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'CAMARINES SUR',
    Location: 'OCAMPO',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'CAMARINES SUR',
    Location: 'PAMPLONA',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'CAMARINES SUR',
    Location: 'PASACAO',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'CAMARINES SUR',
    Location: 'PILI',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'CAMARINES SUR',
    Location: 'PRESENTACION',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'CAMARINES SUR',
    Location: 'RAGAY',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'CAMARINES SUR',
    Location: 'SAGNAY',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'CAMARINES SUR',
    Location: 'SAN FERNANDO',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'CAMARINES SUR',
    Location: 'SAN JOSE',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'CAMARINES SUR',
    Location: 'SIPOCOT',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'CAMARINES SUR',
    Location: 'SIRUMA',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'CAMARINES SUR',
    Location: 'TIGAON',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'CAMARINES SUR',
    Location: 'TINAMBAC',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'CATANDUANES',
    Location: 'BAGAMANOC',
    Rate: '165',
    Remarks: 'ISLAND TRANSFER',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'CATANDUANES',
    Location: 'BARAS',
    Rate: '165',
    Remarks: 'ISLAND TRANSFER',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'CATANDUANES',
    Location: 'BATO',
    Rate: '165',
    Remarks: 'ISLAND TRANSFER',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'CATANDUANES',
    Location: 'CARAMORAN',
    Rate: '165',
    Remarks: 'ISLAND TRANSFER',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'CATANDUANES',
    Location: 'GIGMOTO',
    Rate: '165',
    Remarks: 'ISLAND TRANSFER',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'CATANDUANES',
    Location: 'PANDAN',
    Rate: '165',
    Remarks: 'ISLAND TRANSFER',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'CATANDUANES',
    Location: 'PANGANIBAN',
    Rate: '165',
    Remarks: 'ISLAND TRANSFER',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'CATANDUANES',
    Location: 'SAN ANDRES',
    Rate: '165',
    Remarks: 'ISLAND TRANSFER',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'CATANDUANES',
    Location: 'SAN MIGUEL',
    Rate: '165',
    Remarks: 'ISLAND TRANSFER',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'CATANDUANES',
    Location: 'VIGA',
    Rate: '165',
    Remarks: 'ISLAND TRANSFER',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'CATANDUANES',
    Location: 'VIRAC',
    Rate: '165',
    Remarks: 'ISLAND TRANSFER',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'CAVITE',
    Location: 'ALFONSO',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'CAVITE',
    Location: 'AMADEO',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'CAVITE',
    Location: 'BACOOR',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'CAVITE',
    Location: 'CARMONA',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'CAVITE',
    Location: 'CAVITE CITY',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'CAVITE',
    Location: 'CAVITE EPZA',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'CAVITE',
    Location: 'DASMARIÑAS',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'CAVITE',
    Location: 'GEN. AGUINALDO',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'CAVITE',
    Location: 'GEN. MARIANO ALVAREZ',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'CAVITE',
    Location: 'GEN. TRIAS',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'CAVITE',
    Location: 'IMUS',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'CAVITE',
    Location: 'INDANG',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'CAVITE',
    Location: 'KAWIT',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'CAVITE',
    Location: 'MAGALLANES',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'CAVITE',
    Location: 'MARAGONDON',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'CAVITE',
    Location: 'MENDEZ',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'CAVITE',
    Location: 'NAIC',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'CAVITE',
    Location: 'NOVELETA',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'CAVITE',
    Location: 'ROSARIO',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'CAVITE',
    Location: 'SILANG',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'CAVITE',
    Location: 'TAGAYTAY CITY',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'CAVITE',
    Location: 'TANZA',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'CAVITE',
    Location: 'TERNATE',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'CAVITE',
    Location: 'TRECE MARTIREZ CITY',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'IFUGAO',
    Location: 'AGUINALDO',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'IFUGAO',
    Location: 'ASIPULO',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'IFUGAO',
    Location: 'BANAUE',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'IFUGAO',
    Location: 'HINGYON',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'IFUGAO',
    Location: 'HUNGDUAN',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'IFUGAO',
    Location: 'KIANGAN',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'IFUGAO',
    Location: 'LAGAWE',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'IFUGAO',
    Location: 'LAMUT',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'IFUGAO',
    Location: 'MAYAOYAO',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'IFUGAO',
    Location: 'POTIA',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'IFUGAO',
    Location: 'TINOC',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'ILOCOS NORTE',
    Location: 'ADAMS',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'ILOCOS NORTE',
    Location: 'BACARRA',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'ILOCOS NORTE',
    Location: 'BADOC',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'ILOCOS NORTE',
    Location: 'BANGUI',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'ILOCOS NORTE',
    Location: 'BATAC',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'ILOCOS NORTE',
    Location: 'BURGOS',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'ILOCOS NORTE',
    Location: 'CARAZI',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'ILOCOS NORTE',
    Location: 'CURRIMAO',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'ILOCOS NORTE',
    Location: 'DINGRAS',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'ILOCOS NORTE',
    Location: 'DUMALNEG',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'ILOCOS NORTE',
    Location: 'ESPIRITU',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'ILOCOS NORTE',
    Location: 'LAOAG',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'ILOCOS NORTE',
    Location: 'MARCOS',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'ILOCOS NORTE',
    Location: 'NUEVA ERA',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'ILOCOS NORTE',
    Location: 'PAGUDPOD',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'ILOCOS NORTE',
    Location: 'PAOAY',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'ILOCOS NORTE',
    Location: 'PASUQUIN',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'ILOCOS NORTE',
    Location: 'PIDDIG',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'ILOCOS NORTE',
    Location: 'PINILI',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'ILOCOS NORTE',
    Location: 'SAN NICOLAS',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'ILOCOS NORTE',
    Location: 'SARRAT',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'ILOCOS NORTE',
    Location: 'SOLSONA',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'ILOCOS NORTE',
    Location: 'VINTAR',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'ILOCOS SUR',
    Location: 'ALILEM',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'ILOCOS SUR',
    Location: 'BANAYOYO',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'ILOCOS SUR',
    Location: 'BANTAY',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'ILOCOS SUR',
    Location: 'BURGOS',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'ILOCOS SUR',
    Location: 'CABUGAO',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'ILOCOS SUR',
    Location: 'CANDON',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'ILOCOS SUR',
    Location: 'CAOAYAN',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'ILOCOS SUR',
    Location: 'CERVANTES',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'ILOCOS SUR',
    Location: 'GALIMUYOD',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'ILOCOS SUR',
    Location: 'GREGORIO DEL PILAR',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'ILOCOS SUR',
    Location: 'LIDLIDDA',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'ILOCOS SUR',
    Location: 'MAGSINGAL',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'ILOCOS SUR',
    Location: 'NAGBUKEL',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'ILOCOS SUR',
    Location: 'NARVACAN',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'ILOCOS SUR',
    Location: 'QUIRINO',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'ILOCOS SUR',
    Location: 'SALCEDO',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'ILOCOS SUR',
    Location: 'SAN EMILIO',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'ILOCOS SUR',
    Location: 'SAN ESTEBAN',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'ILOCOS SUR',
    Location: 'SAN ILDEFONSO',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'ILOCOS SUR',
    Location: 'SAN JUAN',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'ILOCOS SUR',
    Location: 'SAN VICENTE',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'ILOCOS SUR',
    Location: 'SANTA',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'ILOCOS SUR',
    Location: 'SANTA CATALINA',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'ILOCOS SUR',
    Location: 'SANTA CRUZ',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'ILOCOS SUR',
    Location: 'SANTA LUCIA',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'ILOCOS SUR',
    Location: 'SANTA MARIA',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'ILOCOS SUR',
    Location: 'SANTIAGO',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'ILOCOS SUR',
    Location: 'SANTO DOMINGO',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'ILOCOS SUR',
    Location: 'SIGAY',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'ILOCOS SUR',
    Location: 'SINAIT',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'ILOCOS SUR',
    Location: 'SUGPON',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'ILOCOS SUR',
    Location: 'SUYO',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'ILOCOS SUR',
    Location: 'TAGUDIN',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'ILOCOS SUR',
    Location: 'VIGAN',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'ISABELA',
    Location: 'ALICIA',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'ISABELA',
    Location: 'ANGADANAN',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'ISABELA',
    Location: 'AURORA',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'ISABELA',
    Location: 'BENITO SOLIVEN',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'ISABELA',
    Location: 'BURGOS',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'ISABELA',
    Location: 'CABAGAN',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'ISABELA',
    Location: 'CABATUAN',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'ISABELA',
    Location: 'CAUAYAN',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'ISABELA',
    Location: 'CORDON',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'ISABELA',
    Location: 'DELFIN ALBANO',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'ISABELA',
    Location: 'DINAPIGUI',
    Rate: '0',
    Remarks: 'UNSERVICEABLE',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'ISABELA',
    Location: 'DIVILACAN',
    Rate: '0',
    Remarks: 'UNSERVICEABLE',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'ISABELA',
    Location: 'ECHAGUE',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'ISABELA',
    Location: 'GAMU',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'ISABELA',
    Location: 'ILAGAN',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'ISABELA',
    Location: 'JONES',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'ISABELA',
    Location: 'LUNA',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'ISABELA',
    Location: 'MACONACON',
    Rate: '0',
    Remarks: 'UNSERVICEABLE',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'ISABELA',
    Location: 'MALLIG',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'ISABELA',
    Location: 'NAGUILLAN',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'ISABELA',
    Location: 'PALANAN',
    Rate: '0',
    Remarks: 'UNSERVICEABLE',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'ISABELA',
    Location: 'QUEZON',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'ISABELA',
    Location: 'QUIRINO',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'ISABELA',
    Location: 'RAMON',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'ISABELA',
    Location: 'REINA MERCEDES',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'ISABELA',
    Location: 'ROXAS',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'ISABELA',
    Location: 'SAN AGUSTIN',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'ISABELA',
    Location: 'SAN GUILLERMO',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'ISABELA',
    Location: 'SAN ISIDRO',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'ISABELA',
    Location: 'SAN MANUEL',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'ISABELA',
    Location: 'SAN MARIANO',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'ISABELA',
    Location: 'SAN MATEO',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'ISABELA',
    Location: 'SAN PABLO',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'ISABELA',
    Location: 'SANTA MARIA',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'ISABELA',
    Location: 'SANTIAGO',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'ISABELA',
    Location: 'SANTO TOMAS',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'ISABELA',
    Location: 'TUMAUINI',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'KALINGA',
    Location: 'BALBALAN',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'KALINGA',
    Location: 'LUBUAGAN',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'KALINGA',
    Location: 'PASIL',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'KALINGA',
    Location: 'PINUKPUK',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'KALINGA',
    Location: 'RIZAL',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'KALINGA',
    Location: 'TABUK',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'KALINGA',
    Location: 'TANUDAN',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'KALINGA',
    Location: 'TINGLAYAN',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'LA UNION ',
    Location: 'AGOO',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'LA UNION ',
    Location: 'ARINGAY',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'LA UNION ',
    Location: 'BACNOTAN',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'LA UNION ',
    Location: 'BAGULIN',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'LA UNION ',
    Location: 'BALAOAN',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'LA UNION ',
    Location: 'BANGAR',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'LA UNION ',
    Location: 'BAUANG',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'LA UNION ',
    Location: 'BURGOS',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'LA UNION ',
    Location: 'CABA',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'LA UNION ',
    Location: 'LUNA',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'LA UNION ',
    Location: 'NAGUILLAN',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'LA UNION ',
    Location: 'PUGO',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'LA UNION ',
    Location: 'ROSARIO',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'LA UNION ',
    Location: 'SAN FERNANDO',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'LA UNION ',
    Location: 'SAN GABRIEL',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'LA UNION ',
    Location: 'SAN JUAN',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'LA UNION ',
    Location: 'SANTOL',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'LA UNION ',
    Location: 'STO. TOMAS',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'LA UNION ',
    Location: 'SUDEPEN',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'LA UNION ',
    Location: 'TUBAO',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'LAGUNA',
    Location: 'ALAMINOS',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'LAGUNA',
    Location: 'BAY',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'LAGUNA',
    Location: 'BIÑAN',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'LAGUNA',
    Location: 'BOTOCAN',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'LAGUNA',
    Location: 'CABUYAO',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'LAGUNA',
    Location: 'CALAMBA',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'LAGUNA',
    Location: 'CALAUAN',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'LAGUNA',
    Location: 'CANLUBANG',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'LAGUNA',
    Location: 'CAVINTI',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'LAGUNA',
    Location: 'FAMY',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'LAGUNA',
    Location: 'KALAYAAN',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'LAGUNA',
    Location: 'LILIW',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'LAGUNA',
    Location: 'LOS BAÑOS',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'LAGUNA',
    Location: 'LUISIANA',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'LAGUNA',
    Location: 'LUMBAN',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'LAGUNA',
    Location: 'MABITAC',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'LAGUNA',
    Location: 'MAGDALENA',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'LAGUNA',
    Location: 'MAJAYJAY',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'LAGUNA',
    Location: 'NAGCARLAN',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'LAGUNA',
    Location: 'PAETE',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'LAGUNA',
    Location: 'PAGSANJAN',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'LAGUNA',
    Location: 'PAKIL',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'LAGUNA',
    Location: 'PANGIL',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'LAGUNA',
    Location: 'PILA',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'LAGUNA',
    Location: 'RIZAL',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'LAGUNA',
    Location: 'SAN PABLO CITY',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'LAGUNA',
    Location: 'SAN PEDRO',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'LAGUNA',
    Location: 'SINILOAN',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'LAGUNA',
    Location: 'STA. CRUZ',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'LAGUNA',
    Location: 'STA. MARIA',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'LAGUNA',
    Location: 'STA. ROSA',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'LAGUNA',
    Location: 'VICTORIA',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'MT. PROVINCE',
    Location: 'BARLIG',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'MT. PROVINCE',
    Location: 'BAUKO',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'MT. PROVINCE',
    Location: 'BESAO',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'MT. PROVINCE',
    Location: 'BONTOC',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'MT. PROVINCE',
    Location: 'NATONIN',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'MT. PROVINCE',
    Location: 'PARACELIS',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'MT. PROVINCE',
    Location: 'SABANGAN',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'MT. PROVINCE',
    Location: 'SADANGA',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'MT. PROVINCE',
    Location: 'SAGADA',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'MT. PROVINCE',
    Location: 'TADIAN',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'NUEVA ECIJA',
    Location: 'ALIAGA',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'NUEVA ECIJA',
    Location: 'BONGABON',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'NUEVA ECIJA',
    Location: 'CABANATUAN',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'NUEVA ECIJA',
    Location: 'CABIAO',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'NUEVA ECIJA',
    Location: 'CARRANGLAN',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'NUEVA ECIJA',
    Location: 'CUYAPO',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'NUEVA ECIJA',
    Location: 'FORT MAGSAYSAY',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'NUEVA ECIJA',
    Location: 'GABALDON',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'NUEVA ECIJA',
    Location: 'GAPAN',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'NUEVA ECIJA',
    Location: 'GENERAL M. NATIVIDAD',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'NUEVA ECIJA',
    Location: 'GENERAL TINIO',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'NUEVA ECIJA',
    Location: 'GUIMBA',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'NUEVA ECIJA',
    Location: 'JAEN',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'NUEVA ECIJA',
    Location: 'LAUR',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'NUEVA ECIJA',
    Location: 'LICAB',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'NUEVA ECIJA',
    Location: 'LLANERA',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'NUEVA ECIJA',
    Location: 'LUPAO',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'NUEVA ECIJA',
    Location: 'MUÑOZ CITY',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'NUEVA ECIJA',
    Location: 'NAMPICUAN',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'NUEVA ECIJA',
    Location: 'PALAYAN CITY',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'NUEVA ECIJA',
    Location: 'PANTABANGAN',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'NUEVA ECIJA',
    Location: 'PENARANDA',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'NUEVA ECIJA',
    Location: 'QUEZON',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'NUEVA ECIJA',
    Location: 'RIZAL',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'NUEVA ECIJA',
    Location: 'SAN ANTONIO',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'NUEVA ECIJA',
    Location: 'SAN ISIDRO',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'NUEVA ECIJA',
    Location: 'SAN JOSE CITY',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'NUEVA ECIJA',
    Location: 'SAN LEONARDO',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'NUEVA ECIJA',
    Location: 'STA. ROSA',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'NUEVA ECIJA',
    Location: 'STO. DOMINGO',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'NUEVA ECIJA',
    Location: 'TALAVERA',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'NUEVA ECIJA',
    Location: 'TALUGTUG',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'NUEVA ECIJA',
    Location: 'ZARAGOZA',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'NUEVA VIZCAYA',
    Location: 'ALFONSO CASTANEDA',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'NUEVA VIZCAYA',
    Location: 'AMBAGUIO',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'NUEVA VIZCAYA',
    Location: 'ARITAO',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'NUEVA VIZCAYA',
    Location: 'BAGABAG',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'NUEVA VIZCAYA',
    Location: 'BAMBANG',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'NUEVA VIZCAYA',
    Location: 'BAYOMBONG',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'NUEVA VIZCAYA',
    Location: 'DIADI',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'NUEVA VIZCAYA',
    Location: 'DUPAX DEL NORTE',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'NUEVA VIZCAYA',
    Location: 'DUPAX DEL SUR',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'NUEVA VIZCAYA',
    Location: 'KASIBU',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'NUEVA VIZCAYA',
    Location: 'KAYAPA',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'NUEVA VIZCAYA',
    Location: 'QUEZON',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'NUEVA VIZCAYA',
    Location: 'SOLANO',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'NUEVA VIZCAYA',
    Location: 'STA. FE',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'NUEVA VIZCAYA',
    Location: 'VILLA VERDE',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'ORIENTAL MINDORO',
    Location: 'BACO',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'ORIENTAL MINDORO',
    Location: 'BANSUD',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'ORIENTAL MINDORO',
    Location: 'BONGABONG',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'ORIENTAL MINDORO',
    Location: 'BULALACAO',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'ORIENTAL MINDORO',
    Location: 'CALAPAN',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'ORIENTAL MINDORO',
    Location: 'GLORIA',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'ORIENTAL MINDORO',
    Location: 'MANSALAY',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'ORIENTAL MINDORO',
    Location: 'NAUJAN',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'ORIENTAL MINDORO',
    Location: 'PINAMALAYAN',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'ORIENTAL MINDORO',
    Location: 'POLA',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'ORIENTAL MINDORO',
    Location: 'PUERTO GALERA',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'ORIENTAL MINDORO',
    Location: 'ROXAS',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'ORIENTAL MINDORO',
    Location: 'SAN TEODORO',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'ORIENTAL MINDORO',
    Location: 'SOCORRO',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'ORIENTAL MINDORO',
    Location: 'VICTORIA',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'PAMPANGA',
    Location: 'ANGELES CITY',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'PAMPANGA',
    Location: 'APALIT',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'PAMPANGA',
    Location: 'ARAYAT',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'PAMPANGA',
    Location: 'BACOLOR',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'PAMPANGA',
    Location: 'CANDABA',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'PAMPANGA',
    Location: 'CLARK AIR BASE',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'PAMPANGA',
    Location: 'DAU',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'PAMPANGA',
    Location: 'FLORIDABLANCA',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'PAMPANGA',
    Location: 'GUAGUA',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'PAMPANGA',
    Location: 'LUBAO',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'PAMPANGA',
    Location: 'MABALACAT',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'PAMPANGA',
    Location: 'MACABEBE',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'PAMPANGA',
    Location: 'MAGALANG',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'PAMPANGA',
    Location: 'MASANTOL',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'PAMPANGA',
    Location: 'MEXICO',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'PAMPANGA',
    Location: 'MINALIN',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'PAMPANGA',
    Location: 'PORAC',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'PAMPANGA',
    Location: 'SAN FERNANDO',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'PAMPANGA',
    Location: 'SAN LUIS',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'PAMPANGA',
    Location: 'SAN SIMON',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'PAMPANGA',
    Location: 'SASMUAN',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'PAMPANGA',
    Location: 'STA. ANA',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'PAMPANGA',
    Location: 'STA. RITA',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'PAMPANGA',
    Location: 'STO. TOMAS',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'PANGASINAN',
    Location: 'AGNO',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'PANGASINAN',
    Location: 'AGUILAR',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'PANGASINAN',
    Location: 'ALAMINOS',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'PANGASINAN',
    Location: 'ALCALA',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'PANGASINAN',
    Location: 'ANDA',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'PANGASINAN',
    Location: 'ASIGNAN',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'PANGASINAN',
    Location: 'BALUNGAO',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'PANGASINAN',
    Location: 'BANI',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'PANGASINAN',
    Location: 'BASISTA',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'PANGASINAN',
    Location: 'BAUTISTA',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'PANGASINAN',
    Location: 'BAYAMBANG',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'PANGASINAN',
    Location: 'BINALONAN',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'PANGASINAN',
    Location: 'BINMALEY',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'PANGASINAN',
    Location: 'BOLINAO',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'PANGASINAN',
    Location: 'BUGALLON',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'PANGASINAN',
    Location: 'BURGOS',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'PANGASINAN',
    Location: 'CALASIAO',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'PANGASINAN',
    Location: 'DAGUPAN CITY',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'PANGASINAN',
    Location: 'DASOL',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'PANGASINAN',
    Location: 'INFANTA',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'PANGASINAN',
    Location: 'LABRADOR',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'PANGASINAN',
    Location: 'LAOAC',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'PANGASINAN',
    Location: 'LINGAYEN',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'PANGASINAN',
    Location: 'MABINI',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'PANGASINAN',
    Location: 'MALASIQUI',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'PANGASINAN',
    Location: 'MANAOAG',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'PANGASINAN',
    Location: 'MANGALDAN',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'PANGASINAN',
    Location: 'MANGATAREM',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'PANGASINAN',
    Location: 'MAPANDAN',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'PANGASINAN',
    Location: 'NATIVIDAD',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'PANGASINAN',
    Location: 'POZORRUBIO',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'PANGASINAN',
    Location: 'ROSALES',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'PANGASINAN',
    Location: 'SAN CARLOS CITY',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'PANGASINAN',
    Location: 'SAN FABIAN',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'PANGASINAN',
    Location: 'SAN JACINTO',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'PANGASINAN',
    Location: 'SAN MANUEL',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'PANGASINAN',
    Location: 'SAN NICOLAS',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'PANGASINAN',
    Location: 'SAN QUINTIN',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'PANGASINAN',
    Location: 'SISON',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'PANGASINAN',
    Location: 'STA. BARBARA',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'PANGASINAN',
    Location: 'STA. MARIA',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'PANGASINAN',
    Location: 'STO. TOMAS',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'PANGASINAN',
    Location: 'SUAL',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'PANGASINAN',
    Location: 'TAYUG',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'PANGASINAN',
    Location: 'UMINGAN',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'PANGASINAN',
    Location: 'URBIZTONDO',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'PANGASINAN',
    Location: 'URDANETA',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'PANGASINAN',
    Location: 'VILLASIS',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'QUEZON',
    Location: 'AGDANGAN',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'QUEZON',
    Location: 'ALABAT',
    Rate: '165',
    Remarks: 'ISLAND TRANSFER',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'QUEZON',
    Location: 'ATIMONAN',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'QUEZON',
    Location: 'BUENAVISTA',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'QUEZON',
    Location: 'BURDEOS',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'QUEZON',
    Location: 'CALAUAG',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'QUEZON',
    Location: 'CANDELARIA',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'QUEZON',
    Location: 'CATANAUAN',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'QUEZON',
    Location: 'DOLORES',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'QUEZON',
    Location: 'GENERAL LUNA',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'QUEZON',
    Location: 'GENERAL NAKAR',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'QUEZON',
    Location: 'GUINAYANGAN',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'QUEZON',
    Location: 'GUMACA',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'QUEZON',
    Location: 'HONDAGUA',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'QUEZON',
    Location: 'INFANTA',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'QUEZON',
    Location: 'JOMALIG',
    Rate: '165',
    Remarks: 'ISLAND TRANSFER',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'QUEZON',
    Location: 'LOPEZ',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'QUEZON',
    Location: 'LUCBAN',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'QUEZON',
    Location: 'LUCENA',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'QUEZON',
    Location: 'MACALELON',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'QUEZON',
    Location: 'MAUBAN',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'QUEZON',
    Location: 'MULANAY',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'QUEZON',
    Location: 'PADRE BURGOS',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'QUEZON',
    Location: 'PAGBILAO',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'QUEZON',
    Location: 'PANUKULAN',
    Rate: '165',
    Remarks: 'ISLAND TRANSFER',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'QUEZON',
    Location: 'PATNANONGAN',
    Rate: '165',
    Remarks: 'ISLAND TRANSFER',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'QUEZON',
    Location: 'PEREZ',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'QUEZON',
    Location: 'PITOGO',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'QUEZON',
    Location: 'PLARIDEL',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'QUEZON',
    Location: 'POLILLO',
    Rate: '165',
    Remarks: 'ISLAND TRANSFER',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'QUEZON',
    Location: 'QUEZON',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'QUEZON',
    Location: 'REAL',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'QUEZON',
    Location: 'SAMPALOC',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'QUEZON',
    Location: 'SAN ANDRES',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'QUEZON',
    Location: 'SAN ANTONIO',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'QUEZON',
    Location: 'SAN FRANCISCO',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'QUEZON',
    Location: 'SAN NARCISO',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'QUEZON',
    Location: 'SARIAYA',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'QUEZON',
    Location: 'TAGKAWAYAN',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'QUEZON',
    Location: 'TAYABAS',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'QUEZON',
    Location: 'TIAONG',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'QUEZON',
    Location: 'UNISAN',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'QUIRINO',
    Location: 'AGLIPAY',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'QUIRINO',
    Location: 'CABARROGUIS',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'QUIRINO',
    Location: 'DIFFUN',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'QUIRINO',
    Location: 'MADDELA',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'QUIRINO',
    Location: 'NAGTIPUNAN',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'QUIRINO',
    Location: 'SAGUDAY',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'RIZAL',
    Location: 'ANGONO',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'RIZAL',
    Location: 'ANTIPOLO',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'RIZAL',
    Location: 'BARAS',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'RIZAL',
    Location: 'BINANGONAN',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'RIZAL',
    Location: 'CAINTA',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'RIZAL',
    Location: 'CARDONA',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'RIZAL',
    Location: 'JALA JALA',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'RIZAL',
    Location: 'MORONG',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'RIZAL',
    Location: 'PILILLA',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'RIZAL',
    Location: 'RODRIGUEZ (MONTALBAN)',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'RIZAL',
    Location: 'SAN MATEO',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'RIZAL',
    Location: 'TANAY',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'RIZAL',
    Location: 'TAYTAY',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'RIZAL',
    Location: 'TERESA',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'SORSOGON',
    Location: 'BACON',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'SORSOGON',
    Location: 'BARCELONA',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'SORSOGON',
    Location: 'BGY CALAYOAN',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'SORSOGON',
    Location: 'BULAN',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'SORSOGON',
    Location: 'BULUSAN',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'SORSOGON',
    Location: 'CASIGURAN',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'SORSOGON',
    Location: 'CASTILLA',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'SORSOGON',
    Location: 'DONSOL',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'SORSOGON',
    Location: 'GUBAT',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'SORSOGON',
    Location: 'IROSIN',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'SORSOGON',
    Location: 'JUBAN',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'SORSOGON',
    Location: 'MAGALLANES',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'SORSOGON',
    Location: 'MATNOG',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'SORSOGON',
    Location: 'PILAR',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'SORSOGON',
    Location: 'PRIETO DIAZ',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'SORSOGON',
    Location: 'SORSOGON',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'SORSOGON',
    Location: 'STA. MAGDALENA',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'TARLAC',
    Location: 'ANAO',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'TARLAC',
    Location: 'BAMBAN',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'TARLAC',
    Location: 'CAMILING',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'TARLAC',
    Location: 'CAPAS',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'TARLAC',
    Location: 'CONCEPCION',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'TARLAC',
    Location: 'GERONA',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'TARLAC',
    Location: 'LA PAZ',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'TARLAC',
    Location: 'MAYANTOC',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'TARLAC',
    Location: 'MONCADA',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'TARLAC',
    Location: 'PANIQUI',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'TARLAC',
    Location: 'PURA',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'TARLAC',
    Location: 'RAMOS',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'TARLAC',
    Location: 'SAN CLEMENTE',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'TARLAC',
    Location: 'SAN JOSE',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'TARLAC',
    Location: 'SAN MANUEL',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'TARLAC',
    Location: 'SAN MIGUEL',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'TARLAC',
    Location: 'STA. IGNACIA',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'TARLAC',
    Location: 'TARLAC',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'TARLAC',
    Location: 'VICTORIA',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'ZAMBALES',
    Location: 'BOTOLAN',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'ZAMBALES',
    Location: 'CABANGAN',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'ZAMBALES',
    Location: 'CANDELARIA',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'ZAMBALES',
    Location: 'CASTILLEJOS',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'ZAMBALES',
    Location: 'IBA',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'ZAMBALES',
    Location: 'MASINLOC',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'ZAMBALES',
    Location: 'OLONGAPO CITY',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'ZAMBALES',
    Location: 'PALAUIG CITY',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'ZAMBALES',
    Location: 'SAN ANTONIO',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'ZAMBALES',
    Location: 'SAN FELIPE',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'ZAMBALES',
    Location: 'SAN MARCELINO',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'ZAMBALES',
    Location: 'SAN NARCISO',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'ZAMBALES',
    Location: 'STA. CRUZ',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'ZAMBALES',
    Location: 'SUBIC',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'AGUSAN DEL NORTE',
    Location: 'BUENAVISTA',
    Rate: '145',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'AGUSAN DEL NORTE',
    Location: 'BUTUAN CITY',
    Rate: '145',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'AGUSAN DEL NORTE',
    Location: 'CABADBARAN',
    Rate: '145',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'AGUSAN DEL NORTE',
    Location: 'CARMEN',
    Rate: '145',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'AGUSAN DEL NORTE',
    Location: 'JABONGA',
    Rate: '145',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'AGUSAN DEL NORTE',
    Location: 'KITCHARAO',
    Rate: '145',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'AGUSAN DEL NORTE',
    Location: 'LAS NIEVES',
    Rate: '145',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'AGUSAN DEL NORTE',
    Location: 'MAGALLANES',
    Rate: '145',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'AGUSAN DEL NORTE',
    Location: 'NASIPIT',
    Rate: '145',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'AGUSAN DEL NORTE',
    Location: 'RT ROMUALDEZ',
    Rate: '145',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'AGUSAN DEL NORTE',
    Location: 'SANTIAGO',
    Rate: '145',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'AGUSAN DEL NORTE',
    Location: 'TUBAY',
    Rate: '145',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'AGUSAN DEL SUR',
    Location: 'BAYUGAN',
    Rate: '145',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'AGUSAN DEL SUR',
    Location: 'BUNAWAN',
    Rate: '195',
    Remarks: 'WITH TRANSSHIPMENT CHARGE',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'AGUSAN DEL SUR',
    Location: 'ESPERANZA',
    Rate: '145',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'AGUSAN DEL SUR',
    Location: 'LA PAZ',
    Rate: '145',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'AGUSAN DEL SUR',
    Location: 'LORETO',
    Rate: '195',
    Remarks: 'WITH TRANSSHIPMENT CHARGE',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'AGUSAN DEL SUR',
    Location: 'PROSPERIDAD',
    Rate: '195',
    Remarks: 'WITH TRANSSHIPMENT CHARGE',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'AGUSAN DEL SUR',
    Location: 'ROSARIO',
    Rate: '145',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'AGUSAN DEL SUR',
    Location: 'SAN FRANCISCO',
    Rate: '145',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'AGUSAN DEL SUR',
    Location: 'SAN LUIS',
    Rate: '195',
    Remarks: 'WITH TRANSSHIPMENT CHARGE',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'AGUSAN DEL SUR',
    Location: 'SANTA JOSEFA',
    Rate: '195',
    Remarks: 'WITH TRANSSHIPMENT CHARGE',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'AGUSAN DEL SUR',
    Location: 'TALACOGON',
    Rate: '195',
    Remarks: 'WITH TRANSSHIPMENT CHARGE',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'AGUSAN DEL SUR',
    Location: 'TRENTO',
    Rate: '195',
    Remarks: 'WITH TRANSSHIPMENT CHARGE',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'AGUSAN DEL SUR',
    Location: 'VERUELA',
    Rate: '145',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'BASILAN',
    Location: 'AKBAR',
    Rate: '195',
    Remarks: 'PICK-UP AT ZAMBOANGA STATION',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'BASILAN',
    Location: 'AL-BARKA',
    Rate: '195',
    Remarks: 'PICK-UP AT ZAMBOANGA STATION',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'BASILAN',
    Location: 'HADJI MOHAMMAD',
    Rate: '195',
    Remarks: 'PICK-UP AT ZAMBOANGA STATION',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'BASILAN',
    Location: 'HADJI MUHTAMAD',
    Rate: '195',
    Remarks: 'PICK-UP AT ZAMBOANGA STATION',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'BASILAN',
    Location: 'ISABELA CITY',
    Rate: '245',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'BASILAN',
    Location: 'LAMITAN CITY',
    Rate: '195',
    Remarks: 'PICK-UP AT ZAMBOANGA STATION',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'BASILAN',
    Location: 'LANTAWAN',
    Rate: '195',
    Remarks: 'PICK-UP AT ZAMBOANGA STATION',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'BASILAN',
    Location: 'MALUSO',
    Rate: '195',
    Remarks: 'PICK-UP AT ZAMBOANGA STATION',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'BASILAN',
    Location: 'SUMISIP',
    Rate: '195',
    Remarks: 'PICK-UP AT ZAMBOANGA STATION',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'BASILAN',
    Location: 'TABUAN-LASA',
    Rate: '195',
    Remarks: 'PICK-UP AT ZAMBOANGA STATION',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'BASILAN',
    Location: 'TIPO-TIPO',
    Rate: '195',
    Remarks: 'PICK-UP AT ZAMBOANGA STATION',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'BASILAN',
    Location: 'TUBURAN',
    Rate: '195',
    Remarks: 'PICK-UP AT ZAMBOANGA STATION',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'BASILAN',
    Location: 'UNGKAYA PUKAN',
    Rate: '195',
    Remarks: 'PICK-UP AT ZAMBOANGA STATION',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'BUKIDNON',
    Location: 'BAUNGON',
    Rate: '145',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'BUKIDNON',
    Location: 'CABANGLASAN',
    Rate: '195',
    Remarks: 'PICK-UP MALAYBALAY STATION ',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'BUKIDNON',
    Location: 'DAMULOG',
    Rate: '195',
    Remarks: 'WITH TRANSSHIPMENT CHARGE',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'BUKIDNON',
    Location: 'DANGCAGAN',
    Rate: '195',
    Remarks: 'WITH TRANSSHIPMENT CHARGE',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'BUKIDNON',
    Location: 'DON CARLOS',
    Rate: '145',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'BUKIDNON',
    Location: 'IMPASUGONG',
    Rate: '145',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'BUKIDNON',
    Location: 'KADINGILAN',
    Rate: '195',
    Remarks: 'WITH TRANSSHIPMENT CHARGE',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'BUKIDNON',
    Location: 'KALILANGAN',
    Rate: '195',
    Remarks: 'WITH TRANSSHIPMENT CHARGE',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'BUKIDNON',
    Location: 'KIBAWE',
    Rate: '195',
    Remarks: 'WITH TRANSSHIPMENT CHARGE',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'BUKIDNON',
    Location: 'KISOLON',
    Rate: '145',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'BUKIDNON',
    Location: 'KITAOTAO',
    Rate: '195',
    Remarks: 'WITH TRANSSHIPMENT CHARGE',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'BUKIDNON',
    Location: 'LANTAPAN',
    Rate: '195',
    Remarks: 'WITH TRANSSHIPMENT CHARGE',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'BUKIDNON',
    Location: 'LIBONA',
    Rate: '145',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'BUKIDNON',
    Location: 'MALAYBALAY',
    Rate: '195',
    Remarks: 'WITH TRANSSHIPMENT CHARGE',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'BUKIDNON',
    Location: 'MALITBOG',
    Rate: '195',
    Remarks: 'PICK-UP AT CAGAYAN STATION',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'BUKIDNON',
    Location: 'MANOLO FORTICH',
    Rate: '145',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'BUKIDNON',
    Location: 'MARAMAG',
    Rate: '195',
    Remarks: 'WITH TRANSSHIPMENT CHARGE',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'BUKIDNON',
    Location: 'PANGANTUCAN',
    Rate: '195',
    Remarks: 'WITH TRANSSHIPMENT CHARGE',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'BUKIDNON',
    Location: 'QUEZON',
    Rate: '195',
    Remarks: 'WITH TRANSSHIPMENT CHARGE',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'BUKIDNON',
    Location: 'SAN FERNANDO',
    Rate: '195',
    Remarks: 'PICK-UP MALAYBALAY STATION ',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'BUKIDNON',
    Location: 'SUMILAO',
    Rate: '145',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'BUKIDNON',
    Location: 'TALAKAG',
    Rate: '145',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'BUKIDNON',
    Location: 'VALENCIA',
    Rate: '195',
    Remarks: 'WITH TRANSSHIPMENT CHARGE',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'CAMIGUIN ISLAND',
    Location: 'CATARMAN',
    Rate: '245',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'CAMIGUIN ISLAND',
    Location: 'GUINSILIBAN',
    Rate: '245',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'CAMIGUIN ISLAND',
    Location: 'MAHINOG',
    Rate: '245',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'CAMIGUIN ISLAND',
    Location: 'MAMBAJAO',
    Rate: '245',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'CAMIGUIN ISLAND',
    Location: 'SAGAY',
    Rate: '245',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'COMPOSTELLA VALLEY',
    Location: 'COMPOSTELA',
    Rate: '145',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'COMPOSTELLA VALLEY',
    Location: 'LAAK (SAN VICENTE)',
    Rate: '145',
    Remarks: 'PICK-UP DAVAO STATION',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'COMPOSTELLA VALLEY',
    Location: 'MABINI (DONA ALICIA)',
    Rate: '145',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'COMPOSTELLA VALLEY',
    Location: 'MACO',
    Rate: '145',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'COMPOSTELLA VALLEY',
    Location: 'MARAGUSAN',
    Rate: '145',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'COMPOSTELLA VALLEY',
    Location: 'MAWAB',
    Rate: '145',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'COMPOSTELLA VALLEY',
    Location: 'MONKAYO',
    Rate: '145',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'COMPOSTELLA VALLEY',
    Location: 'MONTEVISTA',
    Rate: '145',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'COMPOSTELLA VALLEY',
    Location: 'NABUTURAN',
    Rate: '145',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'COMPOSTELLA VALLEY',
    Location: 'NEW BATAAN',
    Rate: '145',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'COMPOSTELLA VALLEY',
    Location: 'PANTUKAN',
    Rate: '145',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'COTABATO, NORTH',
    Location: 'ALAMADA',
    Rate: '145',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'COTABATO, NORTH',
    Location: 'ALEOSAN',
    Rate: '145',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'COTABATO, NORTH',
    Location: 'ANTIPAS',
    Rate: '145',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'COTABATO, NORTH',
    Location: 'BANISILAN',
    Rate: '145',
    Remarks: 'PICK-UP COTABATO STATION',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'COTABATO, NORTH',
    Location: 'CARMEN',
    Rate: '145',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'COTABATO, NORTH',
    Location: 'KABACAN',
    Rate: '145',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'COTABATO, NORTH',
    Location: 'KIDAPAWAN',
    Rate: '145',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'COTABATO, NORTH',
    Location: 'LIBUNGAN',
    Rate: '145',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'COTABATO, NORTH',
    Location: 'MAGPET',
    Rate: '145',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'COTABATO, NORTH',
    Location: 'MAKILALA',
    Rate: '145',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'COTABATO, NORTH',
    Location: 'MATALAM',
    Rate: '145',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'COTABATO, NORTH',
    Location: 'MIDSAYAP',
    Rate: '145',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'COTABATO, NORTH',
    Location: "M'LANG",
    Rate: '145',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'COTABATO, NORTH',
    Location: 'PIGKAWAYAN',
    Rate: '145',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'COTABATO, NORTH',
    Location: 'PIKIT',
    Rate: '145',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'COTABATO, NORTH',
    Location: 'PRESIDENT ROXAS',
    Rate: '145',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'COTABATO, NORTH',
    Location: 'TULUNAN',
    Rate: '145',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'COTABATO, SOUTH',
    Location: 'BANGA',
    Rate: '145',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'COTABATO, SOUTH',
    Location: 'GENERAL SANTOS CITY',
    Rate: '145',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'COTABATO, SOUTH',
    Location: 'KORONADAL',
    Rate: '145',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'COTABATO, SOUTH',
    Location: 'LAKE SEBU',
    Rate: '145',
    Remarks: 'PICK-UP AT GEN SAN STATION',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'COTABATO, SOUTH',
    Location: 'NORALA',
    Rate: '145',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'COTABATO, SOUTH',
    Location: 'POLOMOLOK',
    Rate: '145',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'COTABATO, SOUTH',
    Location: 'STO. NIÑO',
    Rate: '145',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'COTABATO, SOUTH',
    Location: 'SURALLAH',
    Rate: '145',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'COTABATO, SOUTH',
    Location: 'TAMPAKAN',
    Rate: '145',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'COTABATO, SOUTH',
    Location: 'TANTANGAN',
    Rate: '145',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'COTABATO, SOUTH',
    Location: "T'BOLI",
    Rate: '145',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'COTABATO, SOUTH',
    Location: 'TUPI',
    Rate: '145',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'DAVAO DEL NORTE',
    Location: 'ASUNCION',
    Rate: '145',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'DAVAO DEL NORTE',
    Location: 'BRAULIO E. DUJALI',
    Rate: '145',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'DAVAO DEL NORTE',
    Location: 'CARMEN',
    Rate: '145',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'DAVAO DEL NORTE',
    Location: 'KAPALONG',
    Rate: '145',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'DAVAO DEL NORTE',
    Location: 'NEW CORELLA',
    Rate: '145',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'DAVAO DEL NORTE',
    Location: 'PANABO CITY',
    Rate: '145',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'DAVAO DEL NORTE',
    Location: 'SAMAL CITY (SAMAL ISLAND)',
    Rate: '245',
    Remarks: '1 ON 1 DELIVERY',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'DAVAO DEL NORTE',
    Location: 'SAN ISIDRO',
    Rate: '195',
    Remarks: 'WITH TRANSSHIPMENT CHARGE',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'DAVAO DEL NORTE',
    Location: 'SANTO TOMAS',
    Rate: '145',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'DAVAO DEL NORTE',
    Location: 'TAGUM CITY',
    Rate: '145',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'DAVAO DEL NORTE',
    Location: 'TALAINGOD',
    Rate: '145',
    Remarks: 'PICK-UP AT DAVAO STATION',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'DAVAO DEL SUR ',
    Location: 'BANSALAN',
    Rate: '145',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'DAVAO DEL SUR ',
    Location: 'DAVAO CITY',
    Rate: '145',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'DAVAO DEL SUR ',
    Location: 'DIGOS CITY',
    Rate: '145',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'DAVAO DEL SUR ',
    Location: 'DON MARCELINO',
    Rate: '145',
    Remarks: 'PICK-UP AT DAVAO STATION',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'DAVAO DEL SUR ',
    Location: 'HAGONOY',
    Rate: '145',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'DAVAO DEL SUR ',
    Location: 'JOSE ABAD SANTOS',
    Rate: '145',
    Remarks: 'PICK-UP AT DAVAO STATION',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'DAVAO DEL SUR ',
    Location: 'KIBLAWAN',
    Rate: '145',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'DAVAO DEL SUR ',
    Location: 'MAGSAYSAY',
    Rate: '145',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'DAVAO DEL SUR ',
    Location: 'MALALAG',
    Rate: '145',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'DAVAO DEL SUR ',
    Location: 'MALITA',
    Rate: '195',
    Remarks: 'WITH TRANSSHIPMENT CHARGE',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'DAVAO DEL SUR ',
    Location: 'MATANAO',
    Rate: '145',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'DAVAO DEL SUR ',
    Location: 'PADADA',
    Rate: '145',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'DAVAO DEL SUR ',
    Location: 'SANTA CRUZ',
    Rate: '145',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'DAVAO DEL SUR ',
    Location: 'SANTA MARIA',
    Rate: '195',
    Remarks: 'WITH TRANSSHIPMENT CHARGE',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'DAVAO DEL SUR ',
    Location: 'SARANGANI  ',
    Rate: '145',
    Remarks: 'PICK-UP AT DAVAO STATION',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'DAVAO DEL SUR ',
    Location: 'SULOP',
    Rate: '145',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'DAVAO ORIENTAL',
    Location: 'BANAY-BANAY',
    Rate: '195',
    Remarks: 'WITH TRANSSHIPMENT CHARGE',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'DAVAO ORIENTAL',
    Location: 'BANGANGA',
    Rate: '145',
    Remarks: 'PICK-UP AT DAVAO STATION',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'DAVAO ORIENTAL',
    Location: 'BOSTON',
    Rate: '145',
    Remarks: 'PICK-UP AT DAVAO STATION',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'DAVAO ORIENTAL',
    Location: 'CARAGA',
    Rate: '145',
    Remarks: 'PICK-UP AT DAVAO STATION',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'DAVAO ORIENTAL',
    Location: 'CATEEL',
    Rate: '145',
    Remarks: 'PICK-UP AT DAVAO STATION',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'DAVAO ORIENTAL',
    Location: 'GOVERNOR GENEROSO',
    Rate: '195',
    Remarks: 'WITH TRANSSHIPMENT CHARGE',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'DAVAO ORIENTAL',
    Location: 'LUPON',
    Rate: '195',
    Remarks: 'WITH TRANSSHIPMENT CHARGE',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'DAVAO ORIENTAL',
    Location: 'MANAY',
    Rate: '145',
    Remarks: 'PICK-UP AT DAVAO STATION',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'DAVAO ORIENTAL',
    Location: 'MATI CITY',
    Rate: '195',
    Remarks: 'WITH TRANSSHIPMENT CHARGE',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'DAVAO ORIENTAL',
    Location: 'SAN ISIDRO',
    Rate: '195',
    Remarks: 'WITH TRANSSHIPMENT CHARGE',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'DAVAO ORIENTAL',
    Location: 'TARRAGONA',
    Rate: '145',
    Remarks: 'PICK-UP AT DAVAO STATION',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'LANAO DEL NORTE ',
    Location: 'BACOLOD ',
    Rate: '145',
    Remarks: 'PICK-UP AT ILIGAN STATION',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'LANAO DEL NORTE ',
    Location: 'BALOI',
    Rate: '145',
    Remarks: 'PICK-UP AT ILIGAN STATION',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'LANAO DEL NORTE ',
    Location: 'BAROY ',
    Rate: '145',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'LANAO DEL NORTE ',
    Location: 'ILIGAN CITY',
    Rate: '145',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'LANAO DEL NORTE ',
    Location: 'KAUSWAGAN',
    Rate: '145',
    Remarks: 'PICK-UP AT ILIGAN STATION',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'LANAO DEL NORTE ',
    Location: 'KOLAMBUGAN',
    Rate: '145',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'LANAO DEL NORTE ',
    Location: 'LALA (Maranding)',
    Rate: '195',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'LANAO DEL NORTE ',
    Location: 'LINAMON',
    Rate: '145',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'LANAO DEL NORTE ',
    Location: 'MAGSAYSAY',
    Rate: '145',
    Remarks: 'PICK-UP AT ILIGAN STATION',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'LANAO DEL NORTE ',
    Location: 'MAIGO',
    Rate: '145',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'LANAO DEL NORTE ',
    Location: 'MATUNGAO',
    Rate: '145',
    Remarks: 'PICK-UP AT ILIGAN STATION',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'LANAO DEL NORTE ',
    Location: 'MUNAI',
    Rate: '145',
    Remarks: 'PICK-UP AT ILIGAN STATION',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'LANAO DEL NORTE ',
    Location: 'NUNUNGAN',
    Rate: '145',
    Remarks: 'PICK-UP AT ILIGAN STATION',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'LANAO DEL NORTE ',
    Location: 'PANTAO RAGAT',
    Rate: '145',
    Remarks: 'PICK-UP AT ILIGAN STATION',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'LANAO DEL NORTE ',
    Location: 'PANTAR',
    Rate: '145',
    Remarks: 'PICK-UP AT ILIGAN STATION',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'LANAO DEL NORTE ',
    Location: 'POONA PIAGAPO',
    Rate: '145',
    Remarks: 'PICK-UP AT ILIGAN STATION',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'LANAO DEL NORTE ',
    Location: 'SALVADOR',
    Rate: '145',
    Remarks: 'PICK-UP AT ILIGAN STATION',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'LANAO DEL NORTE ',
    Location: 'SAPAD',
    Rate: '145',
    Remarks: 'PICK-UP AT ILIGAN STATION',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'LANAO DEL NORTE ',
    Location: 'SULTAN NAGA DIMAPORO',
    Rate: '145',
    Remarks: 'PICK-UP AT ILIGAN STATION',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'LANAO DEL NORTE ',
    Location: 'TAGOLOAN',
    Rate: '145',
    Remarks: 'PICK-UP AT ILIGAN STATION',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'LANAO DEL NORTE ',
    Location: 'TANGCAL',
    Rate: '145',
    Remarks: 'PICK-UP AT ILIGAN STATION',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'LANAO DEL NORTE ',
    Location: 'TUBOD',
    Rate: '145',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'LANAO DEL SUR',
    Location: 'BACOLOD GRANDE',
    Rate: '145',
    Remarks: 'PICK-UP AT ILIGAN STATION',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'LANAO DEL SUR',
    Location: 'BALABAGAN',
    Rate: '145',
    Remarks: 'PICK-UP AT ILIGAN STATION',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'LANAO DEL SUR',
    Location: 'BALINDONG',
    Rate: '145',
    Remarks: 'PICK-UP AT ILIGAN STATION',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'LANAO DEL SUR',
    Location: 'BAYANG',
    Rate: '145',
    Remarks: 'PICK-UP AT ILIGAN STATION',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'LANAO DEL SUR',
    Location: 'BINDAYAN',
    Rate: '145',
    Remarks: 'PICK-UP AT ILIGAN STATION',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'LANAO DEL SUR',
    Location: 'BUADIPOSO-BUNTONG',
    Rate: '145',
    Remarks: 'PICK-UP AT ILIGAN STATION',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'LANAO DEL SUR',
    Location: 'BUBONG',
    Rate: '145',
    Remarks: 'PICK-UP AT ILIGAN STATION',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'LANAO DEL SUR',
    Location: 'BUMBARAN',
    Rate: '145',
    Remarks: 'PICK-UP AT ILIGAN STATION',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'LANAO DEL SUR',
    Location: 'BUTIG',
    Rate: '145',
    Remarks: 'PICK-UP AT ILIGAN STATION',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'LANAO DEL SUR',
    Location: 'CALANOGAS',
    Rate: '145',
    Remarks: 'PICK-UP AT ILIGAN STATION',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'LANAO DEL SUR',
    Location: 'DITSAAN RAMAIN',
    Rate: '145',
    Remarks: 'PICK-UP AT ILIGAN STATION',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'LANAO DEL SUR',
    Location: 'GANASSI',
    Rate: '145',
    Remarks: 'PICK-UP AT ILIGAN STATION',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'LANAO DEL SUR',
    Location: 'KAPAI',
    Rate: '145',
    Remarks: 'PICK-UP AT ILIGAN STATION',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'LANAO DEL SUR',
    Location: 'KAPATAGAN ',
    Rate: '145',
    Remarks: 'PICK-UP AT ILIGAN STATION',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'LANAO DEL SUR',
    Location: 'LUMBA BAYABAO',
    Rate: '145',
    Remarks: 'PICK-UP AT ILIGAN STATION',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'LANAO DEL SUR',
    Location: 'LUMBACA-UNAYAN',
    Rate: '145',
    Remarks: 'PICK-UP AT ILIGAN STATION',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'LANAO DEL SUR',
    Location: 'LUMBATAN',
    Rate: '145',
    Remarks: 'PICK-UP AT ILIGAN STATION',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'LANAO DEL SUR',
    Location: 'LUMBAYANAGUE',
    Rate: '145',
    Remarks: 'PICK-UP AT ILIGAN STATION',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'LANAO DEL SUR',
    Location: 'MADALUM',
    Rate: '145',
    Remarks: 'PICK-UP AT ILIGAN STATION',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'LANAO DEL SUR',
    Location: 'MADAMBA',
    Rate: '145',
    Remarks: 'PICK-UP AT ILIGAN STATION',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'LANAO DEL SUR',
    Location: 'MAGUING',
    Rate: '145',
    Remarks: 'PICK-UP AT ILIGAN STATION',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'LANAO DEL SUR',
    Location: 'MALABANG',
    Rate: '145',
    Remarks: 'PICK-UP AT ILIGAN STATION',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'LANAO DEL SUR',
    Location: 'MARANTAO',
    Rate: '145',
    Remarks: 'PICK-UP AT ILIGAN STATION',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'LANAO DEL SUR',
    Location: 'MARAWI CITY',
    Rate: '145',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'LANAO DEL SUR',
    Location: 'MAROGONG',
    Rate: '145',
    Remarks: 'PICK-UP AT ILIGAN STATION',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'LANAO DEL SUR',
    Location: 'MASIU',
    Rate: '145',
    Remarks: 'PICK-UP AT ILIGAN STATION',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'LANAO DEL SUR',
    Location: 'MOLUNDO',
    Rate: '145',
    Remarks: 'PICK-UP AT ILIGAN STATION',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'LANAO DEL SUR',
    Location: 'PAGAYAWAN',
    Rate: '145',
    Remarks: 'PICK-UP AT ILIGAN STATION',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'LANAO DEL SUR',
    Location: 'PIAGAPO',
    Rate: '145',
    Remarks: 'PICK-UP AT ILIGAN STATION',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'LANAO DEL SUR',
    Location: 'POON-A-BAYABAO',
    Rate: '145',
    Remarks: 'PICK-UP AT ILIGAN STATION',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'LANAO DEL SUR',
    Location: 'PUALAS',
    Rate: '145',
    Remarks: 'PICK-UP AT ILIGAN STATION',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'LANAO DEL SUR',
    Location: 'SAGUIARAN',
    Rate: '145',
    Remarks: 'PICK-UP AT ILIGAN STATION',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'LANAO DEL SUR',
    Location: 'SULTAN DUMALONDON',
    Rate: '145',
    Remarks: 'PICK-UP AT ILIGAN STATION',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'LANAO DEL SUR',
    Location: 'SULTAN GUMANDER',
    Rate: '145',
    Remarks: 'PICK-UP AT ILIGAN STATION',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'LANAO DEL SUR',
    Location: 'TAGOLOAN II',
    Rate: '145',
    Remarks: 'PICK-UP AT ILIGAN STATION',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'LANAO DEL SUR',
    Location: 'TAMPARAN',
    Rate: '145',
    Remarks: 'PICK-UP AT ILIGAN STATION',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'LANAO DEL SUR',
    Location: 'TARAKA',
    Rate: '145',
    Remarks: 'PICK-UP AT ILIGAN STATION',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'LANAO DEL SUR',
    Location: 'TUBARAN',
    Rate: '145',
    Remarks: 'PICK-UP AT ILIGAN STATION',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'LANAO DEL SUR',
    Location: 'TUGAYA',
    Rate: '145',
    Remarks: 'PICK-UP AT ILIGAN STATION',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'LANAO DEL SUR',
    Location: 'WAO',
    Rate: '145',
    Remarks: 'PICK-UP AT ILIGAN STATION',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'MAGUINDANAO',
    Location: 'AMPATUAN',
    Rate: '195',
    Remarks: 'PICK-UP AT COTABATO STATION',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'MAGUINDANAO',
    Location: 'BARIRA',
    Rate: '195',
    Remarks: 'PICK-UP AT COTABATO STATION',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'MAGUINDANAO',
    Location: 'BULDON',
    Rate: '195',
    Remarks: 'PICK-UP AT COTABATO STATION',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'MAGUINDANAO',
    Location: 'BULUAN',
    Rate: '195',
    Remarks: 'PICK-UP AT COTABATO STATION',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'MAGUINDANAO',
    Location: 'COTABATO CITY',
    Rate: '195',
    Remarks: 'PICK-UP AT COTABATO STATION',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'MAGUINDANAO',
    Location: 'DATU ABDULLAH SANGKI',
    Rate: '195',
    Remarks: 'PICK-UP AT COTABATO STATION',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'MAGUINDANAO',
    Location: 'DATU ANGGAL MIDTIMBANG',
    Rate: '195',
    Remarks: 'PICK-UP AT COTABATO STATION',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'MAGUINDANAO',
    Location: 'DATU BLAH T. SINSUAT',
    Rate: '195',
    Remarks: 'PICK-UP AT COTABATO STATION',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'MAGUINDANAO',
    Location: 'DATU HOFFER AMPATUAN',
    Rate: '195',
    Remarks: 'PICK-UP AT COTABATO STATION',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'MAGUINDANAO',
    Location: 'DATU ODIN SINSUAT (DINAIG)',
    Rate: '195',
    Remarks: 'PICK-UP AT COTABATO STATION',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'MAGUINDANAO',
    Location: 'DATU PAGLAS',
    Rate: '195',
    Remarks: 'PICK-UP AT COTABATO STATION',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'MAGUINDANAO',
    Location: 'DATU PIANG',
    Rate: '195',
    Remarks: 'PICK-UP AT COTABATO STATION',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'MAGUINDANAO',
    Location: 'DATU SALIBO',
    Rate: '195',
    Remarks: 'PICK-UP AT COTABATO STATION',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'MAGUINDANAO',
    Location: 'DATU SAUDI AMPATUAN',
    Rate: '195',
    Remarks: 'PICK-UP AT COTABATO STATION',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'MAGUINDANAO',
    Location: 'DATU UNSAY',
    Rate: '195',
    Remarks: 'PICK-UP AT COTABATO STATION',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'MAGUINDANAO',
    Location: 'GEN. S.K. PENDATUN',
    Rate: '195',
    Remarks: 'PICK-UP AT COTABATO STATION',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'MAGUINDANAO',
    Location: 'GUINDULUNGAN',
    Rate: '195',
    Remarks: 'PICK-UP AT COTABATO STATION',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'MAGUINDANAO',
    Location: 'KABUNTALAN (TUMBAO)',
    Rate: '195',
    Remarks: 'PICK-UP AT COTABATO STATION',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'MAGUINDANAO',
    Location: 'MAMASAPANO',
    Rate: '195',
    Remarks: 'PICK-UP AT COTABATO STATION',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'MAGUINDANAO',
    Location: 'MANGUDADATU',
    Rate: '195',
    Remarks: 'PICK-UP AT COTABATO STATION',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'MAGUINDANAO',
    Location: 'MATANOG',
    Rate: '195',
    Remarks: 'PICK-UP AT COTABATO STATION',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'MAGUINDANAO',
    Location: 'NORTHERN KABUNTALAN',
    Rate: '195',
    Remarks: 'PICK-UP AT COTABATO STATION',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'MAGUINDANAO',
    Location: 'PAGAGAWAN',
    Rate: '195',
    Remarks: 'PICK-UP AT COTABATO STATION',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'MAGUINDANAO',
    Location: 'PAGALUNGAN',
    Rate: '195',
    Remarks: 'PICK-UP AT COTABATO STATION',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'MAGUINDANAO',
    Location: 'PAGLAT',
    Rate: '195',
    Remarks: 'PICK-UP AT COTABATO STATION',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'MAGUINDANAO',
    Location: 'PANDAG',
    Rate: '195',
    Remarks: 'PICK-UP AT COTABATO STATION',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'MAGUINDANAO',
    Location: 'PARANG',
    Rate: '195',
    Remarks: 'PICK-UP AT COTABATO STATION',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'MAGUINDANAO',
    Location: 'RAJAH BUAYAN',
    Rate: '195',
    Remarks: 'PICK-UP AT COTABATO STATION',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'MAGUINDANAO',
    Location: 'SHARIF AGUAK (MAGANOY)',
    Rate: '195',
    Remarks: 'PICK-UP AT COTABATO STATION',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'MAGUINDANAO',
    Location: 'SHARIFF SAYDONA MUSTAPHA',
    Rate: '195',
    Remarks: 'PICK-UP AT COTABATO STATION',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'MAGUINDANAO',
    Location: 'SOUTH UPI',
    Rate: '195',
    Remarks: 'PICK-UP AT COTABATO STATION',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'MAGUINDANAO',
    Location: 'SULTAN KUDARAT (NULING)',
    Rate: '195',
    Remarks: 'PICK-UP AT COTABATO STATION',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'MAGUINDANAO',
    Location: 'SULTAN MASTURA',
    Rate: '195',
    Remarks: 'PICK-UP AT COTABATO STATION',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'MAGUINDANAO',
    Location: 'SULTAN SA BARONGIS (LAMBAYONG)',
    Rate: '195',
    Remarks: 'PICK-UP AT COTABATO STATION',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'MAGUINDANAO',
    Location: 'TALAYAN',
    Rate: '195',
    Remarks: 'PICK-UP AT COTABATO STATION',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'MAGUINDANAO',
    Location: 'TALITAY',
    Rate: '195',
    Remarks: 'PICK-UP AT COTABATO STATION',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'MAGUINDANAO',
    Location: 'UPI',
    Rate: '195',
    Remarks: 'PICK-UP AT COTABATO STATION',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'MISAMIS OCCIDENTAL ',
    Location: 'ALORAN',
    Rate: '145',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'MISAMIS OCCIDENTAL ',
    Location: 'BALIANGAO',
    Rate: '145',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'MISAMIS OCCIDENTAL ',
    Location: 'BONIFACIO',
    Rate: '145',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'MISAMIS OCCIDENTAL ',
    Location: 'CALAMBA',
    Rate: '145',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'MISAMIS OCCIDENTAL ',
    Location: 'CLARIN',
    Rate: '145',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'MISAMIS OCCIDENTAL ',
    Location: 'CONCEPCION',
    Rate: '145',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'MISAMIS OCCIDENTAL ',
    Location: 'DON VICTORIANO',
    Rate: '145',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'MISAMIS OCCIDENTAL ',
    Location: 'JIMENEZ',
    Rate: '145',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'MISAMIS OCCIDENTAL ',
    Location: 'LOPEZ JAENA',
    Rate: '145',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'MISAMIS OCCIDENTAL ',
    Location: 'OROQUIETA CITY',
    Rate: '145',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'MISAMIS OCCIDENTAL ',
    Location: 'OZAMIS CITY',
    Rate: '145',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'MISAMIS OCCIDENTAL ',
    Location: 'PANAON',
    Rate: '145',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'MISAMIS OCCIDENTAL ',
    Location: 'PLARIDEL',
    Rate: '145',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'MISAMIS OCCIDENTAL ',
    Location: 'SAPANG DALAGA',
    Rate: '145',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'MISAMIS OCCIDENTAL ',
    Location: 'SINACABAN',
    Rate: '145',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'MISAMIS OCCIDENTAL ',
    Location: 'TANGUB CITY',
    Rate: '145',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'MISAMIS OCCIDENTAL ',
    Location: 'TUDELA',
    Rate: '145',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'MISAMIS ORIENTAL',
    Location: 'ALUBIJID',
    Rate: '145',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'MISAMIS ORIENTAL',
    Location: 'BALINGASAG',
    Rate: '145',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'MISAMIS ORIENTAL',
    Location: 'BALINGOAN',
    Rate: '145',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'MISAMIS ORIENTAL',
    Location: 'BINUANGAN',
    Rate: '145',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'MISAMIS ORIENTAL',
    Location: 'CAGAYAN DE ORO CITY',
    Rate: '145',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'MISAMIS ORIENTAL',
    Location: 'CLAVERIA',
    Rate: '145',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'MISAMIS ORIENTAL',
    Location: 'EL SALVADOR',
    Rate: '145',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'MISAMIS ORIENTAL',
    Location: 'GINGOOG CITY',
    Rate: '195',
    Remarks: 'WITH TRANSSHIPMENT CHARGE',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'MISAMIS ORIENTAL',
    Location: 'GITAGUM',
    Rate: '145',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'MISAMIS ORIENTAL',
    Location: 'INITAO',
    Rate: '145',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'MISAMIS ORIENTAL',
    Location: 'JASAAN',
    Rate: '145',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'MISAMIS ORIENTAL',
    Location: 'KINOGUITAN',
    Rate: '145',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'MISAMIS ORIENTAL',
    Location: 'LAGONGLONG',
    Rate: '145',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'MISAMIS ORIENTAL',
    Location: 'LAGUINDINGAN',
    Rate: '145',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'MISAMIS ORIENTAL',
    Location: 'LIBERTAD',
    Rate: '145',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'MISAMIS ORIENTAL',
    Location: 'LUGAIT',
    Rate: '145',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'MISAMIS ORIENTAL',
    Location: 'MAGSAYSAY',
    Rate: '195',
    Remarks: 'WITH TRANSSHIPMENT CHARGE',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'MISAMIS ORIENTAL',
    Location: 'MANTICAO',
    Rate: '145',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'MISAMIS ORIENTAL',
    Location: 'MEDINA',
    Rate: '195',
    Remarks: 'WITH TRANSSHIPMENT CHARGE',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'MISAMIS ORIENTAL',
    Location: 'NAAWAN',
    Rate: '145',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'MISAMIS ORIENTAL',
    Location: 'OPOL',
    Rate: '145',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'MISAMIS ORIENTAL',
    Location: 'SALAY',
    Rate: '145',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'MISAMIS ORIENTAL',
    Location: 'SUGBONGCOGON',
    Rate: '145',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'MISAMIS ORIENTAL',
    Location: 'TAGOLOAN',
    Rate: '145',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'MISAMIS ORIENTAL',
    Location: 'TALISAYAN',
    Rate: '145',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'MISAMIS ORIENTAL',
    Location: 'VILLANUEVA',
    Rate: '145',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'SARANGANI PROVICE',
    Location: 'ALABEL',
    Rate: '195',
    Remarks: 'WITH TRANSSHIPMENT CHARGE',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'SARANGANI PROVICE',
    Location: 'GLAN',
    Rate: '195',
    Remarks: 'WITH TRANSSHIPMENT CHARGE',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'SARANGANI PROVICE',
    Location: 'KIAMBA',
    Rate: '195',
    Remarks: 'WITH TRANSSHIPMENT CHARGE',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'SARANGANI PROVICE',
    Location: 'MAASIM',
    Rate: '195',
    Remarks: 'WITH TRANSSHIPMENT CHARGE',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'SARANGANI PROVICE',
    Location: 'MAITUM',
    Rate: '195',
    Remarks: 'WITH TRANSSHIPMENT CHARGE',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'SARANGANI PROVICE',
    Location: 'MALAPATAN',
    Rate: '195',
    Remarks: 'WITH TRANSSHIPMENT CHARGE',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'SARANGANI PROVICE',
    Location: 'MALUNGON',
    Rate: '195',
    Remarks: 'WITH TRANSSHIPMENT CHARGE',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'SULTAN KUDARAT',
    Location: 'BAGUMBAYAN',
    Rate: '195',
    Remarks: 'WITH TRANSSHIPMENT CHARGE',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'SULTAN KUDARAT',
    Location: 'COLUMBIO',
    Rate: '195',
    Remarks: 'WITH TRANSSHIPMENT CHARGE',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'SULTAN KUDARAT',
    Location: 'ESPERANZA',
    Rate: '195',
    Remarks: 'WITH TRANSSHIPMENT CHARGE',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'SULTAN KUDARAT',
    Location: 'ISULAN',
    Rate: '195',
    Remarks: 'WITH TRANSSHIPMENT CHARGE',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'SULTAN KUDARAT',
    Location: 'KALAMANSIG',
    Rate: '195',
    Remarks: 'WITH TRANSSHIPMENT CHARGE',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'SULTAN KUDARAT',
    Location: 'LAMBAYONG',
    Rate: '195',
    Remarks: 'WITH TRANSSHIPMENT CHARGE',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'SULTAN KUDARAT',
    Location: 'LEBAK ',
    Rate: '195',
    Remarks: 'WITH TRANSSHIPMENT CHARGE',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'SULTAN KUDARAT',
    Location: 'LUTAYAN',
    Rate: '195',
    Remarks: 'WITH TRANSSHIPMENT CHARGE',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'SULTAN KUDARAT',
    Location: 'PALIMBANG ',
    Rate: '195',
    Remarks: 'WITH TRANSSHIPMENT CHARGE',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'SULTAN KUDARAT',
    Location: 'PRESIDENT QUIRINO',
    Rate: '195',
    Remarks: 'WITH TRANSSHIPMENT CHARGE',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'SULTAN KUDARAT',
    Location: 'SEN. NINOY AQUINO',
    Rate: '195',
    Remarks: 'WITH TRANSSHIPMENT CHARGE',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'SULTAN KUDARAT',
    Location: 'TACURONG',
    Rate: '195',
    Remarks: 'WITH TRANSSHIPMENT CHARGE',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'SULU',
    Location: 'HADJI PANGLIMA TAHIL (MARUNGGAS)',
    Rate: '245',
    Remarks: 'PICK-UP AT ZAMBOANGA STATION (MEET UP ONLY)',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'SULU',
    Location: 'INDANAN',
    Rate: '245',
    Remarks: 'PICK-UP AT ZAMBOANGA STATION (MEET UP ONLY)',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'SULU',
    Location: 'JOLO',
    Rate: '245',
    Remarks: 'PICK-UP AT ZAMBOANGA STATION (MEET UP ONLY)',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'SULU',
    Location: 'KALINGALAN CALUANG',
    Rate: '245',
    Remarks: 'PICK-UP AT ZAMBOANGA STATION (MEET UP ONLY)',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'SULU',
    Location: 'LUGUS',
    Rate: '245',
    Remarks: 'PICK-UP AT ZAMBOANGA STATION (MEET UP ONLY)',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'SULU',
    Location: 'LUUK',
    Rate: '245',
    Remarks: 'PICK-UP AT ZAMBOANGA STATION (MEET UP ONLY)',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'SULU',
    Location: 'MAIMBUNG',
    Rate: '245',
    Remarks: 'PICK-UP AT ZAMBOANGA STATION (MEET UP ONLY)',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'SULU',
    Location: 'OLD PANAMAO',
    Rate: '245',
    Remarks: 'PICK-UP AT ZAMBOANGA STATION (MEET UP ONLY)',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'SULU',
    Location: 'OMAR',
    Rate: '245',
    Remarks: 'PICK-UP AT ZAMBOANGA STATION (MEET UP ONLY)',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'SULU',
    Location: 'PANDAMI',
    Rate: '245',
    Remarks: 'PICK-UP AT ZAMBOANGA STATION (MEET UP ONLY)',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'SULU',
    Location: 'PANGLIMA ESTINO (NEW PANAMAO)',
    Rate: '245',
    Remarks: 'PICK-UP AT ZAMBOANGA STATION (MEET UP ONLY)',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'SULU',
    Location: 'PANGUTARAN',
    Rate: '245',
    Remarks: 'PICK-UP AT ZAMBOANGA STATION (MEET UP ONLY)',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'SULU',
    Location: 'PARANG',
    Rate: '245',
    Remarks: 'PICK-UP AT ZAMBOANGA STATION (MEET UP ONLY)',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'SULU',
    Location: 'PATA',
    Rate: '245',
    Remarks: 'PICK-UP AT ZAMBOANGA STATION (MEET UP ONLY)',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'SULU',
    Location: 'PATIKUL',
    Rate: '245',
    Remarks: 'PICK-UP AT ZAMBOANGA STATION (MEET UP ONLY)',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'SULU',
    Location: 'SIASI',
    Rate: '245',
    Remarks: 'PICK-UP AT ZAMBOANGA STATION (MEET UP ONLY)',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'SULU',
    Location: 'TALIPAO',
    Rate: '245',
    Remarks: 'PICK-UP AT ZAMBOANGA STATION (MEET UP ONLY)',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'SULU',
    Location: 'TAPUL',
    Rate: '245',
    Remarks: 'PICK-UP AT ZAMBOANGA STATION (MEET UP ONLY)',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'SULU',
    Location: 'TONGKIL',
    Rate: '245',
    Remarks: 'PICK-UP AT ZAMBOANGA STATION (MEET UP ONLY)',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'SURIGAO DEL NORTE',
    Location: 'ALEGRIA',
    Rate: '145',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'SURIGAO DEL NORTE',
    Location: 'BACUAG',
    Rate: '145',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'SURIGAO DEL NORTE',
    Location: 'BURGOS',
    Rate: '145',
    Remarks: 'PICK-UP AT SURIGAO STATION',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'SURIGAO DEL NORTE',
    Location: 'CLAVERIA',
    Rate: '145',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'SURIGAO DEL NORTE',
    Location: 'DAPA',
    Rate: '145',
    Remarks: 'PICK-UP AT SURIGAO STATION',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'SURIGAO DEL NORTE',
    Location: 'DEL CARMEN',
    Rate: '145',
    Remarks: 'PICK-UP AT SURIGAO STATION',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'SURIGAO DEL NORTE',
    Location: 'GEN. LUNA',
    Rate: '145',
    Remarks: 'PICK-UP AT SURIGAO STATION',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'SURIGAO DEL NORTE',
    Location: 'GIGAQUIT',
    Rate: '145',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'SURIGAO DEL NORTE',
    Location: 'MAINIT',
    Rate: '145',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'SURIGAO DEL NORTE',
    Location: 'MALIMONO',
    Rate: '145',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'SURIGAO DEL NORTE',
    Location: 'PILAR',
    Rate: '145',
    Remarks: 'PICK-UP AT SURIGAO STATION',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'SURIGAO DEL NORTE',
    Location: 'PLACER',
    Rate: '195',
    Remarks: 'WITH TRANSSHIPMENT CHARGE',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'SURIGAO DEL NORTE',
    Location: 'SAN BENITO',
    Rate: '145',
    Remarks: 'PICK-UP AT SURIGAO STATION',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'SURIGAO DEL NORTE',
    Location: 'SAN FRANCISCO (ANAO-AOAN)',
    Rate: '145',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'SURIGAO DEL NORTE',
    Location: 'SAN ISIDRO',
    Rate: '145',
    Remarks: 'PICK-UP AT SURIGAO STATION',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'SURIGAO DEL NORTE',
    Location: 'SIARGAO ISLAND',
    Rate: '295',
    Remarks: 'ISLAND TRANSFER (MEET UP NEAREST PORT)',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'SURIGAO DEL NORTE',
    Location: 'SISON',
    Rate: '145',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'SURIGAO DEL NORTE',
    Location: 'SOCORRO',
    Rate: '145',
    Remarks: 'PICK-UP AT SURIGAO STATION',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'SURIGAO DEL NORTE',
    Location: 'STA MONICA',
    Rate: '145',
    Remarks: 'PICK-UP AT SURIGAO STATION',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'SURIGAO DEL NORTE',
    Location: 'SURIGAO CITY',
    Rate: '145',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'SURIGAO DEL NORTE',
    Location: 'TAGANA-AN',
    Rate: '195',
    Remarks: 'WITH TRANSSHIPMENT CHARGE',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'SURIGAO DEL NORTE',
    Location: 'TUBOD',
    Rate: '145',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'SURIGAO DEL SUR',
    Location: 'BAROBO',
    Rate: '145',
    Remarks: 'WITH DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'SURIGAO DEL SUR',
    Location: 'BAYABAS',
    Rate: '145',
    Remarks: 'WITH DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'SURIGAO DEL SUR',
    Location: 'BISLIG',
    Rate: '145',
    Remarks: 'WITH DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'SURIGAO DEL SUR',
    Location: 'CAGWAIT',
    Rate: '145',
    Remarks: 'WITH DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'SURIGAO DEL SUR',
    Location: 'CANTILAN',
    Rate: '145',
    Remarks: 'WITH DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'SURIGAO DEL SUR',
    Location: 'CARMEN',
    Rate: '145',
    Remarks: 'WITH DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'SURIGAO DEL SUR',
    Location: 'CARRASCAL',
    Rate: '145',
    Remarks: 'WITH DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'SURIGAO DEL SUR',
    Location: 'CORTES',
    Rate: '145',
    Remarks: 'WITH DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'SURIGAO DEL SUR',
    Location: 'HINATUAN',
    Rate: '145',
    Remarks: 'WITH DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'SURIGAO DEL SUR',
    Location: 'LANUZA',
    Rate: '145',
    Remarks: 'WITH DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'SURIGAO DEL SUR',
    Location: 'LIANGA',
    Rate: '145',
    Remarks: 'WITH DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'SURIGAO DEL SUR',
    Location: 'LINGIG',
    Rate: '145',
    Remarks: 'WITH DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'SURIGAO DEL SUR',
    Location: 'MADRID',
    Rate: '145',
    Remarks: 'WITH DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'SURIGAO DEL SUR',
    Location: 'MARIHATAG',
    Rate: '145',
    Remarks: 'WITH DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'SURIGAO DEL SUR',
    Location: 'SAN AGUSTIN',
    Rate: '145',
    Remarks: 'WITH DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'SURIGAO DEL SUR',
    Location: 'SAN MIGUEL',
    Rate: '145',
    Remarks: 'WITH DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'SURIGAO DEL SUR',
    Location: 'TAGBINA',
    Rate: '145',
    Remarks: 'WITH DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'SURIGAO DEL SUR',
    Location: 'TAGO',
    Rate: '145',
    Remarks: 'WITH DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'SURIGAO DEL SUR',
    Location: 'TANDAG',
    Rate: '145',
    Remarks: 'WITH DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'TAWI-TAWI',
    Location: 'ANGUYAN',
    Rate: '245',
    Remarks: 'PICK-UP AT ZAMBOANGA STATION (MEET UP ONLY)',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'TAWI-TAWI',
    Location: 'BONGAO',
    Rate: '245',
    Remarks: 'PICK-UP AT ZAMBOANGA STATION (MEET UP ONLY)',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'TAWI-TAWI',
    Location: 'MAPUN',
    Rate: '245',
    Remarks: 'PICK-UP AT ZAMBOANGA STATION (MEET UP ONLY)',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'TAWI-TAWI',
    Location: 'PANGLIMA SUGALA',
    Rate: '245',
    Remarks: 'PICK-UP AT ZAMBOANGA STATION (MEET UP ONLY)',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'TAWI-TAWI',
    Location: 'SAPA-SAPA',
    Rate: '245',
    Remarks: 'PICK-UP AT ZAMBOANGA STATION (MEET UP ONLY)',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'TAWI-TAWI',
    Location: 'SIBUTU',
    Rate: '245',
    Remarks: 'PICK-UP AT ZAMBOANGA STATION (MEET UP ONLY)',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'TAWI-TAWI',
    Location: 'SIMUNUL',
    Rate: '245',
    Remarks: 'PICK-UP AT ZAMBOANGA STATION (MEET UP ONLY)',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'TAWI-TAWI',
    Location: 'SITANGKAI',
    Rate: '245',
    Remarks: 'PICK-UP AT ZAMBOANGA STATION (MEET UP ONLY)',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'TAWI-TAWI',
    Location: 'SOUTH UBIAN',
    Rate: '245',
    Remarks: 'PICK-UP AT ZAMBOANGA STATION (MEET UP ONLY)',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'TAWI-TAWI',
    Location: 'TANDUBAS',
    Rate: '245',
    Remarks: 'PICK-UP AT ZAMBOANGA STATION (MEET UP ONLY)',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'TAWI-TAWI',
    Location: 'TURTLE ISLANDS',
    Rate: '245',
    Remarks: 'PICK-UP AT ZAMBOANGA STATION (MEET UP ONLY)',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'ZAMBOANGA DEL NORTE',
    Location: 'BACUNGAN (LEON T. POSTIGO)',
    Rate: '145',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'ZAMBOANGA DEL NORTE',
    Location: 'BALIGUIAN',
    Rate: '145',
    Remarks: 'PICK-UP AT DIPOLOG STATION',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'ZAMBOANGA DEL NORTE',
    Location: 'DAPITAN CITY',
    Rate: '145',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'ZAMBOANGA DEL NORTE',
    Location: 'DIPOLOG CITY',
    Rate: '145',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'ZAMBOANGA DEL NORTE',
    Location: 'GODOD',
    Rate: '145',
    Remarks: 'PICK-UP AT DIPOLOG STATION',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'ZAMBOANGA DEL NORTE',
    Location: 'GUTALAC',
    Rate: '145',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'ZAMBOANGA DEL NORTE',
    Location: 'JOSE DALMAN (PONOT)',
    Rate: '145',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'ZAMBOANGA DEL NORTE',
    Location: 'KALAWIT',
    Rate: '145',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'ZAMBOANGA DEL NORTE',
    Location: 'KATIPUNAN',
    Rate: '145',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'ZAMBOANGA DEL NORTE',
    Location: 'LA LIBERTAD',
    Rate: '145',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'ZAMBOANGA DEL NORTE',
    Location: 'LABASON',
    Rate: '145',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'ZAMBOANGA DEL NORTE',
    Location: 'LILOY',
    Rate: '145',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'ZAMBOANGA DEL NORTE',
    Location: 'MANUKAN',
    Rate: '145',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'ZAMBOANGA DEL NORTE',
    Location: 'MUTIA',
    Rate: '145',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'ZAMBOANGA DEL NORTE',
    Location: 'PINAN (NEW PINAN)',
    Rate: '145',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'ZAMBOANGA DEL NORTE',
    Location: 'POLANCO',
    Rate: '145',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'ZAMBOANGA DEL NORTE',
    Location: 'PRES. MANUEL A ROXAS',
    Rate: '145',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'ZAMBOANGA DEL NORTE',
    Location: 'RIZAL',
    Rate: '145',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'ZAMBOANGA DEL NORTE',
    Location: 'SALUG',
    Rate: '145',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'ZAMBOANGA DEL NORTE',
    Location: 'SERGIO OSMENA, SR.',
    Rate: '145',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'ZAMBOANGA DEL NORTE',
    Location: 'SIAYAN',
    Rate: '145',
    Remarks: 'PICK-UP AT DIPOLOG STATION',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'ZAMBOANGA DEL NORTE',
    Location: 'SIBUCO',
    Rate: '145',
    Remarks: 'PICK-UP AT DIPOLOG STATION',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'ZAMBOANGA DEL NORTE',
    Location: 'SIBUTAD',
    Rate: '145',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'ZAMBOANGA DEL NORTE',
    Location: 'SINDANGAN',
    Rate: '195',
    Remarks: 'WITH TRANSSHIPMENT CHARGE',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'ZAMBOANGA DEL NORTE',
    Location: 'SIOCON',
    Rate: '145',
    Remarks: 'PICK-UP AT DIPOLOG STATION',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'ZAMBOANGA DEL NORTE',
    Location: 'SIRAWAI',
    Rate: '145',
    Remarks: 'PICK-UP AT DIPOLOG STATION',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'ZAMBOANGA DEL NORTE',
    Location: 'TAMPILISAN',
    Rate: '145',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'ZAMBOANGA DEL SUR',
    Location: 'AURORA',
    Rate: '145',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'ZAMBOANGA DEL SUR',
    Location: 'BAYOG',
    Rate: '145',
    Remarks: 'PICK-UP AT PAGADIAN STATION',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'ZAMBOANGA DEL SUR',
    Location: 'DIMATALING  ',
    Rate: '145',
    Remarks: 'PICK-UP AT PAGADIAN STATION',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'ZAMBOANGA DEL SUR',
    Location: 'DINAS',
    Rate: '145',
    Remarks: 'PICK-UP AT PAGADIAN STATION',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'ZAMBOANGA DEL SUR',
    Location: 'DUMALINAO',
    Rate: '145',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'ZAMBOANGA DEL SUR',
    Location: 'DUMINGAG',
    Rate: '145',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'ZAMBOANGA DEL SUR',
    Location: 'GUIPOS',
    Rate: '145',
    Remarks: 'PICK-UP AT PAGADIAN STATION',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'ZAMBOANGA DEL SUR',
    Location: 'JOSEFINA',
    Rate: '145',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'ZAMBOANGA DEL SUR',
    Location: 'KUMALARANG',
    Rate: '145',
    Remarks: 'PICK-UP AT PAGADIAN STATION',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'ZAMBOANGA DEL SUR',
    Location: 'LABANGAN',
    Rate: '145',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'ZAMBOANGA DEL SUR',
    Location: 'LAKEWOOD',
    Rate: '145',
    Remarks: 'PICK-UP AT PAGADIAN STATION',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'ZAMBOANGA DEL SUR',
    Location: 'LAPUYAN',
    Rate: '145',
    Remarks: 'PICK-UP AT PAGADIAN STATION',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'ZAMBOANGA DEL SUR',
    Location: 'MAHAYAG',
    Rate: '145',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'ZAMBOANGA DEL SUR',
    Location: 'MARGOSATUBIG',
    Rate: '145',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'ZAMBOANGA DEL SUR',
    Location: 'MIDSALIP',
    Rate: '145',
    Remarks: 'PICK-UP AT PAGADIAN STATION',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'ZAMBOANGA DEL SUR',
    Location: 'MOLAVE',
    Rate: '145',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'ZAMBOANGA DEL SUR',
    Location: 'PAGADIAN CITY',
    Rate: '145',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'ZAMBOANGA DEL SUR',
    Location: 'PITOGO',
    Rate: '145',
    Remarks: 'PICK-UP AT PAGADIAN STATION',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'ZAMBOANGA DEL SUR',
    Location: 'RAMON MAGSAYSAY',
    Rate: '145',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'ZAMBOANGA DEL SUR',
    Location: 'SAN MIGUEL',
    Rate: '145',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'ZAMBOANGA DEL SUR',
    Location: 'SAN PABLO',
    Rate: '145',
    Remarks: 'PICK-UP AT PAGADIAN STATION',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'ZAMBOANGA DEL SUR',
    Location: 'SOMINOT (DON MARIANO)',
    Rate: '145',
    Remarks: 'PICK-UP AT PAGADIAN STATION',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'ZAMBOANGA DEL SUR',
    Location: 'TABINA',
    Rate: '145',
    Remarks: 'PICK-UP AT PAGADIAN STATION',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'ZAMBOANGA DEL SUR',
    Location: 'TAMBULIG',
    Rate: '145',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'ZAMBOANGA DEL SUR',
    Location: 'TIGBAO',
    Rate: '145',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'ZAMBOANGA DEL SUR',
    Location: 'TUKURAN',
    Rate: '145',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'ZAMBOANGA DEL SUR',
    Location: 'VINCENZO A. SAGUN',
    Rate: '145',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'ZAMBOANGA DEL SUR',
    Location: 'ZAMBOANGA CITY',
    Rate: '145',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'ZAMBOANGA SIBUGAY',
    Location: 'ALICIA',
    Rate: '145',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'ZAMBOANGA SIBUGAY',
    Location: 'BUUG',
    Rate: '145',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'ZAMBOANGA SIBUGAY',
    Location: 'DIPLAHAN',
    Rate: '145',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'ZAMBOANGA SIBUGAY',
    Location: 'IMELDA',
    Rate: '145',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'ZAMBOANGA SIBUGAY',
    Location: 'IPIL',
    Rate: '145',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'ZAMBOANGA SIBUGAY',
    Location: 'KABASALAN',
    Rate: '145',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'ZAMBOANGA SIBUGAY',
    Location: 'MABUHAY',
    Rate: '145',
    Remarks: 'PICK-UP AT ZAMBOANGA STATION',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'ZAMBOANGA SIBUGAY',
    Location: 'MALANGAS',
    Rate: '145',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'ZAMBOANGA SIBUGAY',
    Location: 'NAGA',
    Rate: '145',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'ZAMBOANGA SIBUGAY',
    Location: 'OLUTANGA',
    Rate: '145',
    Remarks: 'PICK-UP AT ZAMBOANGA STATION',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'ZAMBOANGA SIBUGAY',
    Location: 'PAYAO',
    Rate: '145',
    Remarks: 'PICK-UP AT ZAMBOANGA STATION',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'ZAMBOANGA SIBUGAY',
    Location: 'ROSELLER T. LIM',
    Rate: '145',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'ZAMBOANGA SIBUGAY',
    Location: 'SIAYAN',
    Rate: '145',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'ZAMBOANGA SIBUGAY',
    Location: 'TALUSAN',
    Rate: '145',
    Remarks: 'PICK-UP AT ZAMBOANGA STATION',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'ZAMBOANGA SIBUGAY',
    Location: 'TITAY',
    Rate: '145',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'MIN',
    Island: 'MINDANAO',
    Freight: 'SEA',
    Province: 'ZAMBOANGA SIBUGAY',
    Location: 'TUNGAWAN',
    Rate: '145',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'AKLAN',
    Location: 'ALTAVAS',
    Rate: '135',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'AKLAN',
    Location: 'BALETE',
    Rate: '135',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'AKLAN',
    Location: 'BANGA',
    Rate: '135',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'AKLAN',
    Location: 'BATAN',
    Rate: '135',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'AKLAN',
    Location: 'BURUANGA',
    Rate: '235',
    Remarks: '1 ON 1 DELIVERY',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'AKLAN',
    Location: 'IBAJAY',
    Rate: '135',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'AKLAN',
    Location: 'KALIBO',
    Rate: '135',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'AKLAN',
    Location: 'LEZO',
    Rate: '135',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'AKLAN',
    Location: 'LIBACAO',
    Rate: '135',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'AKLAN',
    Location: 'MADALAG',
    Rate: '235',
    Remarks: '1 ON 1 DELIVERY',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'AKLAN',
    Location: 'MAKATO',
    Rate: '135',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'AKLAN',
    Location: 'MALAY ',
    Rate: '135',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'AKLAN',
    Location: 'MALINAO',
    Rate: '135',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'AKLAN',
    Location: 'NABAS',
    Rate: '135',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'AKLAN',
    Location: 'NEW WASHINGTON',
    Rate: '135',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'AKLAN',
    Location: 'NUMANCIA',
    Rate: '135',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'AKLAN',
    Location: 'TANGALAN',
    Rate: '135',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'ANTIQUE ',
    Location: 'ANINI-Y',
    Rate: '135',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'ANTIQUE ',
    Location: 'BARBAZA',
    Rate: '135',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'ANTIQUE ',
    Location: 'BELISON',
    Rate: '135',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'ANTIQUE ',
    Location: 'BUGASONG',
    Rate: '135',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'ANTIQUE ',
    Location: 'CALUYA ISLAND',
    Rate: '235',
    Remarks: '1 ON 1 DELIVERY',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'ANTIQUE ',
    Location: 'CULASI',
    Rate: '135',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'ANTIQUE ',
    Location: 'HAMTIC',
    Rate: '135',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'ANTIQUE ',
    Location: 'LAUA-AN',
    Rate: '135',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'ANTIQUE ',
    Location: 'LIBERTAD',
    Rate: '235',
    Remarks: '1 ON 1 DELIVERY',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'ANTIQUE ',
    Location: 'PANDAN',
    Rate: '135',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'ANTIQUE ',
    Location: 'PATNONGON',
    Rate: '135',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'ANTIQUE ',
    Location: 'SAN JOSE DE BUENAVISTA',
    Rate: '135',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'ANTIQUE ',
    Location: 'SAN REMIGIO',
    Rate: '235',
    Remarks: '1 ON 1 DELIVERY',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'ANTIQUE ',
    Location: 'SEBASTE',
    Rate: '185',
    Remarks: 'WITH TRANSSHIPMENT CHARGE',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'ANTIQUE ',
    Location: 'SIBALOM',
    Rate: '135',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'ANTIQUE ',
    Location: 'TIBIAO',
    Rate: '135',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'ANTIQUE ',
    Location: 'TOBIAS FORNIER (DAO)',
    Rate: '135',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'ANTIQUE ',
    Location: 'VALDERRAMA',
    Rate: '135',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'BILIRAN',
    Location: 'ALMERIA',
    Rate: '185',
    Remarks: 'WITH TRANSSHIPMENT CHARGE',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'BILIRAN',
    Location: 'BILIRAN',
    Rate: '185',
    Remarks: 'WITH TRANSSHIPMENT CHARGE',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'BILIRAN',
    Location: 'CABUGCAYAN',
    Rate: '185',
    Remarks: 'WITH TRANSSHIPMENT CHARGE',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'BILIRAN',
    Location: 'CAIBIRAN',
    Rate: '185',
    Remarks: 'WITH TRANSSHIPMENT CHARGE',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'BILIRAN',
    Location: 'CULABA',
    Rate: '185',
    Remarks: 'WITH TRANSSHIPMENT CHARGE',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'BILIRAN',
    Location: 'KAWAYAN',
    Rate: '185',
    Remarks: 'WITH TRANSSHIPMENT CHARGE',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'BILIRAN',
    Location: 'MARIPIPI',
    Rate: '235',
    Remarks: '1 ON 1 DELIVERY',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'BILIRAN',
    Location: 'NAVAL',
    Rate: '185',
    Remarks: 'WITH TRANSSHIPMENT CHARGE',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'BOHOL ',
    Location: 'ALBURQUERQUE',
    Rate: '135',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'BOHOL ',
    Location: 'ALICIA',
    Rate: '185',
    Remarks: 'WITH TRANSSHIPMENT CHARGE',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'BOHOL ',
    Location: 'ANDA',
    Rate: '235',
    Remarks: '1 ON 1 DELIVERY',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'BOHOL ',
    Location: 'ANTEQUERA',
    Rate: '135',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'BOHOL ',
    Location: 'BACLAYON',
    Rate: '135',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'BOHOL ',
    Location: 'BALILIHAN',
    Rate: '135',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'BOHOL ',
    Location: 'BATUAN',
    Rate: '135',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'BOHOL ',
    Location: 'BILAR',
    Rate: '135',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'BOHOL ',
    Location: 'BUENAVISTA',
    Rate: '135',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'BOHOL ',
    Location: 'CALAPE',
    Rate: '135',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'BOHOL ',
    Location: 'CANDIJAY',
    Rate: '135',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'BOHOL ',
    Location: 'CARMEN',
    Rate: '135',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'BOHOL ',
    Location: 'CATIGBIAN',
    Rate: '135',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'BOHOL ',
    Location: 'CLARIN',
    Rate: '135',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'BOHOL ',
    Location: 'CORELLA',
    Rate: '135',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'BOHOL ',
    Location: 'CORTES',
    Rate: '135',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'BOHOL ',
    Location: 'DAGOHOY',
    Rate: '135',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'BOHOL ',
    Location: 'DANAO',
    Rate: '135',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'BOHOL ',
    Location: 'DAUIS',
    Rate: '135',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'BOHOL ',
    Location: 'DIMIAO',
    Rate: '135',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'BOHOL ',
    Location: 'DUERO',
    Rate: '135',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'BOHOL ',
    Location: 'GARCIA HERNANDEZ',
    Rate: '135',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'BOHOL ',
    Location: 'GUINDULMAN',
    Rate: '135',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'BOHOL ',
    Location: 'INABANGA',
    Rate: '135',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'BOHOL ',
    Location: 'JAGNA',
    Rate: '135',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'BOHOL ',
    Location: 'JANOPOL',
    Rate: '135',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'BOHOL ',
    Location: 'JETAFE',
    Rate: '135',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'BOHOL ',
    Location: 'LILA',
    Rate: '135',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'BOHOL ',
    Location: 'LOAY',
    Rate: '135',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'BOHOL ',
    Location: 'LOBOC',
    Rate: '135',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'BOHOL ',
    Location: 'LOON',
    Rate: '135',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'BOHOL ',
    Location: 'MABINI',
    Rate: '235',
    Remarks: '1 ON 1 DELIVERY',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'BOHOL ',
    Location: 'MARIBOJOC',
    Rate: '135',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'BOHOL ',
    Location: 'PANGLAO',
    Rate: '135',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'BOHOL ',
    Location: 'PILAR',
    Rate: '135',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'BOHOL ',
    Location: 'PRES. CARLOS P. GARCIA IS.',
    Rate: '235',
    Remarks: '1 ON 1 DELIVERY',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'BOHOL ',
    Location: 'SAGBAYAN',
    Rate: '135',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'BOHOL ',
    Location: 'SAN ISIDRO',
    Rate: '135',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'BOHOL ',
    Location: 'SAN MIGUEL',
    Rate: '135',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'BOHOL ',
    Location: 'SEVILLA',
    Rate: '135',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'BOHOL ',
    Location: 'SIERRA BULLONES',
    Rate: '135',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'BOHOL ',
    Location: 'SIKATUNA',
    Rate: '135',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'BOHOL ',
    Location: 'TAGBILARAN CITY',
    Rate: '135',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'BOHOL ',
    Location: 'TALIBON',
    Rate: '135',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'BOHOL ',
    Location: 'TRINIDAD',
    Rate: '135',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'BOHOL ',
    Location: 'TUBIGON',
    Rate: '135',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'BOHOL ',
    Location: 'UBAY',
    Rate: '185',
    Remarks: 'WITH TRANSSHIPMENT CHARGE',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'BOHOL ',
    Location: 'VALENCIA',
    Rate: '135',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'CAPIZ',
    Location: 'CUARTERO',
    Rate: '135',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'CAPIZ',
    Location: 'DAO',
    Rate: '135',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'CAPIZ',
    Location: 'DUMALAG',
    Rate: '135',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'CAPIZ',
    Location: 'DUMARAO',
    Rate: '135',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'CAPIZ',
    Location: 'IVISAN',
    Rate: '135',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'CAPIZ',
    Location: 'JAMINDAN',
    Rate: '135',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'CAPIZ',
    Location: 'MA-AYON',
    Rate: '135',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'CAPIZ',
    Location: 'MAMBUSAO',
    Rate: '135',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'CAPIZ',
    Location: 'PANAY',
    Rate: '135',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'CAPIZ',
    Location: 'PANIT-AN',
    Rate: '135',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'CAPIZ',
    Location: 'PILAR',
    Rate: '135',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'CAPIZ',
    Location: 'PONTEVEDRA',
    Rate: '135',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'CAPIZ',
    Location: 'PRESIDENT ROXAS',
    Rate: '135',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'CAPIZ',
    Location: 'ROXAS CITY',
    Rate: '135',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'CAPIZ',
    Location: 'SAPI-AN',
    Rate: '135',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'CAPIZ',
    Location: 'SIGMA',
    Rate: '135',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'CAPIZ',
    Location: 'TAPAZ',
    Rate: '135',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'CEBU',
    Location: 'ALCANTARA',
    Rate: '135',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'CEBU',
    Location: 'ALCOY',
    Rate: '135',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'CEBU',
    Location: 'ALEGRIA',
    Rate: '135',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'CEBU',
    Location: 'ALOGUINSAN',
    Rate: '135',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'CEBU',
    Location: 'ARGAO',
    Rate: '135',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'CEBU',
    Location: 'ASTURIAS',
    Rate: '135',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'CEBU',
    Location: 'BADIAN',
    Rate: '135',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'CEBU',
    Location: 'BALAMBAN',
    Rate: '135',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'CEBU',
    Location: 'BANTAYAN IS.',
    Rate: '235',
    Remarks: '1 ON 1 DELIVERY',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'CEBU',
    Location: 'BARILI',
    Rate: '135',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'CEBU',
    Location: 'BOGO',
    Rate: '135',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'CEBU',
    Location: 'BOLJOON',
    Rate: '135',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'CEBU',
    Location: 'BORBON',
    Rate: '135',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'CEBU',
    Location: 'CARCAR',
    Rate: '135',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'CEBU',
    Location: 'CARMEN',
    Rate: '135',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'CEBU',
    Location: 'CATMON',
    Rate: '135',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'CEBU',
    Location: 'CEBU CITY',
    Rate: '135',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'CEBU',
    Location: 'COMPOSTELA',
    Rate: '135',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'CEBU',
    Location: 'CONSOLACION',
    Rate: '135',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'CEBU',
    Location: 'CORDOVA',
    Rate: '135',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'CEBU',
    Location: 'DAAN BANTAYAN',
    Rate: '135',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'CEBU',
    Location: 'DALAGUETE',
    Rate: '135',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'CEBU',
    Location: 'DANAO CTY',
    Rate: '135',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'CEBU',
    Location: 'DUMANJUG',
    Rate: '135',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'CEBU',
    Location: 'GINATILAN',
    Rate: '135',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'CEBU',
    Location: 'LAPU-LAPU CITY',
    Rate: '135',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'CEBU',
    Location: 'LILOAN',
    Rate: '135',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'CEBU',
    Location: 'MADRIDEJOS, BANTAYAN IS.',
    Rate: '235',
    Remarks: '1 ON 1 DELIVERY',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'CEBU',
    Location: 'MALABUYOC',
    Rate: '135',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'CEBU',
    Location: 'MANDAUE CITY',
    Rate: '135',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'CEBU',
    Location: 'MEDELLIN',
    Rate: '135',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'CEBU',
    Location: 'MINGLANILLA',
    Rate: '135',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'CEBU',
    Location: 'MOALBOAL',
    Rate: '135',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'CEBU',
    Location: 'NAGA',
    Rate: '135',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'CEBU',
    Location: 'OSLOB',
    Rate: '135',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'CEBU',
    Location: 'PILAR, CAMOTES IS.',
    Rate: '235',
    Remarks: '1 ON 1 DELIVERY',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'CEBU',
    Location: 'PINAMUNGAHAN',
    Rate: '135',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'CEBU',
    Location: 'PORO, CAMOTES IS.',
    Rate: '235',
    Remarks: '1 ON 1 DELIVERY',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'CEBU',
    Location: 'RONDA',
    Rate: '135',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'CEBU',
    Location: 'SAMBOAN',
    Rate: '135',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'CEBU',
    Location: 'SAN FERNANDO',
    Rate: '135',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'CEBU',
    Location: 'SAN FRANCISCO, CAMOTES IS.',
    Rate: '235',
    Remarks: '1 ON 1 DELIVERY',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'CEBU',
    Location: 'SAN REMIGIO',
    Rate: '135',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'CEBU',
    Location: 'SANTA FE, BANTAYAN IS.',
    Rate: '235',
    Remarks: '1 ON 1 DELIVERY',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'CEBU',
    Location: 'SANTANDER',
    Rate: '135',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'CEBU',
    Location: 'SIBONGA',
    Rate: '135',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'CEBU',
    Location: 'SOGOD',
    Rate: '135',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'CEBU',
    Location: 'TABOGON',
    Rate: '135',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'CEBU',
    Location: 'TABUELAN',
    Rate: '135',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'CEBU',
    Location: 'TALISAY CITY',
    Rate: '135',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'CEBU',
    Location: 'TOLEDO CITY',
    Rate: '135',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'CEBU',
    Location: 'TUBURAN',
    Rate: '135',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'CEBU',
    Location: 'TUDELA, CAMOTES IS.',
    Rate: '235',
    Remarks: '1 ON 1 DELIVERY',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'GUIMARAS ISLAND ',
    Location: 'BUENAVISTA',
    Rate: '235',
    Remarks: '1 ON 1 DELIVERY',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'GUIMARAS ISLAND ',
    Location: 'JORDAN',
    Rate: '235',
    Remarks: '1 ON 1 DELIVERY',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'GUIMARAS ISLAND ',
    Location: 'NUEVA VALENCIA',
    Rate: '235',
    Remarks: '1 ON 1 DELIVERY',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'GUIMARAS ISLAND ',
    Location: 'SAN LORENZO',
    Rate: '235',
    Remarks: '1 ON 1 DELIVERY',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'GUIMARAS ISLAND ',
    Location: 'SIBUNAG',
    Rate: '235',
    Remarks: '1 ON 1 DELIVERY',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'ILOILO',
    Location: 'AJUY',
    Rate: '135',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'ILOILO',
    Location: 'ALIMODIAN',
    Rate: '135',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'ILOILO',
    Location: 'ANILAO',
    Rate: '135',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'ILOILO',
    Location: 'AREVALO',
    Rate: '135',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'ILOILO',
    Location: 'BADIANGAN',
    Rate: '235',
    Remarks: '1 ON 1 DELIVERY',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'ILOILO',
    Location: 'BALASAN',
    Rate: '185',
    Remarks: 'WITH TRANSSHIPMENT CHARGE',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'ILOILO',
    Location: 'BANATE',
    Rate: '135',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'ILOILO',
    Location: 'BAROTAC NUEVO',
    Rate: '135',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'ILOILO',
    Location: 'BAROTAC VIEJO',
    Rate: '135',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'ILOILO',
    Location: 'BATAD',
    Rate: '185',
    Remarks: 'WITH TRANSSHIPMENT CHARGE',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'ILOILO',
    Location: 'BINGAWAN',
    Rate: '135',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'ILOILO',
    Location: 'CABATUAN',
    Rate: '135',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'ILOILO',
    Location: 'CALINOG',
    Rate: '135',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'ILOILO',
    Location: 'CARLES',
    Rate: '185',
    Remarks: 'WITH TRANSSHIPMENT CHARGE',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'ILOILO',
    Location: 'CONCEPCION',
    Rate: '185',
    Remarks: 'WITH TRANSSHIPMENT CHARGE',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'ILOILO',
    Location: 'DINGLE',
    Rate: '135',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'ILOILO',
    Location: 'DUEÑAS',
    Rate: '135',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'ILOILO',
    Location: 'DUMANGAS',
    Rate: '135',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'ILOILO',
    Location: 'ESTANCIA',
    Rate: '185',
    Remarks: 'WITH TRANSSHIPMENT CHARGE',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'ILOILO',
    Location: 'GUIMBAL',
    Rate: '135',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'ILOILO',
    Location: 'IGBARAS',
    Rate: '235',
    Remarks: '1 ON 1 DELIVERY',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'ILOILO',
    Location: 'ILOILO CITY',
    Rate: '135',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'ILOILO',
    Location: 'JANIUAY',
    Rate: '135',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'ILOILO',
    Location: 'JARO',
    Rate: '135',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'ILOILO',
    Location: 'LA PAZ',
    Rate: '135',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'ILOILO',
    Location: 'LAMBUNAO',
    Rate: '135',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'ILOILO',
    Location: 'LAWIGAN',
    Rate: '135',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'ILOILO',
    Location: 'LEGANES',
    Rate: '135',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'ILOILO',
    Location: 'LEMERY',
    Rate: '135',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'ILOILO',
    Location: 'LEON',
    Rate: '135',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'ILOILO',
    Location: 'MAASIN',
    Rate: '135',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'ILOILO',
    Location: 'MANDURRIAO',
    Rate: '135',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'ILOILO',
    Location: 'MIAGAO',
    Rate: '135',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'ILOILO',
    Location: 'MINA',
    Rate: '135',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'ILOILO',
    Location: 'MOLO',
    Rate: '135',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'ILOILO',
    Location: 'NEW LUCENA',
    Rate: '135',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'ILOILO',
    Location: 'OTON',
    Rate: '135',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'ILOILO',
    Location: 'PASSI CITY',
    Rate: '135',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'ILOILO',
    Location: 'PAVIA',
    Rate: '135',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'ILOILO',
    Location: 'POTOTAN',
    Rate: '135',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'ILOILO',
    Location: 'SAN DIONISIO',
    Rate: '185',
    Remarks: 'WITH TRANSSHIPMENT CHARGE',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'ILOILO',
    Location: 'SAN ENRIQUE',
    Rate: '135',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'ILOILO',
    Location: 'SAN JOAQUIN',
    Rate: '135',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'ILOILO',
    Location: 'SAN MIGUEL',
    Rate: '135',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'ILOILO',
    Location: 'SAN RAFAEL',
    Rate: '135',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'ILOILO',
    Location: 'SANTA BARBARA',
    Rate: '135',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'ILOILO',
    Location: 'SARA',
    Rate: '185',
    Remarks: 'WITH TRANSSHIPMENT CHARGE',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'ILOILO',
    Location: 'TIGBAUAN',
    Rate: '135',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'ILOILO',
    Location: 'TUBUNGAN',
    Rate: '235',
    Remarks: '1 ON 1 DELIVERY',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'ILOILO',
    Location: 'ZARRAGA',
    Rate: '135',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'LEYTE ',
    Location: 'ABUYOG',
    Rate: '135',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'LEYTE ',
    Location: 'ALANGALANG',
    Rate: '135',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'LEYTE ',
    Location: 'ALBUERA',
    Rate: '135',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'LEYTE ',
    Location: 'BABATNGON',
    Rate: '135',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'LEYTE ',
    Location: 'BARUGO',
    Rate: '135',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'LEYTE ',
    Location: 'BATO',
    Rate: '135',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'LEYTE ',
    Location: 'BAYBAY',
    Rate: '135',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'LEYTE ',
    Location: 'BURAUEN',
    Rate: '135',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'LEYTE ',
    Location: 'CALUBIAN',
    Rate: '135',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'LEYTE ',
    Location: 'CAPOOCAN',
    Rate: '135',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'LEYTE ',
    Location: 'CARIGARA',
    Rate: '135',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'LEYTE ',
    Location: 'DAGAMI',
    Rate: '135',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'LEYTE ',
    Location: 'DULAG',
    Rate: '135',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'LEYTE ',
    Location: 'HILONGOS',
    Rate: '135',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'LEYTE ',
    Location: 'HINDANG',
    Rate: '135',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'LEYTE ',
    Location: 'INOPACAN',
    Rate: '135',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'LEYTE ',
    Location: 'ISABEL',
    Rate: '135',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'LEYTE ',
    Location: 'JARO',
    Rate: '135',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'LEYTE ',
    Location: 'JAVIER',
    Rate: '135',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'LEYTE ',
    Location: 'JULITA',
    Rate: '135',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'LEYTE ',
    Location: 'KANANGA',
    Rate: '135',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'LEYTE ',
    Location: 'LA PAZ',
    Rate: '135',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'LEYTE ',
    Location: 'LEYTE',
    Rate: '135',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'LEYTE ',
    Location: 'MAC ARTHUR',
    Rate: '135',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'LEYTE ',
    Location: 'MAHAPLAG',
    Rate: '135',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'LEYTE ',
    Location: 'MATAG-OB',
    Rate: '135',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'LEYTE ',
    Location: 'MATALOM',
    Rate: '135',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'LEYTE ',
    Location: 'MAYORGA',
    Rate: '135',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'LEYTE ',
    Location: 'MERIDA',
    Rate: '135',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'LEYTE ',
    Location: 'ORMOC CITY',
    Rate: '135',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'LEYTE ',
    Location: 'PALO',
    Rate: '135',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'LEYTE ',
    Location: 'PALOMPON',
    Rate: '135',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'LEYTE ',
    Location: 'PASTRANA',
    Rate: '135',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'LEYTE ',
    Location: 'SAN ISIDRO',
    Rate: '135',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'LEYTE ',
    Location: 'SAN MIGUEL',
    Rate: '135',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'LEYTE ',
    Location: 'SANTA FE',
    Rate: '135',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'LEYTE ',
    Location: 'TABANGO',
    Rate: '135',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'LEYTE ',
    Location: 'TABONTABON',
    Rate: '135',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'LEYTE ',
    Location: 'TACLOBAN CITY',
    Rate: '135',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'LEYTE ',
    Location: 'TANAUAN',
    Rate: '135',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'LEYTE ',
    Location: 'TOLOSA',
    Rate: '135',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'LEYTE ',
    Location: 'TUNGA',
    Rate: '135',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'LEYTE ',
    Location: 'VILLABA',
    Rate: '135',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'LEYTE (SOUTHERN)',
    Location: 'ANAHAWAN',
    Rate: '135',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'LEYTE (SOUTHERN)',
    Location: 'BONTOC',
    Rate: '135',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'LEYTE (SOUTHERN)',
    Location: 'HINUNANGAN',
    Rate: '135',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'LEYTE (SOUTHERN)',
    Location: 'HINUNDAYAN',
    Rate: '135',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'LEYTE (SOUTHERN)',
    Location: 'LIBAGON',
    Rate: '135',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'LEYTE (SOUTHERN)',
    Location: 'LILOAN',
    Rate: '135',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'LEYTE (SOUTHERN)',
    Location: 'LIMASAWA ISLAND',
    Rate: '235',
    Remarks: '1 ON 1 DELIVERY',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'LEYTE (SOUTHERN)',
    Location: 'MAASIN',
    Rate: '135',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'LEYTE (SOUTHERN)',
    Location: 'MACROHON',
    Rate: '135',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'LEYTE (SOUTHERN)',
    Location: 'MALITBOG',
    Rate: '135',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'LEYTE (SOUTHERN)',
    Location: 'PADRE BURGOS',
    Rate: '135',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'LEYTE (SOUTHERN)',
    Location: 'PINTUYAN',
    Rate: '235',
    Remarks: '1 ON 1 DELIVERY',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'LEYTE (SOUTHERN)',
    Location: 'SAINT BERNARD',
    Rate: '235',
    Remarks: '1 ON 1 DELIVERY',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'LEYTE (SOUTHERN)',
    Location: 'SAN FRANCISCO',
    Rate: '135',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'LEYTE (SOUTHERN)',
    Location: 'SAN JUAN',
    Rate: '135',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'LEYTE (SOUTHERN)',
    Location: 'SAN RICARDO',
    Rate: '135',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'LEYTE (SOUTHERN)',
    Location: 'SILAGO',
    Rate: '135',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'LEYTE (SOUTHERN)',
    Location: 'SOGOD',
    Rate: '135',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'LEYTE (SOUTHERN)',
    Location: 'TOMAS OPPUS',
    Rate: '135',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'MASBATE',
    Location: 'MASBATE',
    Rate: '185',
    Remarks: 'ISLAND TRANSFER',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'NEGROS OCCIDENTAL',
    Location: 'BACOLOD CITY',
    Rate: '135',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'NEGROS OCCIDENTAL',
    Location: 'BAGO CITY',
    Rate: '135',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'NEGROS OCCIDENTAL',
    Location: 'BINALBAGAN',
    Rate: '135',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'NEGROS OCCIDENTAL',
    Location: 'CADIZ CITY',
    Rate: '135',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'NEGROS OCCIDENTAL',
    Location: 'CALATRAVA',
    Rate: '185',
    Remarks: 'WITH TRANSSHIPMENT CHARGE',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'NEGROS OCCIDENTAL',
    Location: 'CANDONI',
    Rate: '185',
    Remarks: 'WITH TRANSSHIPMENT CHARGE',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'NEGROS OCCIDENTAL',
    Location: 'CAUAYAN',
    Rate: '185',
    Remarks: 'WITH TRANSSHIPMENT CHARGE',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'NEGROS OCCIDENTAL',
    Location: 'ENRIQUE B. MAGALONA',
    Rate: '135',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'NEGROS OCCIDENTAL',
    Location: 'ESCALANTE',
    Rate: '135',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'NEGROS OCCIDENTAL',
    Location: 'HIMAMAYLAN',
    Rate: '135',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'NEGROS OCCIDENTAL',
    Location: 'HINIGARAN',
    Rate: '135',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'NEGROS OCCIDENTAL',
    Location: 'HINOBA-AN',
    Rate: '185',
    Remarks: 'WITH TRANSSHIPMENT CHARGE',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'NEGROS OCCIDENTAL',
    Location: 'ILOG',
    Rate: '185',
    Remarks: 'WITH TRANSSHIPMENT CHARGE',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'NEGROS OCCIDENTAL',
    Location: 'ISABELA',
    Rate: '135',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'NEGROS OCCIDENTAL',
    Location: 'KABANKALAN CITY',
    Rate: '135',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'NEGROS OCCIDENTAL',
    Location: 'LA CARLOTA CITY',
    Rate: '135',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'NEGROS OCCIDENTAL',
    Location: 'LA CASTELLANA',
    Rate: '135',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'NEGROS OCCIDENTAL',
    Location: 'MANAPLA',
    Rate: '135',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'NEGROS OCCIDENTAL',
    Location: 'MOISES PADILLA',
    Rate: '185',
    Remarks: 'WITH TRANSSHIPMENT CHARGE',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'NEGROS OCCIDENTAL',
    Location: 'MURCIA',
    Rate: '135',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'NEGROS OCCIDENTAL',
    Location: 'PONTEVEDRA',
    Rate: '135',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'NEGROS OCCIDENTAL',
    Location: 'PULUPANDAN',
    Rate: '135',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'NEGROS OCCIDENTAL',
    Location: 'SAGAY CITY',
    Rate: '135',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'NEGROS OCCIDENTAL',
    Location: 'SALVADOR BENEDICTO',
    Rate: '185',
    Remarks: 'WITH TRANSSHIPMENT CHARGE',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'NEGROS OCCIDENTAL',
    Location: 'SAN CARLOS CITY',
    Rate: '185',
    Remarks: 'WITH TRANSSHIPMENT CHARGE',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'NEGROS OCCIDENTAL',
    Location: 'SAN ENRIQUE',
    Rate: '135',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'NEGROS OCCIDENTAL',
    Location: 'SILAY CITY',
    Rate: '135',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'NEGROS OCCIDENTAL',
    Location: 'SIPALAY',
    Rate: '185',
    Remarks: 'WITH TRANSSHIPMENT CHARGE',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'NEGROS OCCIDENTAL',
    Location: 'SUM-AG',
    Rate: '135',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'NEGROS OCCIDENTAL',
    Location: 'TALISAY CITY',
    Rate: '135',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'NEGROS OCCIDENTAL',
    Location: 'TOBOSO',
    Rate: '185',
    Remarks: 'WITH TRANSSHIPMENT CHARGE',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'NEGROS OCCIDENTAL',
    Location: 'VALLADOLID',
    Rate: '135',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'NEGROS OCCIDENTAL',
    Location: 'VICTORIAS CITY',
    Rate: '135',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'NEGROS ORIENTAL',
    Location: 'AMLAN',
    Rate: '135',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'NEGROS ORIENTAL',
    Location: 'AYUNGON',
    Rate: '135',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'NEGROS ORIENTAL',
    Location: 'BACONG',
    Rate: '135',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'NEGROS ORIENTAL',
    Location: 'BAIS CITY',
    Rate: '135',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'NEGROS ORIENTAL',
    Location: 'BASAY',
    Rate: '185',
    Remarks: 'WITH TRANSSHIPMENT CHARGE',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'NEGROS ORIENTAL',
    Location: 'BAYAWAN CITY',
    Rate: '185',
    Remarks: 'WITH TRANSSHIPMENT CHARGE',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'NEGROS ORIENTAL',
    Location: 'BINDOY',
    Rate: '135',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'NEGROS ORIENTAL',
    Location: 'CANLAON CITY',
    Rate: '185',
    Remarks: 'WITH TRANSSHIPMENT CHARGE',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'NEGROS ORIENTAL',
    Location: 'DAUIN',
    Rate: '135',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'NEGROS ORIENTAL',
    Location: 'DUMAGUETE CITY',
    Rate: '135',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'NEGROS ORIENTAL',
    Location: 'GUIHULNGAN',
    Rate: '185',
    Remarks: 'WITH TRANSSHIPMENT CHARGE',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'NEGROS ORIENTAL',
    Location: 'JIMALALUD',
    Rate: '135',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'NEGROS ORIENTAL',
    Location: 'LA LIBERTAD',
    Rate: '185',
    Remarks: 'WITH TRANSSHIPMENT CHARGE',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'NEGROS ORIENTAL',
    Location: 'MABINAY',
    Rate: '135',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'NEGROS ORIENTAL',
    Location: 'MANJUYOD',
    Rate: '135',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'NEGROS ORIENTAL',
    Location: 'PAMPLONA',
    Rate: '135',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'NEGROS ORIENTAL',
    Location: 'SAN JOSE',
    Rate: '135',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'NEGROS ORIENTAL',
    Location: 'SANTA CATALINA',
    Rate: '135',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'NEGROS ORIENTAL',
    Location: 'SIATON',
    Rate: '135',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'NEGROS ORIENTAL',
    Location: 'SIBULAN',
    Rate: '135',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'NEGROS ORIENTAL',
    Location: 'TANJAY',
    Rate: '135',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'NEGROS ORIENTAL',
    Location: 'TAYASAN',
    Rate: '135',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'NEGROS ORIENTAL',
    Location: 'VALENCIA',
    Rate: '135',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'NEGROS ORIENTAL',
    Location: 'VALLEHERMOSA',
    Rate: '135',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'NEGROS ORIENTAL',
    Location: 'ZAMBOAGUITA',
    Rate: '135',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'PALAWAN',
    Location: 'ABORLAN',
    Rate: '185',
    Remarks: 'WITH TRANSSHIPMENT CHARGE',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'PALAWAN',
    Location: 'AGUTAYA (VIA CUYO ISLAND)',
    Rate: '185',
    Remarks: 'PICK-UP AT PUERTO PRINCESA STATION',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'PALAWAN',
    Location: 'ARACELI',
    Rate: '185',
    Remarks: 'PICK-UP AT ROXAS POBLACION STATION',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'PALAWAN',
    Location: 'BALABAC',
    Rate: '235',
    Remarks: "PICK-UP AT BROOKE'S POINT STATION",
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'PALAWAN',
    Location: 'BATARAZA',
    Rate: '185',
    Remarks: 'WITH TRANSSHIPMENT CHARGE',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'PALAWAN',
    Location: "BROOKE'S POINT",
    Rate: '185',
    Remarks: 'WITH TRANSSHIPMENT CHARGE',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'PALAWAN',
    Location: 'BUSUANGA',
    Rate: '235',
    Remarks: 'PICK-UP AT CORON STATION',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'PALAWAN',
    Location: 'CAGAYANCILLO',
    Rate: '185',
    Remarks: 'PICK-UP AT PUERTO PRINCESA STATION',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'PALAWAN',
    Location: 'CORON (Calamian Group of Islands)',
    Rate: '235',
    Remarks: 'PUO - PICKUP MAIN OFFICE',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'PALAWAN',
    Location: 'CULION',
    Rate: '235',
    Remarks: 'PICK-UP AT CORON STATION',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'PALAWAN',
    Location: 'CUYO',
    Rate: '185',
    Remarks: 'PICK-UP AT PUERTO PRINCESA STATION',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'PALAWAN',
    Location: 'EL NIDO (BAQUIT)',
    Rate: '235',
    Remarks: '1 ON 1 DELIVERY',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'PALAWAN',
    Location: 'IWAHIG',
    Rate: '185',
    Remarks: 'PICK-UP AT PUERTO PRINCESA STATION',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'PALAWAN',
    Location: 'KALAYAAN',
    Rate: '185',
    Remarks: 'PICK-UP AT PUERTO PRINCESA STATION',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'PALAWAN',
    Location: 'LINAPACAN',
    Rate: '185',
    Remarks: 'PICK-UP AT ROXAS POBLACION STATION',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'PALAWAN',
    Location: 'MAGSAYSAY',
    Rate: '135',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'PALAWAN',
    Location: 'NARRA',
    Rate: '185',
    Remarks: 'WITH TRANSSHIPMENT CHARGE',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'PALAWAN',
    Location: 'PUERTO PRINCESA CITY',
    Rate: '135',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'PALAWAN',
    Location: 'QUEZON',
    Rate: '185',
    Remarks: 'WITH TRANSSHIPMENT CHARGE',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'PALAWAN',
    Location: 'RIZAL',
    Rate: '235',
    Remarks: '1 ON 1 DELIVERY',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'PALAWAN',
    Location: 'ROXAS',
    Rate: '185',
    Remarks: 'WITH TRANSSHIPMENT CHARGE',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'PALAWAN',
    Location: 'SAN VICENTE',
    Rate: '235',
    Remarks: '1 ON 1 DELIVERY',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'PALAWAN',
    Location: 'SOFRONIO ESPANOLA',
    Rate: '235',
    Remarks: "PICK-UP AT BROOKE'S POINT STATION",
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'PALAWAN',
    Location: 'TAYTAY',
    Rate: '235',
    Remarks: '1 ON 1 DELIVERY',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'ROMBLON ISLAND',
    Location: 'ROMBLON ISLAND',
    Rate: '185',
    Remarks: 'ISLAND TRANSFER',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'SAMAR, EASTERN',
    Location: 'ARTECHE',
    Rate: '185',
    Remarks: 'WITH TRANSSHIPMENT CHARGE',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'SAMAR, EASTERN',
    Location: 'BALANGIGA',
    Rate: '135',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'SAMAR, EASTERN',
    Location: 'BALANGKAYAN',
    Rate: '135',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'SAMAR, EASTERN',
    Location: 'BORONGAN',
    Rate: '135',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'SAMAR, EASTERN',
    Location: 'CAN-AVID',
    Rate: '135',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'SAMAR, EASTERN',
    Location: 'DOLORES',
    Rate: '185',
    Remarks: 'WITH TRANSSHIPMENT CHARGE',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'SAMAR, EASTERN',
    Location: 'GENERAL MACARTHUR',
    Rate: '135',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'SAMAR, EASTERN',
    Location: 'GIPORLOS',
    Rate: '135',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'SAMAR, EASTERN',
    Location: 'GUIUAN',
    Rate: '135',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'SAMAR, EASTERN',
    Location: 'HERNANI',
    Rate: '135',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'SAMAR, EASTERN',
    Location: 'JIPAPAD',
    Rate: '185',
    Remarks: 'WITH TRANSSHIPMENT CHARGE',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'SAMAR, EASTERN',
    Location: 'LAWAAN',
    Rate: '135',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'SAMAR, EASTERN',
    Location: 'LLORENTE',
    Rate: '135',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'SAMAR, EASTERN',
    Location: 'MASLOG',
    Rate: '135',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'SAMAR, EASTERN',
    Location: 'MAYDOLONG',
    Rate: '135',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'SAMAR, EASTERN',
    Location: 'MERCEDEZ',
    Rate: '135',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'SAMAR, EASTERN',
    Location: 'ORAS',
    Rate: '185',
    Remarks: 'WITH TRANSSHIPMENT CHARGE',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'SAMAR, EASTERN',
    Location: 'QUINAPUNDAN',
    Rate: '135',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'SAMAR, EASTERN',
    Location: 'SALCEDO',
    Rate: '135',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'SAMAR, EASTERN',
    Location: 'SAN JULIAN',
    Rate: '185',
    Remarks: 'WITH TRANSSHIPMENT CHARGE',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'SAMAR, EASTERN',
    Location: 'SAN POLICARPO',
    Rate: '135',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'SAMAR, EASTERN',
    Location: 'SULAT',
    Rate: '135',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'SAMAR, EASTERN',
    Location: 'TAFT',
    Rate: '135',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'SAMAR, NORTHERN',
    Location: 'ALLEN',
    Rate: '135',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'SAMAR, NORTHERN',
    Location: 'BIRI',
    Rate: '185',
    Remarks: 'PICK-UP AT NEAREST PORT',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'SAMAR, NORTHERN',
    Location: 'BOBON',
    Rate: '135',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'SAMAR, NORTHERN',
    Location: 'BURAY',
    Rate: '135',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'SAMAR, NORTHERN',
    Location: 'CAPUL',
    Rate: '185',
    Remarks: 'PICK-UP AT NEAREST PORT',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'SAMAR, NORTHERN',
    Location: 'CATARMAN',
    Rate: '135',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'SAMAR, NORTHERN',
    Location: 'CATUBIG',
    Rate: '185',
    Remarks: 'WITH TRANSSHIPMENT CHARGE',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'SAMAR, NORTHERN',
    Location: 'GAMAY',
    Rate: '185',
    Remarks: 'WITH TRANSSHIPMENT CHARGE',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'SAMAR, NORTHERN',
    Location: 'LAOANG',
    Rate: '185',
    Remarks: 'WITH TRANSSHIPMENT CHARGE',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'SAMAR, NORTHERN',
    Location: 'LAPINIG',
    Rate: '185',
    Remarks: 'WITH TRANSSHIPMENT CHARGE',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'SAMAR, NORTHERN',
    Location: 'LAS NAVAS',
    Rate: '185',
    Remarks: 'WITH TRANSSHIPMENT CHARGE',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'SAMAR, NORTHERN',
    Location: 'LAVEZARES',
    Rate: '185',
    Remarks: 'WITH TRANSSHIPMENT CHARGE',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'SAMAR, NORTHERN',
    Location: 'LOPE DE VEGA',
    Rate: '185',
    Remarks: 'WITH TRANSSHIPMENT CHARGE',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'SAMAR, NORTHERN',
    Location: 'MAPANAS',
    Rate: '185',
    Remarks: 'WITH TRANSSHIPMENT CHARGE',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'SAMAR, NORTHERN',
    Location: 'MONDRAGON',
    Rate: '185',
    Remarks: 'WITH TRANSSHIPMENT CHARGE',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'SAMAR, NORTHERN',
    Location: 'PALAPAG',
    Rate: '185',
    Remarks: 'WITH TRANSSHIPMENT CHARGE',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'SAMAR, NORTHERN',
    Location: 'PAMBUJAN',
    Rate: '185',
    Remarks: 'WITH TRANSSHIPMENT CHARGE',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'SAMAR, NORTHERN',
    Location: 'ROSARIO',
    Rate: '185',
    Remarks: 'WITH TRANSSHIPMENT CHARGE',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'SAMAR, NORTHERN',
    Location: 'SAN ANTONIO',
    Rate: '185',
    Remarks: 'PICK-UP AT NEAREST PORT',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'SAMAR, NORTHERN',
    Location: 'SAN ISIDRO',
    Rate: '185',
    Remarks: 'WITH TRANSSHIPMENT CHARGE',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'SAMAR, NORTHERN',
    Location: 'SAN JOSE',
    Rate: '185',
    Remarks: 'WITH TRANSSHIPMENT CHARGE',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'SAMAR, NORTHERN',
    Location: 'SAN ROQUE',
    Rate: '185',
    Remarks: 'WITH TRANSSHIPMENT CHARGE',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'SAMAR, NORTHERN',
    Location: 'SAN VICENTE',
    Rate: '185',
    Remarks: 'PICK-UP AT NEAREST PORT',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'SAMAR, NORTHERN',
    Location: 'SILVINO LOBOS',
    Rate: '185',
    Remarks: 'WITH TRANSSHIPMENT CHARGE',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'SAMAR, NORTHERN',
    Location: 'VICTORIA ',
    Rate: '185',
    Remarks: 'WITH TRANSSHIPMENT CHARGE',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'SAMAR, WESTERN',
    Location: 'ALMAGRO',
    Rate: '185',
    Remarks: 'PICK-UP AT NEAREST PORT',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'SAMAR, WESTERN',
    Location: 'BASEY',
    Rate: '135',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'SAMAR, WESTERN',
    Location: 'CALBAYOG CITY',
    Rate: '135',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'SAMAR, WESTERN',
    Location: 'CALBIGA',
    Rate: '135',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'SAMAR, WESTERN',
    Location: 'CATBALOGAN',
    Rate: '135',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'SAMAR, WESTERN',
    Location: 'CONCORD',
    Rate: '185',
    Remarks: 'WITH TRANSSHIPMENT CHARGE',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'SAMAR, WESTERN',
    Location: 'DARAM',
    Rate: '185',
    Remarks: 'PICK-UP AT NEAREST PORT',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'SAMAR, WESTERN',
    Location: 'GANDARA',
    Rate: '135',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'SAMAR, WESTERN',
    Location: 'HINABANGAN',
    Rate: '135',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'SAMAR, WESTERN',
    Location: 'JIABONG',
    Rate: '135',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'SAMAR, WESTERN',
    Location: 'MARABUT ISLAND',
    Rate: '185',
    Remarks: 'PICK-UP AT NEAREST PORT',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'SAMAR, WESTERN',
    Location: 'MATUGUINAO',
    Rate: '135',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'SAMAR, WESTERN',
    Location: 'MOTIONG',
    Rate: '135',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'SAMAR, WESTERN',
    Location: 'OQUENDO',
    Rate: '185',
    Remarks: 'WITH TRANSSHIPMENT CHARGE',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'SAMAR, WESTERN',
    Location: 'PAGSANHAN',
    Rate: '185',
    Remarks: 'WITH TRANSSHIPMENT CHARGE',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'SAMAR, WESTERN',
    Location: 'PINABACDAO',
    Rate: '135',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'SAMAR, WESTERN',
    Location: 'SAN JORGE',
    Rate: '135',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'SAMAR, WESTERN',
    Location: 'SAN JOSE DE BUAN',
    Rate: '185',
    Remarks: 'WITH TRANSSHIPMENT CHARGE',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'SAMAR, WESTERN',
    Location: 'SAN SEBASTIAN',
    Rate: '185',
    Remarks: 'WITH TRANSSHIPMENT CHARGE',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'SAMAR, WESTERN',
    Location: 'SANTA MARGARITA',
    Rate: '135',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'SAMAR, WESTERN',
    Location: 'SANTA RITA',
    Rate: '135',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'SAMAR, WESTERN',
    Location: 'SANTO NINO',
    Rate: '185',
    Remarks: 'WITH TRANSSHIPMENT CHARGE',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'SAMAR, WESTERN',
    Location: 'TAGAPUL-AN',
    Rate: '185',
    Remarks: 'PICK-UP AT NEAREST PORT',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'SAMAR, WESTERN',
    Location: 'TALALORA',
    Rate: '135',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'SAMAR, WESTERN',
    Location: 'TARANGNAN',
    Rate: '135',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'SAMAR, WESTERN',
    Location: 'TINAMBACAN',
    Rate: '135',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'SAMAR, WESTERN',
    Location: 'VILLAREAL',
    Rate: '185',
    Remarks: 'WITH TRANSSHIPMENT CHARGE',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'SAMAR, WESTERN',
    Location: 'WRIGHT',
    Rate: '135',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'SAMAR, WESTERN',
    Location: 'ZUMARRAGA',
    Rate: '185',
    Remarks: 'PICK-UP AT NEAREST PORT',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'SIQUIJOR ISLAND',
    Location: 'ENRIQUE VILLANUEVA',
    Rate: '185',
    Remarks: 'ISLAND TRANSFER',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'SIQUIJOR ISLAND',
    Location: 'LARENA',
    Rate: '185',
    Remarks: 'ISLAND TRANSFER',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'SIQUIJOR ISLAND',
    Location: 'LAZI',
    Rate: '185',
    Remarks: 'ISLAND TRANSFER',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'SIQUIJOR ISLAND',
    Location: 'MARIA',
    Rate: '185',
    Remarks: 'ISLAND TRANSFER',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'SIQUIJOR ISLAND',
    Location: 'SAN JUAN',
    Rate: '185',
    Remarks: 'ISLAND TRANSFER',
    LeadTime: null,
  },
  {
    SourceCode: 'MS',
    IslandCode: 'VIZ',
    Island: 'VISAYAS',
    Freight: 'SEA',
    Province: 'SIQUIJOR ISLAND',
    Location: 'SIQUIJOR',
    Rate: '185',
    Remarks: 'ISLAND TRANSFER',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'Metro Manila (NCR)',
    Location: 'Caloocan',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'Metro Manila (NCR)',
    Location: 'Las Piñas',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'Metro Manila (NCR)',
    Location: 'Makati',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'Metro Manila (NCR)',
    Location: 'Malabon',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'Metro Manila (NCR)',
    Location: 'Mandaluyong',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'Metro Manila (NCR)',
    Location: 'Manila',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'Metro Manila (NCR)',
    Location: 'Marikina',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'Metro Manila (NCR)',
    Location: 'Muntinlupa',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'Metro Manila (NCR)',
    Location: 'Navotas',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'Metro Manila (NCR)',
    Location: 'Parañaque',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'Metro Manila (NCR)',
    Location: 'Pasay',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'Metro Manila (NCR)',
    Location: 'Pasig',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'Metro Manila (NCR)',
    Location: 'Quezon City',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'Metro Manila (NCR)',
    Location: 'San Juan',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'Metro Manila (NCR)',
    Location: 'Taguig',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'Metro Manila (NCR)',
    Location: 'Valenzuela',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
  {
    SourceCode: 'MM',
    IslandCode: 'LUZ',
    Island: 'LUZON',
    Freight: 'SEA',
    Province: 'Metro Manila (NCR)',
    Location: 'Pateros',
    Rate: '115',
    Remarks: 'WITHIN DELIVERY AREA',
    LeadTime: null,
  },
];
