import { Breadcrumb, Button, Checkbox, Col, Form, Input, Row, Select, message } from 'antd';
import { useHistory } from 'react-router';
import { useSelector, useDispatch } from 'react-redux';

import { SenderDetailsSelector } from '@features/SenderDetails/';
import { AddReceiver, ReceiverActions, ReceiverSelector, UpdateReceiver } from '@features/Receiver';

import { Constants, ReceiverStatus } from 'common/constants';
import { useEffect, useState } from 'react';
import { CapitalizeWords, handleFocusContactNumber, handleKeyDownContactNumberNew } from 'common/utils/utility';
import { OperationStatus } from 'store/rootTypes';
import { TransactionSelector } from '@features/Transaction';
import { MobileCountryCodeList } from 'common/code/MobileCountryCode';
import { BookingSelector } from '../store/BookingSelector';
import { commonSelector } from '@features/Common/store/commonSelector';
import { PATHS } from '@routing/routes';
import { getDistinctProvinces, getLocationsByProvince } from 'common/code/Address';

export const Receiver = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const bookingState = useSelector(BookingSelector);
  const senderDetailsState = useSelector(SenderDetailsSelector);
  const receiverState = useSelector(ReceiverSelector);
  const transactionState = useSelector(TransactionSelector);
  const commonState = useSelector(commonSelector);
  useEffect(() => {
    if (
      (senderDetailsState.Sender === null || senderDetailsState.Sender === undefined) &&
      receiverState.ReceiverStatus == ReceiverStatus.Booking
    ) {
      history.push('/home');
    }
  }, [senderDetailsState, history]);

  useEffect(() => {
    const id = receiverState.Receiver?.Id;

    switch (receiverState.ReceiverStatus) {
      case ReceiverStatus.Update:
        if (id === null || id === undefined) {
          history.push('/home');
        }
        break;
      case ReceiverStatus.Booking:
        history.push('/receiver/listing');
        break;
      default:
        // Handle the default case (if any)
        //SenderStatus.New retain to this page to input the details manually
        break;
    }
  }, []);

  const [isForUpdate, setForUpdate] = useState(false);
  const [isForAdd, setForAdd] = useState(false);
  const [isChecked, setIsChecked] = useState(false);

  useEffect(() => {
    const updateReceiverAndRedirect = async () => {
      if (receiverState.Receiver !== null && receiverState.Receiver !== undefined && isForUpdate) {
        await dispatch(UpdateReceiver(receiverState?.Receiver));
        message.success('Receiver updated successfully');

        if (commonState.GlobalTransactionId) {
          history.push(PATHS.ReceiverListingBooking.path);
        } else {
          history.push('/receiver/listing');
        }
      }
    };

    updateReceiverAndRedirect();
  }, [receiverState.Receiver, isForUpdate, dispatch, history]);

  useEffect(() => {
    const addReceiverAndRedirect = async () => {
      if (receiverState.Receiver !== null && receiverState.Receiver !== undefined && isForAdd) {
        await dispatch(AddReceiver(receiverState?.Receiver));
        message.success('Receiver added successfully');
        if (commonState.GlobalTransactionId) {
          history.push(PATHS.ReceiverListingBooking.path);
        } else if (transactionState?.SelectedTransId) {
          history.push('/receiver/listing');
        } else {
          history.push('/receiver/listing');
        }
      }
    };

    addReceiverAndRedirect();
  }, [receiverState.Receiver, isForAdd, dispatch, history]);

  const onFinish = async (values: any) => {
    await dispatch(
      ReceiverActions.SetReceiver({
        ...values,
        TransactionId: transactionState?.SelectedTransId,
        MobileCode: selectedMobileCode,
        UserId: bookingState.FromSignupExistingUserId,
      })
    );

    if (receiverState.ReceiverStatus === ReceiverStatus.Update) {
      setForUpdate(true);
    } else if (receiverState.ReceiverStatus === ReceiverStatus.New) {
      setForAdd(true);
    } else {
      history.push('/booking/receiver');
    }
  };

  const onBack = () => {
    if (commonState.GlobalTransactionId) {
      history.push(PATHS.ReceiverListingBooking.path);
    } else if (transactionState?.FromUpdateLink) {
      history.push('/transaction/summary');
    } else if (
      receiverState.ReceiverStatus === ReceiverStatus.Update ||
      receiverState.ReceiverStatus === ReceiverStatus.New
    ) {
      history.push('/receiver/listing');
    } else {
      history.push('/booking/senderaddress');
    }
  };
  const [selectedMobileCode, setSelectedMobileCode] = useState(
    transactionState?.TransactionSummary?.Receiver?.MobileCode || receiverState?.Receiver?.MobileCode || '+63'
  );
  const [form] = Form.useForm();
  const { Option } = Select;

  const selectStyle = { width: '100px' }; // Adjust the width as needed
  const inputStyle = { width: 'calc(100% - 100px)' }; // Adjust the width based on the select width

  useEffect(() => {
    if (receiverState.ReceiverStatus === ReceiverStatus.New) {
      setSelectedMobileCode('+63');
    } else if (receiverState.ReceiverStatus === ReceiverStatus.Update && receiverState?.Receiver?.MobileCode) {
      setSelectedMobileCode(receiverState?.Receiver?.MobileCode);
    }
  }, []);

  const [selectedProvince, setSelectedProvince] = useState<string | null>(null);
  const [locations, setLocations] = useState<string[]>([]);

  const handleProvinceChange = (province: string) => {
    // setCity(null);
    form.setFieldsValue({ City: '' });
    setSelectedProvince(province);
    const filteredLocations = getLocationsByProvince(province);
    setLocations(filteredLocations);
  };

  const [isHidden, setIsHidden] = useState(false);
  const handleCheckboxChange = (e: any) => {
    setIsChecked(e.target.checked);
    setIsHidden(e.target.checked); // Update visibility based on the checkbox state
    form.setFieldsValue({ City: '' });
    form.setFieldsValue({ Province: '' });
  };

  useEffect(() => {
    const province = !transactionState?.FromUpdateLink
      ? receiverState.Receiver?.Province
      : transactionState?.TransactionSummary?.Receiver.Province;

    const city1 = !transactionState?.FromUpdateLink
      ? receiverState.Receiver?.City
      : transactionState?.TransactionSummary?.Receiver.City;

    if (province && city1) {
      setSelectedProvince(province);
      const filteredLocations = getLocationsByProvince(province);

      if (filteredLocations.length > 0) {
        setIsChecked(false);
        setLocations(filteredLocations);
        setIsHidden(false);
      } else {
        setIsChecked(true);
        setIsHidden(true);
      }
    }
  }, []);

  return (
    <div className="App">
      {!transactionState?.FromUpdateLink ? (
        <Row justify="center" align="middle">
          {' '}
          {receiverState.ReceiverStatus === ReceiverStatus.Update ? (
            <h1>Update receiver</h1>
          ) : receiverState.ReceiverStatus === ReceiverStatus.New ? (
            <h1>Add new receiver</h1>
          ) : (
            <h1>{Constants.KFSBOX_BookPickupHeader}</h1>
          )}
        </Row>
      ) : (
        <Row justify="center" align="middle">
          {' '}
          <h1>Update receiver</h1>
        </Row>
      )}
      <Form name="senderForm" onFinish={onFinish} layout="vertical" form={form}>
        <Row justify="center" align="middle">
          <Col xs={24} sm={20} md={12} lg={8} xl={8}>
            {receiverState.ReceiverStatus === ReceiverStatus.Booking ? (
              <Breadcrumb
                items={[
                  {
                    title: 'Sender',
                  },
                  {
                    title: 'Sender address',
                  },
                  {
                    title: 'Receiver',
                  },
                ]}
              />
            ) : null}
            <br />
            <Form.Item
              initialValue={
                !transactionState?.FromUpdateLink
                  ? receiverState.Receiver?.GivenName
                  : transactionState?.TransactionSummary?.Receiver.GivenName
              }
              label="Given name"
              name="GivenName"
              rules={[{ required: true, message: Constants.REQUIRED_FIELD }]}
            >
              <Input size="large" maxLength={150} />
            </Form.Item>
            <Form.Item
              initialValue={
                !transactionState?.FromUpdateLink
                  ? receiverState.Receiver?.Surname
                  : transactionState?.TransactionSummary?.Receiver.Surname
              }
              label="Surname"
              name="Surname"
              rules={[{ required: true, message: Constants.REQUIRED_FIELD }]}
            >
              <Input size="large" maxLength={150} />
            </Form.Item>

            <Form.Item
              initialValue={
                !transactionState?.FromUpdateLink
                  ? receiverState.Receiver?.Address
                  : transactionState?.TransactionSummary?.Receiver.Address
              }
              label="Address (Unit number, Street, Brgy)"
              name="Address"
              rules={[{ required: true, message: Constants.REQUIRED_FIELD }]}
            >
              <Input size="large" maxLength={150} />
            </Form.Item>
            {!isHidden && (
              <>
                <Form.Item
                  label="Province"
                  name="Province"
                  initialValue={
                    !transactionState?.FromUpdateLink
                      ? receiverState.Receiver?.Province
                      : transactionState?.TransactionSummary?.Receiver.Province
                  }
                  rules={[{ required: true, message: Constants.REQUIRED_FIELD }]}
                >
                  <Select
                    size="large"
                    showSearch
                    placeholder="Select province"
                    optionFilterProp="children"
                    onChange={handleProvinceChange}
                  >
                    {getDistinctProvinces().map((province) => (
                      <Select.Option key={province} value={CapitalizeWords(province)}>
                        {CapitalizeWords(province)}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>

                <Form.Item
                  label="Town"
                  name="City"
                  initialValue={
                    !transactionState?.FromUpdateLink
                      ? receiverState.Receiver?.City
                      : transactionState?.TransactionSummary?.Receiver.City
                  }
                  rules={[{ required: true, message: Constants.REQUIRED_FIELD }]}
                >
                  <Select
                    size="large"
                    showSearch
                    placeholder="Select town"
                    disabled={!selectedProvince && !receiverState.Receiver?.City}
                    optionFilterProp="children"
                  >
                    {/* <Select.Option key="" value="">
                      {'Please select city'}
                    </Select.Option> */}
                    {locations.map((location) => (
                      <Select.Option key={location} value={CapitalizeWords(location)}>
                        {CapitalizeWords(location)}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </>
            )}
            <Form.Item>
              <Checkbox
                checked={isChecked} // Controlled by state
                className="textColorPrimary"
                onChange={handleCheckboxChange}
                onClick={(e) => e.stopPropagation()} // Ensure it only toggles when clicked
              >
                <>
                  I can’t find my <b>Town</b> or <b>Province</b>
                </>
              </Checkbox>
            </Form.Item>
            {isHidden && (
              <>
                <Form.Item
                  initialValue={
                    !transactionState?.FromUpdateLink
                      ? receiverState.Receiver?.Province
                      : transactionState?.TransactionSummary?.Receiver.Province
                  }
                  label="Province"
                  name="Province"
                  rules={[{ required: true, message: Constants.REQUIRED_FIELD }]}
                >
                  <Input size="large" maxLength={150} />
                </Form.Item>
                <Form.Item
                  initialValue={
                    !transactionState?.FromUpdateLink
                      ? receiverState.Receiver?.City
                      : transactionState?.TransactionSummary?.Receiver.City
                  }
                  label="Town"
                  name="City"
                  rules={[{ required: true, message: Constants.REQUIRED_FIELD }]}
                >
                  <Input size="large" maxLength={150} />
                </Form.Item>
              </>
            )}

            <Form.Item
              initialValue={
                !transactionState?.FromUpdateLink
                  ? receiverState.Receiver?.PostalCode
                  : transactionState?.TransactionSummary?.Receiver.PostalCode
              }
              label="Postal code (optional)"
              // label="Postal code"
              name="PostalCode"
              // rules={[{ required: true, message: Constants.REQUIRED_FIELD }]}
            >
              <Input size="large" maxLength={10} />
            </Form.Item>
            <Form.Item
              initialValue={
                !transactionState?.FromUpdateLink
                  ? receiverState.Receiver?.ContactNo
                  : transactionState?.TransactionSummary?.Receiver.ContactNo
              }
              label="Contact number"
              name="ContactNo"
              // rules={[{ required: true }]}
            >
              <Input.Group compact>
                <Form.Item
                  noStyle // Adjust the name prop for the Select component
                  initialValue="+1"
                >
                  <Select
                    style={selectStyle}
                    value={selectedMobileCode}
                    onChange={(value) => setSelectedMobileCode(value)}
                    size="large" // Add your onChange logic
                    showSearch // Enable search
                    optionFilterProp="children" // Use the "children" property for filtering
                  >
                    {MobileCountryCodeList.map((country) => (
                      <Option key={country.code} value={country.dial_code}>
                        {`${country.dial_code}`}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
                <Form.Item
                  noStyle
                  name="ContactNo"
                  // initialValue=""
                  rules={[{ required: true, message: Constants.REQUIRED_FIELD }]}
                >
                  <Input
                    style={inputStyle}
                    placeholder="Contact number"
                    size="large"
                    // onInput={handleInputChangeContactNumber}
                    onKeyDown={handleKeyDownContactNumberNew}
                    onFocus={handleFocusContactNumber}
                  />
                </Form.Item>
              </Input.Group>
            </Form.Item>
            <div>
              <Button className="prevSenderBtn" size="large" type="default" onClick={onBack}>
                {Constants.BTN_BACK}
              </Button>
              <Button
                className="nextSenderBtn"
                size="large"
                type="primary"
                htmlType="submit"
                loading={receiverState.submitStatus == OperationStatus.pending}
              >
                {receiverState.ReceiverStatus === ReceiverStatus.Update || transactionState?.FromUpdateLink
                  ? Constants.BTN_UPDATE
                  : receiverState.ReceiverStatus === ReceiverStatus.New
                  ? Constants.BTN_SAVE
                  : Constants.BTN_CONTINUE}
              </Button>
            </div>
          </Col>
        </Row>
      </Form>
    </div>
  );
};
