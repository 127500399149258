import { useEffect, useState } from 'react';
import { Button, Col, Form, InputNumber, Row, Space, message } from 'antd';
import { useHistory } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';

import { GetTransactionById, TransactionSelector } from '@features/Transaction';

import { Constants, PaymentStatus, Roles } from 'common/constants';
import styles from 'features/Booking/Booking.module.less';
import 'App.css';
import { SaveBarcode, ValidateBarcode } from '@features/Barcode';
import { BarcodeSaveRequestModel, BarcodeValidateRequestModel } from 'common/models/BarcodeTypes';
import { AccountSelector } from '@features/Account';

export const Barcode = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const transactionState = useSelector(TransactionSelector);
  const accountState = useSelector(AccountSelector);
  const [form] = Form.useForm();
  const [barcodeValidities, setBarcodeValidities] = useState<Array<boolean>>([]);
  const [errorBarcode, setBarcodeError] = useState<any>('');
  const validateBarcode = async (value: string, index: number) => {
    try {
      const updatedValidities = [...barcodeValidities];
      const barcodeValidateRequest: BarcodeValidateRequestModel = {
        TransactionsId: transactionState?.SelectedTransId ?? '',
        TransactionStatus: transactionState.TransactionSummary?.TransactionStatus[0]?.Status ?? '',
        Number: value,
        Abbreviations1: transactionState.TransactionSummary?.Sender.State ?? '',
        Abbreviations2: transactionState.Invoice?.Type ?? '',
      };
      const response = await dispatch(ValidateBarcode(barcodeValidateRequest));
      // Assuming the API response has an 'error' property with the error message
      const errorMessage = response ?? '';
      // Update the validity status and error message for the current barcode
      updatedValidities[index] = !errorMessage;
      setBarcodeError(errorMessage);
      setBarcodeValidities(updatedValidities);
    } catch (error) {
      console.error('Error validating barcode:', error);
    }
  };

  const onBack = () => {
    if (transactionState.FromUpdateLink) {
      history.push('/transaction/summary');
    } else if (transactionState.Invoice?.Status !== PaymentStatus.Paid) {
      history.push('/invoice');
    } else {
      history.push('/transaction/summary');
    }
  };

  const onFinish = async (values: any) => {
    // Check if the barcode field is not an empty array
    const isBarcodeArrayNotEmpty = Array.isArray(values.barcode) && values.barcode.length > 0;

    if (isBarcodeArrayNotEmpty) {
      const barcodeSet = new Set();
      const duplicateBarcodes: string[] = [];

      for (const barcodeObj of values.barcode) {
        const barcode = barcodeObj.barcode;

        if (barcodeSet.has(barcode)) {
          duplicateBarcodes.push(barcode);
        } else {
          barcodeSet.add(barcode);
        }
      }

      if (duplicateBarcodes.length > 0) {
        // There are duplicate barcodes
        const errorMessage = `Duplicate barcodes are not allowed. Barcode/s: ${Array.from(duplicateBarcodes).join(
          ', '
        )}`;
        message.error(errorMessage, 2);
        return;
      }
      const allBarcodesValid = barcodeValidities.every((validity) => validity);

      // If all barcodes are valid, call the API
      if (allBarcodesValid) {
        // alert(JSON.stringify(allBarcodesValid) + 'barcode is valid');
        // Call your API here
        console.log('Calling the API...');
      } else {
        // alert(JSON.stringify(allBarcodesValid) + 'barcode not valid');
        // console.log('Not all barcodes are valid. API not called.');
        return;
      }
    } else {
      message.error('Please add barcode', 2);
      return;
    }
    const barcodeValidateRequest: BarcodeSaveRequestModel = {
      TransactionsId: transactionState?.SelectedTransId ?? '',
      TransactionStatus: transactionState.TransactionSummary?.TransactionStatus[0]?.Status ?? '',
      Numbers: values.barcode.map((barcodeObj: any) => barcodeObj.barcode),
      Number: '',
      Abbreviations1: transactionState.TransactionSummary?.Sender.State ?? '',
      Abbreviations2: transactionState.Invoice?.Type ?? '',
      OldBarcodes: transactionState.TransactionSummary?.Barcode,
    };
    const response = await dispatch(SaveBarcode(barcodeValidateRequest));
    // Check if response is a non-empty string
    if (typeof response === 'string' && response !== '') {
      message.error(response, 2);
    } else {
      if (transactionState.SelectedTransId) {
        await dispatch(GetTransactionById(transactionState.SelectedTransId));
      }
      if (transactionState.FromUpdateLink || transactionState.TransactionSummary?.Signature?.Sender) {
        message.success('Barcode updated successfully');
        history.push('/transaction/summary');
      } else {
        history.push('/booking/signature');
      }
    }
  };

  useEffect(() => {
    // Check if Barcode is null or has a count of 0
    const barcodeData = transactionState.TransactionSummary?.Barcode;
    // const barcodeCount =
    //   barcodeData && barcodeData.length > 0 ? barcodeData.length : transactionState.Invoice?.UnitCount || 1;

    const barcodeCount = transactionState.Invoice?.UnitCount;
    // Set initial values for barcode fields
    const initialValues = Array.from({ length: Number(barcodeCount) }, (_, index) => {
      const barcodeValue = barcodeData && barcodeData[index] ? Number(barcodeData[index].Number) : undefined;
      return { barcode: barcodeValue };
    });

    form.setFieldsValue({ barcode: initialValues });
  }, [transactionState.TransactionSummary?.Barcode, transactionState.Invoice?.UnitCount, form]);

  useEffect(() => {
    if (accountState.RoleName == Roles.Customer) {
      history.push('/home');
    }
  }, []);
  return (
    <div className="App">
      <Row justify="center" align="middle">
        <h1>Enter barcode </h1>
      </Row>

      <Form name="senderForm" form={form} onFinish={onFinish} layout="vertical">
        <Row justify="center" align="middle">
          <Col xs={24} sm={20} md={12} lg={8} xl={8}>
            <Form.List name="barcode" initialValue={[{}]}>
              {(fields, { add, remove }) => (
                <>
                  {fields.map(({ key, name, ...restField }, index) => (
                    <div key={key}>
                      <Space align="baseline" className={styles.customSpaceAdd}>
                        <Form.Item
                          {...restField}
                          name={[name, 'barcode']}
                          rules={[{ required: true, message: Constants.REQUIRED_FIELD }]}
                          validateTrigger={['onBlur']}
                          validateStatus={barcodeValidities[index] === false ? 'error' : undefined}
                          help={barcodeValidities[index] === false ? errorBarcode : undefined}
                        >
                          <InputNumber
                            className="full-width"
                            addonBefore={transactionState.TransactionSummary?.Sender.State}
                            addonAfter={transactionState.Invoice?.Type}
                            size="large"
                            onBlur={(e) => validateBarcode(e.target.value, index)}
                            placeholder="barcode number"
                            maxLength={6}
                          />
                        </Form.Item>
                        {/* <MinusCircleOutlined onClick={() => remove(name)} className={styles.minusIcon} /> */}
                      </Space>
                    </div>
                  ))}
                  {/* <Form.Item>
                    <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                      Add more
                    </Button>
                  </Form.Item> */}
                </>
              )}
            </Form.List>
            <Button className="prevSenderBtn" size="large" type="default" onClick={onBack}>
              {Constants.BTN_BACK}
            </Button>

            <Button className="nextSenderBtn" size="large" type="primary" htmlType="submit">
              {transactionState.FromUpdateLink ? Constants.BTN_UPDATE : Constants.BTN_CONTINUE}
            </Button>
          </Col>
        </Row>
      </Form>
    </div>
  );
};
